//import { useQuery, gql, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';


export const GET_MAHNUNGEN = gql`
    query GetMahnungen {
        mahnungen{
            id
            status
            rechnungsart
            rechnungsnummer
            rechnungsbetrag
            rechnungsdatum
            pacht {
                id
                paechter {
                    id
                    name
                    vorname
                }
                kontierungsnummer
            }
            mahnstufe
            rechnungsbetrag
            bislangGezahlt
            offenerBetrag
            ausstellungsdatum
            archiviertAm
        }
    }
`;



export const GET_MAHNUNGEN_OF_PACHT = gql`
    query GetMahnungenOfPacht($pachtID: ID!) {
        mahnungenOfPacht(pachtId: $pachtID) {
            id
            status
            rechnungsart
            rechnungsnummer
            rechnungsbetrag
            rechnungsdatum
            pacht {
                id
                paechter {
                    id
                    name
                    vorname
                }
                kontierungsnummer
            }
            mahnstufe
            rechnungsbetrag
            bislangGezahlt
            offenerBetrag
            ausstellungsdatum
            archiviertAm
        }
    }
`;



export const GET_ARCHIVIERTE_MAHNUNGEN = gql`
    query GetArchivierteMahnungen {
        archivierteMahnungen{
            id
            status
            rechnungsart
            rechnungsnummer
            rechnungsbetrag
            rechnungsdatum
            pacht {
                id
                paechter {
                    id
                    name
                    vorname
                }
                kontierungsnummer
            }
            mahnstufe
            rechnungsbetrag
            bislangGezahlt
            offenerBetrag
            ausstellungsdatum
            archiviertAm
        }
    }
`;



export const CREATE_MAHNUNGEN = gql`
    mutation CreateMahnungen {
        createMahnung {
            ok
        }
    }
`;



export const ISSUE_MAHNUNG = gql`
    mutation IssueMahnungen($id: ID!){
        issueMahnung(id: $id) {
            ok
        }
    }
`;


export const DELETE_MAHNUNG = gql`
    mutation DeleteMahnung($id: ID!){
        deleteMahnung(id: $id) {
            ok
        }
    }
`;





