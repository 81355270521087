import * as React from 'react';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import AlertTitle from '@mui/material/AlertTitle';

import IconButton from '@mui/material/IconButton';

import ReactDOM from 'react-dom';

export default function AlertMessage({ message, severity, isOpenAlert, handleCloseAlertButton }) {
    
    const anchor = ReactDOM.createRoot(document.getElementById('alerts'));
        
    // see https://felixgerschau.com/react-hooks-settimeout/
    React.useEffect(() => {
        //console.log('use effect läuft jetzt. severity: ' + severity);
        let duration;
        if(severity === "success") {
            duration = 5000;
        } else {
            // set duration to one minute
            duration = 60000;
        }

        const setTimer = setTimeout(() => {
            handleCloseAlertButton();          
        },duration);
        
        // important!! clear timeout!! See link above
        return () => clearTimeout(setTimer);
    }, [message, isOpenAlert, severity]); //run only (on first mount and) if state of message, state of isOpenAlert, or state of severity changes



    const element = (
        <Box sx={{ width: '100%' }}>
            <Collapse in={isOpenAlert}>
                <Alert
                    severity={severity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleCloseAlertButton();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {message}
                </Alert>
            </Collapse>
        </Box>               
    );

    anchor.render(element);

};