//import { useQuery, gql, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';


export const GET_ENERGIEVERBRAEUCHE = gql`
    query GetEnergieverbraeuche {
        energieverbraeuche{
            id
            zaehlernummer
            parzelle
            verwaltungsparzelle
            paechter
            energieart
            abrechnungsperiode
            verbrauch
            hinweis
        }
    }
`;


export const CALC_ENERGIEVERBRAEUCHE = gql`
    mutation CalcEnergieverbraeuche($abrechnungsperiode: Int!) {
        calcEnergieverbraeuche(abrechnungsperiode: $abrechnungsperiode) {
            gesamtanzahlZaehler
            relevanteZaehler
            successCounter
            hintCounter
            errorCounter
        }
    }
`;

