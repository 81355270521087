import * as React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';

import { UPDATE_GEMEINKOSTEN, GET_GEMEINKOSTEN } from './graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import AlertMessage from '../Alerts/AlertMessage';


let loads;



// yup validation

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    .string()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    .required("Dieses Feld ist ein Pflichtfeld");

const currentDate = new Date();
const currentYear = currentDate.getFullYear();



const validationSchema = yup.object({
    gueltigkeit: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        //.min(currentYear, 'Das Jahr darf nicht in der Vergangenheit liegen')
        .test('len', 'Bitte das Jahr vierstellig erfassen', val => val && val.toString().length === 4)
        .required('Bitte das Jahr vierstellig erfassen'),
    reparaturkosten: euroValidator,
    versicherung: euroValidator,
    grundbesitzabgaben: euroValidator,
    strassenbeleuchtung: euroValidator
});



// format currency from database format 00.00 to 00,00, so it is displayed correctly in frontend
function formatCurrencyFromDB(amount) {
    return amount.replace(".", ",");
}

// format currency back from frontend format 00,00 to database format 00.00, so it can be written back to database
function formatCurrencyForDB(amount) {
    return amount.replace(",", ".");
}

// format currency for correct display in alert
function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




export default function UpdateGemeinkosten() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const params = useParams();
    const gemeinkostenID = params.id;
    const navigate = useNavigate();

    const client = useApolloClient();
    
    // get cached zahlung - on client- to prefill update form - so no query is needed
    // see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-fragments
    const selectedGemeinkosten = client.readFragment({
        id: 'GemeinkostenType:' + gemeinkostenID, // The value of the apollo client cache ID
        fragment: gql`
          fragment selectedGemeinkosten on GemeinkostenType {
            id
            gueltigkeit
            reparaturkosten
            versicherung
            grundbesitzabgaben
            strassenbeleuchtung
          }
        `,
    });

    console.log(selectedGemeinkosten);
    
    const [updateGemeinkosten, { data, loading, error, refetch }] = useMutation(UPDATE_GEMEINKOSTEN, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_GEMEINKOSTEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.updateGemeinkosten?.id)) {
                setAlertMessage('Gemeinkosten mit der ID ' + data.updateGemeinkosten.id + ' erfolgreich geändert');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/nebenkosten/gemeinkosten`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    const goBack = () => {
		navigate(-1);
	}

    const initialValues = {
        gueltigkeit: selectedGemeinkosten.gueltigkeit,
        reparaturkosten: formatCurrencyFromDB(selectedGemeinkosten.reparaturkosten),
        versicherung: formatCurrencyFromDB(selectedGemeinkosten.versicherung),
        grundbesitzabgaben: formatCurrencyFromDB(selectedGemeinkosten.grundbesitzabgaben),
        strassenbeleuchtung: formatCurrencyFromDB(selectedGemeinkosten.strassenbeleuchtung)
    };


    


    const handleSubmit = (values) => {

        updateGemeinkosten({ 
            variables: {
                id: gemeinkostenID,
                gueltigkeit: parseInt(values.gueltigkeit),
                reparaturkosten: formatCurrencyForDB(values.reparaturkosten),
                versicherung: formatCurrencyForDB(values.versicherung),
                grundbesitzabgaben: formatCurrencyForDB(values.grundbesitzabgaben),
                strassenbeleuchtung: formatCurrencyForDB(values.strassenbeleuchtung)
            }
        });
        
    };




    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Gemeinkosten bearbeiten</Title>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="gueltigkeit"
                                            name="gueltigkeit"
                                            label="Gültig für (Jahr)"
                                            value={values.gueltigkeit}
                                            onChange={handleChange}
                                            error={touched.gueltigkeit && Boolean(errors.gueltigkeit)}
                                            helperText={touched.gueltigkeit && errors.gueltigkeit}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="reparaturkosten"
                                            name="reparaturkosten"
                                            label="Kosten Reparatur- und Wartungskosten der Versorgungseinrichtungen (EUR)"
                                            value={values.reparaturkosten}
                                            onChange={handleChange}
                                            error={touched.reparaturkosten && Boolean(errors.reparaturkosten)}
                                            helperText={touched.reparaturkosten && errors.reparaturkosten}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="versicherung"
                                            name="versicherung"
                                            label="Kosten Versicherung Schrankenanlage (EUR)"
                                            value={values.versicherung}
                                            onChange={handleChange}
                                            error={touched.versicherung && Boolean(errors.versicherung)}
                                            helperText={touched.versicherung && errors.versicherung}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="grundbesitzabgaben"
                                            name="grundbesitzabgaben"
                                            label="Kosten Grundbesitzabgaben (EUR)"
                                            value={values.grundbesitzabgaben}
                                            onChange={handleChange}
                                            error={touched.grundbesitzabgaben && Boolean(errors.grundbesitzabgaben)}
                                            helperText={touched.grundbesitzabgaben && errors.grundbesitzabgaben}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="strassenbeleuchtung"
                                            name="strassenbeleuchtung"
                                            label="Kosten Straßenbeleuchtung (EUR)"
                                            value={values.strassenbeleuchtung}
                                            onChange={handleChange}
                                            error={touched.strassenbeleuchtung && Boolean(errors.strassenbeleuchtung)}
                                            helperText={touched.strassenbeleuchtung && errors.strassenbeleuchtung}
                                        />

                                        <Button color="primary" variant="contained" type="submit">
                                            ändern
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};

