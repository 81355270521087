import React from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from './UserContext';


const PrivateRoute = ({ children }) => {
    const user = React.useContext(UserContext);
    //console.log(user[0]);
    
    //return user[0] ? children : <Navigate to="/login" />;
    return children
    
}

export default PrivateRoute;