import * as React from 'react';
import { useQuery } from '@apollo/client';

import {GET_PARZELLEN, DELETE_PARZELLE} from './graphql'
import Title from '../Utils/Title'
import LoadingAnimation from '../Utils/LoadingAnimation';

import ContextMenu from './ContextMenu';
import DeleteParzelle from './Delete';
import AlertMessage from '../Alerts/AlertMessage';

import { Link } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';




function formatdDate(date) {
    if(date.value == null) {
        return;
    } else {
        date = date.value.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}

 
function formatBool(val) {
    //console.log('Wert: ' + val.value);
    //if(val == true) {
    if(val.value) {
        return 'Ja';
    } else {
        return 'Nein';
    }
}


const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);



export default function ListParzellen() {
    
    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeleteParzelle' of child 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');
    
    const [loadingState, setLoadingState] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    const parzellenList = data? data.parzellen.map(item => {
        const id = item.id;
        const standort = item.standort;
        const parzellengroesse = item.parzellengroesse;
        const besonderheiten = item.besonderheiten;
        const zusatzpflege = item.zusatzpflege;
        const fiSchalterNeu  = item.fiSchalterNeu;
        const stellplatzparzelle = item.stellplatzparzelle;
        const verwaltungsparzelle = item.verwaltungsparzelle;
        return { 
            id,
            standort,
            parzellengroesse,
            besonderheiten,
            zusatzpflege,
            fiSchalterNeu,
            stellplatzparzelle,
            verwaltungsparzelle,
        };
    }) : '';

    const rows = parzellenList;

    const navigate = useNavigate();

    

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'standort', headerName: 'Standort', width: 250, editable: true, },
        { field: 'parzellengroesse', headerName: 'Grösse (qm) ', width: 150, type: 'number', },
        { field: 'stellplatzparzelle', headerName: 'Stellplatzparzelle', width: 170, type: 'boolean', valueFormatter: (params) => formatBool(params) },
        { field: 'verwaltungsparzelle', headerName: 'Verwaltungsparzelle', width: 170, type: 'boolean', valueFormatter: (params) => formatBool(params) },
        { field: 'besonderheiten', headerName: 'Besonderheiten', width: 200, },
        {
            field: "actions",
            headerName:
                <>
                <Tooltip title="Parzelle anlegen">
                    <Link to={`/parzelle/create`}>
                        <IconButton>
                            <AddBoxIcon
                                variant='filled'
                                
                                color='action'
                                sx={{
                                    '&:hover': {
                                        color:'#233044'
                                    },
                                    marginLeft: '0.1rem',
                                    fontSize: '1.25rem'
                                }}
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
                </>,
            
            description: "",
            sortable: false,
            filterable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <ContextMenu
                        params={params}
                        assignAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
                        assignAlertSeverity={(alertSeverity) => setAlertSeverity(alertSeverity)}
                        handleOpenAlert={() => {setOpenAlert(true) }}
                    />
                );
            }
        }
    ];
    
    return (
        <>
        
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Parzellen</Title>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'standort', sort: 'asc' }],
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                checkboxSelection
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                loading={loadingState}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );

}