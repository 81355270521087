import * as React from 'react';
import { useParams } from "react-router-dom";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';
import { GET_ZAHLUNGEN } from './graphql';
import { GET_PAECHTER } from '../Paechter/graphql'
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { Link } from "react-router-dom";

import Autocomplete from '@mui/material/Autocomplete';
import Title from '../Utils/Title'
import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


let loads;

function GetZahlungen() {
    //const [loads, setLoading] = React.useState(false)
    const { loading, error, data, refetch } = useQuery(GET_ZAHLUNGEN, {
        onCompleted: () => {
            loads=false;
            //console.log('completed. loads: ' + loads);
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        //console.log('loading...loads: ' + loads);
        return loads;
    };
    if (error) return <p>Error :(</p>;
    console.log(data);
    console.log(data.zahlungen);
    return data.zahlungen;
    
}


function GetPaechter() {
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;
    return data.paechter;
}


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

function formatBoolean(bool) {
    if (bool == false) {
        return 'Nein';
    }
    else {
        return 'Ja';
    }
}

// fomat chached date to dd.MM.yyyy
function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
    } else {
        // important: set date to empty string - otherwise yup will make field required!!
        date = '';
    }
    return date;
}



export default function ShowZahlung() {

    const params = useParams();
    const zahlungID = params.id;
    const zahlungen = GetZahlungen();
    const getPaechter = GetPaechter();

    const navigate = useNavigate();
    const goBack = () => {
		navigate(-1);
	}

    const handleEditButton = () => {
        navigate(`/zahlung/${zahlungID}/update`);
    }

    // filter to get parzelle with id
    const filtered = Object.values(zahlungen).filter(zahlung => zahlung.id === zahlungID);

    // get paechter
    const paechterData = filtered[0].pacht.paechter;


    const LinkTextFieldPaechter = () => {

        const inputStyles = {
            height: '56px',
        };

        return (
          <TextField
            id="link-textfield"
            label="Pächter"
            variant="filled"
            fullWidth
            size="medium"
            InputProps={{
              inputComponent: Namen,
              style: inputStyles,
            }}
            value={Namen}
            // Add any other props as needed
          />
        );
    };

    const Namen = () => (
        <Typography component="div" className="reference-link">
            <Link to={`/paechter/${paechterData.id}/show`}>
                {paechterData.name}, {paechterData.vorname}
            </Link>
        </Typography>
    );



    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                        >
                            
                            {filtered.map(zahlung => {
                                return (
                                    <>
                                        <Title>Zahlung über EUR {formatCurrency(zahlung.betrag)} von {zahlung.pacht.paechter.vorname} {zahlung.pacht.paechter.name} anzeigen</Title>

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Betrag (EUR)"
                                            value={formatCurrency(zahlung.betrag)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Buchungsdatum"
                                            value={formatCachedDate(zahlung.buchungsdatum)}
                                        />
                                        <LinkTextFieldPaechter/>
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Zahlungsart"
                                            value={zahlung.zahlungsart}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Kontierungsnummer"
                                            value={zahlung.pacht.kontierungsnummer}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Hinweis"
                                            value={zahlung.hinweis}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Ausgleichszahlung"
                                            value={formatBoolean(zahlung.ausgleichszahlung)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Pachtzahlung"
                                            value={formatBoolean(zahlung.pachtzahlung)}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Sonstige Rechnung"
                                            value={formatBoolean(zahlung.sonstigeRechnung)}
                                        />
                                        
                                    </>                                    
                                );
                                })
                            }
                            <Button color="primary" variant="contained" onClick={handleEditButton}>
                                bearbeiten
                            </Button>
                            <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                zurück
                            </Button>
                            
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};