import * as React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';

import {UPDATE_ZAEHLER, GET_ZAEHLER} from './graphql';
import {GET_PARZELLEN} from '../Parzellen/graphql';
import { GET_ZAEHLERSTAENDE } from '../Zaehlerstaende/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import AlertMessage from '../Alerts/AlertMessage';
import Autocomplete from '@mui/material/Autocomplete';



let loads;


// fomat chached date to dd.MM.yyyy so the prefilled date can be filled in in correct format
function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}

// fomat date back to yyyy-MM-dd so it can be written back to database
function formatDate(date) {
    if(date) {
        date = date.split(".");
        // if day has not 2 digits
        if (date[0].length < 2) {
            date[0] = "0" + date[0];
        }
        // if month has not 2 digits
        if (date[1].length < 2) {
            date[1] = "0" + date[1];
        }
        date = date[2] + "-" + date[1] + "-" + date[0]; 
    } else {
        date = null;
    }   
    return date;
}


// format currency from database format 00.00 to 00,00, so it is displayed correctly in frontend
function formatCurrencyFromDB(amount) {
    if(amount) {
        return amount.replace(".", ",");
    }
    return null;
}

// format currency back from frontend format 00,00 to database format 00.00, so it can be written back to database
function formatCurrencyForDB(amount) {
    if(amount) {
        return amount.replace(",", ".");
    }
    return null;
}

// format currency for correct display in alert
function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




// Get Parzellen
function GetParzellen() {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;

    // Es dürfen nur Stellplatzparzellen ausgegeben werden
    const filteredParzellen = data.parzellen.filter(item => item.anzahlStellplaetze > 0);

    return filteredParzellen.map(({ id, standort, anzahlStellplaetze }) => (
        {id, standort, anzahlStellplaetze}
    ));
    
}


// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');
const today = new Date();
const currentYear = today.getFullYear();
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');

// if date has to be in the future
const validateDateFuture = validateDate.min(tomorrow, 'Das Datum muss in der Zukunft liegen');

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    );


const validationSchema = yup.object({
    zaehlernummer: requiredString,
    parzelle: yup.object().required("Es muss eine Parzelle ausgewählt werden"),
    energieart: requiredString,
    /* per 20231214 vorübergehend deaktiviert aufgrund des Hinweises von Frau Hoven
    installationAm: validateDatePast.required('Dieses Feld ist ein Pflichtfeld'),
    letzteEichung: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        .min(2000, 'Das Jahr liegt zu weit zurück')
        .max(currentYear, 'Das Jahr darf nicht in der Zukunft liegen')
        .required('Bitte das Jahr vierstellig erfassen'),
    */

        
    /*
    Das Feld naechsteEichung wird automatisch vom backend befüllt
    naechsteEichung: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        .min(currentYear, 'Das Jahr darf nicht in der Vergangenheit liegen')
        .required('Bitte das Jahr vierstellig erfassen')
        .when('letzteEichung', (letzteEichung, schema) => {
            return schema.test({
                name: 'is-after-letzteEichung',
                message: 'Das nächste Eichungsdatum muss nach dem letzten Eichungsdatum liegen',
                test: function (naechsteEichung) {
                    return naechsteEichung && letzteEichung ? naechsteEichung > letzteEichung : true;
                },
            });
        }),
    */
    zaehlermiete: yup.boolean().required('Bitte auswählen, ob die Zählermiete in Rechnung gestellt werden soll'),
    pauschalpreis: euroValidator.nullable(true),
    deinstallationAm: validateDatePast.nullable(true),
    zaehlerstandBeiDeinstallation: yup.number().nullable(true),
    zaehlerwechsel: yup.boolean().required('Bitte auswählen, ob es sich um einen Zählerwechsel handelt'),
})



export default function UpdateZaehler() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const parzelleData = GetParzellen();

    const params = useParams();
    const zaehlerID = params.id;
    const navigate = useNavigate();

    const client = useApolloClient();
    
    // get cached zahlung - on client- to prefill update form - so no query is needed
    // see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-fragments
    const selectedZaehler = client.readFragment({
        id: 'ZaehlerType:' + zaehlerID, // The value of the apollo client cache ID
        fragment: gql`
          fragment selectedZaehler on ZaehlerType {
            id
            zaehlernummer
            parzelle {
                id
                standort
            }
            energieart
            installationAm
            zaehlerstandBeiInstallation
            letzteEichung
            naechsteEichung
            zaehlermiete
            pauschalpreis
            deinstallationAm
            zaehlerstandBeiDeinstallation
            zaehlerwechsel
          }
        `,
    });

    
    // useState to store values of parzelleId
    const [parzelleValue, setParzelleValue] = React.useState(selectedZaehler?.parzelle);

    console.log(parzelleValue);


    const [updateZaehler, { data, loading, error, refetch }] = useMutation(UPDATE_ZAEHLER, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_ZAEHLER },
            { query: GET_ZAEHLERSTAENDE }
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.updateZaehler?.id)) {
                setAlertMessage('Zähler mit der Nummer ' + data.updateZaehler.zaehlernummer + ' erfolgreich geändert');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/energie/zaehler`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                let newMessage;
                if(message.includes('Duplicate entry')) {
                    newMessage = 'Änderung nicht möglich: Diese Zählernummer wurde bereits bei einem anderen Zähler vergeben und Zählernummern dürfen nicht mehrfach vorkommen.';
                    setAlertMessage(newMessage);
                } else {
                    setAlertMessage(message);
                };
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    const goBack = () => {
		//navigate(-1);
        navigate(`/energie/zaehler`)
	}

    const initialValues = {
        zaehlernummer: selectedZaehler?.zaehlernummer,
        parzelle: selectedZaehler?.parzelle,
        energieart: selectedZaehler?.energieart,
        installationAm: formatCachedDate(selectedZaehler?.installationAm),
        zaehlerstandBeiInstallation: selectedZaehler?.zaehlerstandBeiInstallation,
        letzteEichung: selectedZaehler?.letzteEichung,
        //naechsteEichung: selectedZaehler?.naechsteEichung,
        zaehlermiete: selectedZaehler?.zaehlermiete,
        pauschalpreis: formatCurrencyFromDB(selectedZaehler?.pauschalpreis),
        deinstallationAm: formatCachedDate(selectedZaehler?.deinstallationAm),
        zaehlerstandBeiDeinstallation: selectedZaehler?.zaehlerstandBeiDeinstallation,
        //zaehlerwechsel: selectedZaehler?.zaehlerwechsel,
        zaehlerwechsel: null,
    };


    


    const handleSubmit = (values) => {

        const formatBool = (val) => {
            if(val == '') {
                return false;
            }
            return val;
        }

        updateZaehler({ 
            variables: {
                id: zaehlerID,
                zaehlernummer: values.zaehlernummer,
                parzelle: values.parzelle.id,
                energieart: values.energieart,
                installationAm: formatDate(values.installationAm),
                zaehlerstandBeiInstallation: parseInt(values.zaehlerstandBeiInstallation),
                letzteEichung: parseInt(values.letzteEichung),
                naechsteEichung: null,
                zaehlermiete: formatBool(values.zaehlermiete),
                pauschalpreis: formatCurrencyForDB(values.pauschalpreis),
                deinstallationAm: formatDate(values.deinstallationAm),
                zaehlerstandBeiDeinstallation: parseInt(values.zaehlerstandBeiDeinstallation),
                zaehlerwechsel: formatBool(values.zaehlerwechsel),
            }
        });
        
    };




    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Zähler bearbeiten</Title>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>
                                        
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            //disabled
                                            id="zaehlernummer"
                                            name="zaehlernummer"
                                            label="Zählernummer"
                                            value={values.zaehlernummer}
                                            onChange={handleChange}
                                            error={touched.zaehlernummer && Boolean(errors.zaehlernummer)}
                                            helperText={touched.zaehlernummer && errors.zaehlernummer}
                                        />

                                        <Autocomplete
                                            options={parzelleData}
                                            filterSelectedOptions
                                            disabled
                                            value={parzelleValue}
                                            onChange={(e, parzelleValue) => {
                                                // at first set UseState
                                                setParzelleValue(parzelleValue);
                                                console.log(parzelleValue);
                                                // then use state to set field value
                                                setFieldValue(
                                                    "parzelle", parzelleValue
                                                );
                                            }}
                                            // important so that initially selected values get considered
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            getOptionLabel={(option) => option.standort}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    {option.standort}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params}
                                                    variant='filled'
                                                    label="Parzelle"
                                                    name="parzelle"
                                                    required
                                                    error={touched.parzelle && Boolean(errors.parzelle)}
                                                    helperText={touched.parzelle && errors.parzelle}
                                                />
                                            )}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                        >
                                            <InputLabel>Energieart</InputLabel>
                                            <Select
                                                MenuProps={{
                                                    sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                    }
                                                }}
                                                id="energieart"
                                                name="energieart"
                                                required
                                                disabled
                                                value={values.energieart}
                                                onChange={handleChange}
                                                error={touched.energieart && Boolean(errors.energieart)}
                                                helperText={touched.energieart && errors.energieart}
                                            >
                                                <MenuItem className='selectField' value='Strom'>Strom</MenuItem>
                                                <MenuItem className='selectField' value='Wasser'>Wasser</MenuItem>
                                                <MenuItem className='selectField' value='Gas'>Gas</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.energieart && errors.energieart ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333 !important", marginLeft: "16px !important" }}
                                                >
                                                    {touched.energieart && errors.energieart}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            disabled
                                            id="installationAm"
                                            name="installationAm"
                                            label='Installation am'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.installationAm}
                                            onChange={handleChange}
                                            error={touched.installationAm && Boolean(errors.installationAm)}
                                            helperText={touched.installationAm && errors.installationAm}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            disabled
                                            id='zaehlerstandBeiInstallation'
                                            name='zaehlerstandBeiInstallation'
                                            label='Zählerstand bei Installation'
                                            value={values.zaehlerstandBeiInstallation}
                                            onChange={handleChange}
                                            error={touched.zaehlerstandBeiInstallation && Boolean(errors.zaehlerstandBeiInstallation)}
                                            helperText={touched.zaehlerstandBeiInstallation && errors.zaehlerstandBeiInstallation}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id='letzteEichung'
                                            name='letzteEichung'
                                            label='Letzte Eichung'
                                            value={values.letzteEichung}
                                            onChange={handleChange}
                                            error={touched.letzteEichung && Boolean(errors.letzteEichung)}
                                            helperText={touched.letzteEichung && errors.letzteEichung}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Zählermiete in Rechnung stellen</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="zaehlermiete"
                                                name="zaehlermiete"
                                                value={values.zaehlermiete}
                                                onChange={handleChange}
                                                error={touched.zaehlermiete && Boolean(errors.zaehlermiete)}
                                                helperText={touched.zaehlermiete && errors.zaehlermiete}
                                            >
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.zaehlermiete && errors.zaehlermiete ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.zaehlermiete && errors.zaehlermiete}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="pauschalpreis"
                                            name="pauschalpreis"
                                            label="Pauschalpreis (EUR)"
                                            value={values.pauschalpreis}
                                            onChange={handleChange}
                                            error={touched.pauschalpreis && Boolean(errors.pauschalpreis)}
                                            helperText={touched.pauschalpreis && errors.pauschalpreis}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="deinstallationAm"
                                            name="deinstallationAm"
                                            label='Deinstallation am'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.deinstallationAm}
                                            onChange={handleChange}
                                            error={touched.deinstallationAm && Boolean(errors.deinstallationAm)}
                                            helperText={touched.deinstallationAm && errors.deinstallationAm}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id='zaehlerstandBeiDeinstallation'
                                            name='zaehlerstandBeiDeinstallation'
                                            label='Zählerstand bei Deinstallation'
                                            value={values.zaehlerstandBeiDeinstallation}
                                            onChange={handleChange}
                                            error={touched.zaehlerstandBeiDeinstallation && Boolean(errors.zaehlerstandBeiDeinstallation)}
                                            helperText={touched.zaehlerstandBeiDeinstallation && errors.zaehlerstandBeiDeinstallation}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Zählerwechsel</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="zaehlerwechsel"
                                                name="zaehlerwechsel"
                                                value={values.zaehlerwechsel}
                                                onChange={handleChange}
                                                error={touched.zaehlerwechsel && Boolean(errors.zaehlerwechsel)}
                                                helperText={touched.zaehlerwechsel && errors.zaehlerwechsel}
                                            >
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.zaehlerwechsel && errors.zaehlerwechsel ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.zaehlerwechsel && errors.zaehlerwechsel}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>
                                        
                                        <Button color="primary" variant="contained" type="submit">
                                            ändern
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};

