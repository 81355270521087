import * as React from 'react';
import { useParams } from "react-router-dom";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';
import { GET_ZAEHLER } from './graphql';
import { GET_PARZELLEN } from '../Parzellen/graphql'
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { Link } from "react-router-dom";

import Autocomplete from '@mui/material/Autocomplete';
import Title from '../Utils/Title'
import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


let loads;

function GetZaehler() {
    //const [loads, setLoading] = React.useState(false)
    const { loading, error, data, refetch } = useQuery(GET_ZAEHLER, {
        onCompleted: () => {
            loads=false;
            //console.log('completed. loads: ' + loads);
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        //console.log('loading...loads: ' + loads);
        return loads;
    };
    if (error) return <p>Error :(</p>;
    console.log(data);
    console.log(data.zaehler);
    return data.zaehler;
}


function formatCurrency(number) {
    if (number) {
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number);
    }
    return '';
}

// fomat chached date to dd.MM.yyyy
function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
    } else {
        // important: set date to empty string - otherwise yup will make field required!!
        date = '';
    }
    return date;
}



export default function ShowZaehler() {

    const params = useParams();
    const zaehlerID = params.id;
    const zaehler = GetZaehler();

    const navigate = useNavigate();
    const goBack = () => {
		navigate(-1);
	}

    const handleEditButton = () => {
        navigate(`/energie/zaehler/${zaehlerID}/update`);
    }

    // filter to get parzelle with id
    const filtered = Object.values(zaehler).filter(zaehler => zaehler.id === zaehlerID);

    // get paechter
    const parzelleData = filtered[0].parzelle;


    const LinkTextFieldParzelle = () => {

        const inputStyles = {
            height: '56px',
        };

        return (
          <TextField
            id="link-textfield"
            label="Parzelle"
            variant="filled"
            fullWidth
            size="medium"
            InputProps={{
              inputComponent: ParzelleStandorte,
              style: inputStyles,
            }}
            value={ParzelleStandorte}
          />
        );
    };
    
    const ParzelleStandorte = () => (
            <Typography component="div" className="reference-link">
                <Link to={`/parzelle/${parzelleData.id}/show`}>
                    {parzelleData.standort} 
                </Link>
            </Typography>
    );


    // set const for 'Schlüssel im Büro'    
    function formatBoolZaehlermiete() {
        if (filtered[0].zaehlermiete) {
            if(filtered[0].zaehlermiete.toString() == 'true') {
                return 'Ja';
            }
            else {
                return 'Nein';
            }
        }
        return 'Nein';
    }
    const formatZaehlermiete = formatBoolZaehlermiete();






    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                        >
                            
                            {filtered.map(zaehler => {
                                return (
                                    <>
                                        <Title>Zähler Nr. {zaehler.zaehlernummer} anzeigen</Title>

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Zählernummer"
                                            value={zaehler.zaehlernummer}
                                        />

                                        <LinkTextFieldParzelle/>

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Energieart"
                                            value={zaehler.energieart}
                                        />
                                        
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Installation am"
                                            value={formatCachedDate(zaehler.installationAm)}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Zählerstand bei Installation"
                                            value={zaehler.zaehlerstandBeiInstallation}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Letzte Eichung"
                                            value={zaehler.letzteEichung}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Nächste Eichung"
                                            value={zaehler.naechsteEichung}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Zählermiete"
                                            value={formatZaehlermiete}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Pauschalpreis"
                                            value={formatCurrency(zaehler.pauschalpreis)}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Deinstallation am"
                                            value={formatCachedDate(zaehler.deinstallationAm)}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Zählerstand bei Deinstallation"
                                            value={zaehler.zaehlerstandBeiDeinstallation}
                                        />
                                        
                                    </>                                    
                                );
                                })
                            }
                            <Button color="primary" variant="contained" onClick={handleEditButton}>
                                bearbeiten
                            </Button>
                            <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                zurück
                            </Button>
                            
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};