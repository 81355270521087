import { gql } from '@apollo/client';


export const GET_GESAMTRECHNUNGEN = gql`
    query GetGesamtrechnungen {
        gesamtrechnungen{
            id
            parzelleStandort
            paechtername
            paechtervorname
            debNr
            zeitraumVon
            zeitraumBis
            endsaldo
            rechnungslaufId
            abrechnungsperiode
        }
    }
`;


export const GET_ENDSALDO_VORJAHR = gql`
    query GetEndsaldoVorjahr($pachtID: ID!) {
        endsaldoVorjahr(pachtId: $pachtID)
    }
`;


export const CREATE_GESAMTRECHNUNG = gql`
    mutation CreateGesamtrechnung(
        $pacht: ID!,
        $abrechnungsperiode: String!,
        $rechnungslaufId: Int,
        $vermittlungsgebuehr: Decimal,
        ) {
        createGesamtrechnung(
            pacht: $pacht,
            abrechnungsperiode: $abrechnungsperiode,
            rechnungslaufId: $rechnungslaufId,
            vermittlungsgebuehr: $vermittlungsgebuehr,
             ) {
                id
                endsaldo
            }
    }
`;




export const DELETE_GESAMTRECHNUNG = gql`
    mutation DeleteGesamtrechnung($id: ID!){
        deleteGesamtrechnung(id: $id) {
            ok
        }
    }
`;


