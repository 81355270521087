import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import { GET_MAHNUNGEN, GET_ARCHIVIERTE_MAHNUNGEN, DELETE_MAHNUNG } from './graphql'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';





export default function DeleteMahnung({ mahnung, isOpenDialog, handleCloseDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
    

    const [deleteMahnung, { data, loading, error, refetch }] = useMutation(DELETE_MAHNUNG, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_MAHNUNGEN },
            { query: GET_ARCHIVIERTE_MAHNUNGEN }
        ],
        //errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.deleteMahnung?.ok)) {
                assignAlertMessage('Mahnung gelöscht');
                assignAlertSeverity('success');
            }
        },
    });


    
    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                console.error('Unhandled error:', error);
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);


    
    
    function DeleteMahnung(givenId) {
        return deleteMahnung({
            variables: {
                id: givenId
            }
        });
    };
    

    return (
        <>
            {isOpenDialog ? (
                <Dialog
                    open={isOpenDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie die {mahnung.mahnstufe} mit der ID {mahnung.id} an {mahnung.paechter} wirklich löschen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Das Löschen kann <b>nicht rückgängig</b> gemacht werden!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}autoFocus>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseDialog();
                                DeleteMahnung(mahnung.id);
                                handleOpenAlert();
                            }
                        }
                        >Löschen</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}