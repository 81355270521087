import * as React from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { GET_ONE_PACHT } from '../graphql';
import Title from '../../Utils/Title';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LoadingAnimation from '../../Utils/LoadingAnimation';

function GetOnePacht(pachtID) {
    const { loading, error, data } = useQuery(GET_ONE_PACHT, {
        variables: { pachtID },
        onCompleted: () => {
            console.log('Query completed');
        }
    });

    if (loading) {
        return { loading: true, data: null, error: null };
    }
    if (error) {
        return { loading: false, data: null, error: error.message };
    }
    return { loading: false, data: data.onePacht, error: null };
}

function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = `${date[2]}.${date[1]}.${date[0]}`;
    } else {
        date = '';
    }
    return date;
}

function formatBoolean(val) {
    if (val) {
        return 'ja';
    } else {
        return 'nein';
    }
    
}

function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

export default function ShowPacht() {
    const params = useParams();
    const pachtID = params.id;
    const navigate = useNavigate();
    const { loading, data, error } = GetOnePacht(pachtID);

    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
            <LoadingAnimation />
        </Box>
    );
    if (error) return <p>Error: {error}</p>;

    const onepacht = data;

    const goBack = () => {
        navigate(-1);
    }


    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid item xs={12}>
                    
                        <Box noValidate>

                            {onepacht && onepacht.map(pacht => (
                                <React.Fragment key={pacht.id}>

                                    <Title>Pachtvertrag {pacht.parzelle.standort}</Title>

                                    <TextField
                                        fullWidth
                                        disabled
                                        variant='filled'
                                        label="Parzelle"
                                        value={pacht.parzelle.standort}
                                    />

                                    <TextField
                                        fullWidth
                                        disabled
                                        variant='filled'
                                        label="Pächter"
                                        value={`${pacht.paechter.vorname} ${pacht.paechter.name}`}
                                    />

                                    <TextField
                                        fullWidth
                                        disabled
                                        variant='filled'
                                        label="Kontierungsnummer"
                                        value={pacht.kontierungsnummer}
                                    />

                                    <TextField
                                        fullWidth
                                        disabled
                                        variant='filled'
                                        label="Acto"
                                        value={formatBoolean(pacht.aConto)}
                                    />

                                    <TextField
                                        fullWidth
                                        disabled
                                        variant='filled'
                                        label="Pachtbeginn"
                                        value={formatCachedDate(pacht.pachtbeginn)}
                                    />

                                    <TextField
                                        fullWidth
                                        disabled
                                        variant='filled'
                                        label="Pachtende"
                                        value={formatCachedDate(pacht.pachtende)}
                                    />
                                    
                                </React.Fragment>
                            ))}
                            
                        </Box>
                    
                </Grid>
            </Grid>
        </Container>
    );
}
