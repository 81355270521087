import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

import {CREATE_GEMEINKOSTEN, GET_GEMEINKOSTEN} from './graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


import AlertMessage from '../Alerts/AlertMessage';


let loads;


// format currency back from format 00,00 to 00.00, so it can be written back to database
function formatBackCurrency(amount) {
    if (amount) {
        return amount.replace(",", ".");
    }
    return;
}


// yup validation

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    .string()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    .required("Dieses Feld ist ein Pflichtfeld");

const currentDate = new Date();
const currentYear = currentDate.getFullYear();



const validationSchema = yup.object({
    gueltigkeit: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        //.min(currentYear, 'Das Jahr darf nicht in der Vergangenheit liegen')
        .test('len', 'Bitte das Jahr vierstellig erfassen', val => val && val.toString().length === 4)
        .required('Bitte das Jahr vierstellig erfassen'),
    reparaturkosten: euroValidator,
    versicherung: euroValidator,
    grundbesitzabgaben: euroValidator,
    strassenbeleuchtung: euroValidator
});


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




export default function CreateGemeinkosten() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();

    const [createGemeinkosten, { data, loading, error, refetch }] = useMutation(CREATE_GEMEINKOSTEN, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_GEMEINKOSTEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.createGemeinkosten?.id)) {
                setAlertMessage('Energiepreis mit der ID ' + data.createGemeinkosten.id +
                 ' für das Jahr ' + data.createGemeinkosten.gueltigkeit + ' erfolgreich angelegt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/nebenkosten/gemeinkosten`);
                }, 2000);
            }
        }
    });


    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);

    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        gueltigAb: '',
        preisart: '',
        preis: '',
    };


    const handleSubmit = (values) => {
        
        createGemeinkosten({ 
            variables: {
                gueltigkeit: parseInt(values.gueltigkeit),
                reparaturkosten: formatBackCurrency(values.reparaturkosten),
                versicherung: formatBackCurrency(values.versicherung),
                grundbesitzabgaben: formatBackCurrency(values.grundbesitzabgaben),
                strassenbeleuchtung: formatBackCurrency(values.strassenbeleuchtung)
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Gemeinkosten anlegen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="gueltigkeit"
                                            name="gueltigkeit"
                                            label="Gültig für (Jahr)"
                                            value={values.gueltigkeit}
                                            onChange={handleChange}
                                            error={touched.gueltigkeit && Boolean(errors.gueltigkeit)}
                                            helperText={touched.gueltigkeit && errors.gueltigkeit}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="reparaturkosten"
                                            name="reparaturkosten"
                                            label="Kosten Reparatur- und Wartungskosten der Versorgungseinrichtungen (EUR)"
                                            value={values.reparaturkosten}
                                            onChange={handleChange}
                                            error={touched.reparaturkosten && Boolean(errors.reparaturkosten)}
                                            helperText={touched.reparaturkosten && errors.reparaturkosten}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="versicherung"
                                            name="versicherung"
                                            label="Kosten Versicherung Schrankenanlage (EUR)"
                                            value={values.versicherung}
                                            onChange={handleChange}
                                            error={touched.versicherung && Boolean(errors.versicherung)}
                                            helperText={touched.versicherung && errors.versicherung}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="grundbesitzabgaben"
                                            name="grundbesitzabgaben"
                                            label="Kosten Grundbesitzabgaben (EUR)"
                                            value={values.grundbesitzabgaben}
                                            onChange={handleChange}
                                            error={touched.grundbesitzabgaben && Boolean(errors.grundbesitzabgaben)}
                                            helperText={touched.grundbesitzabgaben && errors.grundbesitzabgaben}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="strassenbeleuchtung"
                                            name="strassenbeleuchtung"
                                            label="Kosten Straßenbeleuchtung (EUR)"
                                            value={values.strassenbeleuchtung}
                                            onChange={handleChange}
                                            error={touched.strassenbeleuchtung && Boolean(errors.strassenbeleuchtung)}
                                            helperText={touched.strassenbeleuchtung && errors.strassenbeleuchtung}
                                        />

                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




