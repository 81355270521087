import React from 'react';
import Body from './Body';
import { deDE } from '@mui/material/locale';
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import UserContext from '../Authentication/UserContext';
import { LOGOUT } from '../Authentication/graphql';


////

import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import LogoutIcon from '@mui/icons-material/Logout';

import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

import MainMenu from '../Navbar/MainMenu';



const theme = createTheme(
    {
      components: {
        // Name of the component
        MuiButtonBase: {
          styleOverrides: {
            // Name of the slot
            root: {
              '& svg, & .MuiListItemText-root': {
                color: '#d9d9d9'
              },
              '&:hover, &:focus, &.Mui-selected': {
                '& svg, & .MuiListItemText-root': {
                  color: '#ffffff',
                 
                },
              },
              '&.Mui-selected, &:hover': {
                backgroundColor: '#2b3c54 !important',
              },
            },
          },
        },
        MuiDivider: {
          styleOverrides: {
            root: {
              borderColor: '#1a2432'
            }
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              backgroundColor: 'rgba(0, 0, 0, 0.02)'
            }
          }
        }
        
      },
  
      palette: {
        mode: 'light',
        primary: {
          light: '#ffff00',
          main: '#5777a8',
          dark: '#ffff00',
          drawer: '#233044',
        },
        secondary: {
          light: '#ffff00',
          main: '#5777a8',
          dark: '#5777a8',
        },
        
      },
    },
    deDE,
  );


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      backgroundColor: theme.palette.primary.drawer,
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
        backgroundColor: theme.palette.primary.drawer,
      }),
    },
  }),
);


export default function AppContent() {
    const [open, setOpen] = React.useState(false);
    
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();

    const toggleDrawer = () => {
        setOpen(!open);
    };


    /*
    let userLoggedIn = React.useContext(UserContext)[0];
    if (!userLoggedIn) {
      userLoggedIn = {firstName: 'anonymous'};
    }
    console.log(userLoggedIn);

    
    const user = React.useContext(UserContext)[0];
    if (user.firstName ==="") {
        user.firstName = "Anonymous";
        user.lastName = "User";
    }
    */


const [logout, { data, loading, error, refetch }] = useMutation(LOGOUT, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
        
    onError: ({graphQLErrors, networkError, error}) => {

        // if any error appears
        setAlertMessage('Fehler! Logout nicht erfolgreich!');
        setAlertSeverity('error');
        setOpenAlert(true);

    },
    
    onCompleted: (data) => {
        console.log(data);
        //if (Boolean(data.createMobilheim.id)) {
        if (data.deleteTokenCookie.deleted) {            
            setTimeout(() => {
                navigate(`/login`);
            }, 500);
        } else {
            setAlertMessage('Logout nicht erfolgreich!');
            setAlertSeverity('error');
            setOpenAlert(true);
        }

    }
});


const handleLogout = () => {
    logout();
};

  
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.primary.drawer
                : theme.palette.primary.drawer,
          }}
        >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '30px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Verwaltung Mobilheimpark Dreiländersee
            </Typography>

            

            <IconButton color="inherit">
              <LogoutIcon onClick={handleLogout} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer 
          variant="permanent"
          open={open}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.primary.drawer
                : theme.palette.primary.drawer,
          }}
        
        >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
              
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainMenu />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Body />

        </Box>
      </Box>
    </ThemeProvider>
  );
}