import { gql } from '@apollo/client';


export const GET_ENERGIERECHNUNGEN = gql`
    query GetEnergierechnungen {
        energierechnungen{
            id
            rechnungsdatum
            rechnungsnummer
            parzelleStandort
            paechtername
            debNr
            zeitraumVon
            zeitraumBis
            rechnungsbetrag
            rechnungslaufId
            storniert
            stornoVon
            abgeschlossen
            abrechnungsperiode
            paechtername
            paechtervorname
        }
    }
`;


export const GET_ENERGIERECHNUNGEN_OF_PACHT = gql`
    query GetEnergierechnungenOfPacht($pachtID: ID!) {
        energierechnungenOfPacht(pachtId: $pachtID){
            id
            rechnungsdatum
            rechnungsnummer
            debNr
            rechnungsbetrag
            paechtername
            paechtervorname
            storniert
            stornoVon
        }
    }
`;


export const CREATE_ENERGIERECHNUNG = gql`
    mutation CreateEnergierechnung(
        $pacht: ID!,
        $abrechnungsperiode: String!,
        $rechnungslaufId: Int,
        $stornoVon: String,
        ) {
        createEnergierechnung(
            pacht: $pacht,
            abrechnungsperiode: $abrechnungsperiode,
            rechnungslaufId: $rechnungslaufId,
            stornoVon: $stornoVon,
             ) {
                anzahlErstellteEnergierechnungen
                erstellteRechnungenRechnungsnummern
                resultList
            }
    }
`;


export const LOCK_ENERGIERECHNUNG = gql`
    mutation LockEnergierechnung($id: ID!){
        lockEnergierechnung(id: $id) {
            resultList
        }
    }
`;



// Für eine Stornierung nutzen wir im backend die mutation createEnergierechnung
export const STORNO_ENERGIERECHNUNG = gql`
    mutation CreateEnergierechnung(
        $stornoVon: String,
        $pacht: ID,
        $abrechnungsperiode: String,
        $rechnungslaufId: Int,
        ){
        createEnergierechnung(
            stornoVon: $stornoVon,
            pacht: $pacht,
            abrechnungsperiode: $abrechnungsperiode,
            rechnungslaufId: $rechnungslaufId,
            ) {
                resultList
        }
    }
`;



export const DELETE_ENERGIERECHNUNG = gql`
    mutation DeleteEnergierechnung($id: ID!){
        deleteEnergierechnung(id: $id) {
            resultList
        }
    }
`;


