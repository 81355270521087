import * as React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';

import {UPDATE_PAECHTER, GET_PAECHTER, GET_ARCHIVIERTE_PAECHTER} from './graphql';
import {GET_MOBILHEIME} from '../Mobilheime/graphql';
import {GET_PARZELLEN} from '../Parzellen/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import AlertMessage from '../Alerts/AlertMessage';



let loads;

// fomat chached date to dd.MM.yyyy so the prefilled date can be filled in in correct format
function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


// fomat date back to yyyy-MM-dd so it can be written back to database
function formatDate(date) {
    if(date) {
        date = date.split(".");
        // if day has not 2 digits
        if (date[0].length < 2) {
            date[0] = "0" + date[0];
        }
        // if month has not 2 digits
        if (date[1].length < 2) {
            date[1] = "0" + date[1];
        }
        date = date[2] + "-" + date[1] + "-" + date[0]; 
    } else {
        date = null;
    }   
    return date;
}

function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}



// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');

const today = new Date();

const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');

// if date has to be in the future
const validateDateFuture = validateDate.min(tomorrow, 'Das Datum muss in der Zukunft liegen');

const validateIBAN = yup.string()
    .typeError('Bitte die IBAN korrekt erfassen')
    .min(15, 'Eine IBAN muss mindstens 15 Stellen lang sein');
    

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    

const validationSchema = yup.object({
    anrede: requiredString,
    name: requiredString,
    vorname: requiredString,
    strasse: requiredString,
    plz: requiredString
        .typeError('Bitte die Postleitzahl erfassen')
        .min(5, 'Die PLZ muss (in Deutschland) 5 Stellen lang sein'),
    ort: requiredString,
    geburtsdatum: validateDatePast.required('Dieses Feld ist ein Pflichtfeld'),
    geburtsdatumPartner: validateDatePast,
    eingetragenInPachtvertrag: yup.boolean(),
    iban: validateIBAN,
    auflagenfrist: validateDate,
    antragPv: validateDate,
    pruefungPv: validateDate,
    trennschalterPv: yup.boolean(),
    kaufdatumSensor1: validateDate,
    kaufdatumSensor2: validateDate,
    ausgabedatumMagnetkarte: validateDate,
    gebrauchteSensoren: yup.number()
        .typeError('Bitte geben Sie eine Zahl größer 0 ein oder lassen Sie das Feld frei')
        .min(1,'Bitte geben Sie eine Zahl größer 0 ein oder lassen Sie das Feld frei')
        .nullable(true),
    ausgeschiedenAm: validateDate,
});


// Get Parzellen
function GetParzellen() {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;

    console.log(data);
      
    return data.parzellen.map(({ id, standort }) => (
        {id, standort}
    ));
    
}



export default function UpdateParzelle() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');


    const params = useParams();
    const paechterId = params.id;
    const navigate = useNavigate();

    const client = useApolloClient();
    // get cached paechter - on client- to prefill update form - so no query is needed
    // see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-fragments
    const selectedPaechter = client.readFragment({
        id: 'PaechterType:' + paechterId, // The value of the apollo client cache ID
        fragment: gql`
          fragment selectedPaechter on PaechterType {
            id
            anrede
            name
            vorname
            strasse
            plz
            ort
            vorherigeMeldeadresse
            telefonFestnetz
            telefonMobil
            geburtsdatum
            namePartner
            geburtsname
            geburtsdatumPartner
            eingetragenInPachtvertrag
            ausweisnummerPaechter
            ausweisnummerPartner
            weitereKontaktpersonen
            iban
            zusatzpflege
            auflagen
            auflagenfrist
            antragPv
            pruefungPv
            trennschalterPv
            duldungen
            briefkastenNr
            sensor1
            kaufdatumSensor1
            sensor2
            kaufdatumSensor2
            magnetkarte
            ausgabedatumMagnetkarte
            gebrauchteSensoren
            kfzKennzeichen
            erschliessungskosten
            ausgeschiedenAm
          }
        `,
    });

    //console.log(paechterId);
    console.log(selectedPaechter);


    // useState to store values of parzelleId
    const [parzelleValue, setParzelleValue] = React.useState(selectedPaechter?.parzellen);
    
    const parzelleData = GetParzellen();

    const [updatePaechter, { data, loading, error, refetch }] = useMutation(UPDATE_PAECHTER, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_PAECHTER },
            { query: GET_ARCHIVIERTE_PAECHTER },
            { query: GET_PARZELLEN },
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.updatePaechter?.id)) {
                setAlertMessage('Pächter ' + data.updatePaechter.vorname + ' ' + data.updatePaechter.name + ' erfolgreich geändert');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/paechter`);
                }, 2000);
            }
        }
    });


    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    const goBack = () => {
		navigate(-1);
	}

    const initialValues = {
        anrede: selectedPaechter.anrede,
        name: selectedPaechter.name,
        vorname: selectedPaechter.vorname,
        strasse: selectedPaechter.strasse,
        plz: selectedPaechter.plz,
        ort: selectedPaechter.ort,
        vorherigeMeldeadresse: selectedPaechter.vorherigeMeldeadresse,
        telefonFestnetz: selectedPaechter.telefonFestnetz,
        telefonMobil: selectedPaechter.telefonMobil,
        geburtsdatum: formatCachedDate(selectedPaechter.geburtsdatum),
        namePartner: selectedPaechter.namePartner,
        geburtsname: selectedPaechter.geburtsname,
        geburtsdatumPartner: formatCachedDate(selectedPaechter.geburtsdatumPartner),
        eingetragenInPachtvertrag: selectedPaechter.eingetragenInPachtvertrag,
        ausweisnummerPaechter: selectedPaechter.ausweisnummerPaechter,
        ausweisnummerPartner: selectedPaechter.ausweisnummerPartner,
        weitereKontaktpersonen: selectedPaechter.weitereKontaktpersonen,
        iban: selectedPaechter.iban,
        zusatzpflege: selectedPaechter.zusatzpflege,
        auflagen: selectedPaechter.auflagen,
        auflagenfrist: formatCachedDate(selectedPaechter.auflagenfrist),
        antragPv: formatCachedDate(selectedPaechter.antragPv),
        pruefungPv: formatCachedDate(selectedPaechter.pruefungPv),
        trennschalterPv: selectedPaechter.trennschalterPv,
        duldungen: selectedPaechter.duldungen,
        briefkastenNr: selectedPaechter.briefkastenNr,
        sensor1: selectedPaechter.sensor1,
        kaufdatumSensor1: formatCachedDate(selectedPaechter.kaufdatumSensor1),
        sensor2: selectedPaechter.sensor2,
        kaufdatumSensor2: formatCachedDate(selectedPaechter.kaufdatumSensor2),
        magnetkarte: selectedPaechter.magnetkarte,
        ausgabedatumMagnetkarte: formatCachedDate(selectedPaechter.ausgabedatumMagnetkarte),
        gebrauchteSensoren: selectedPaechter.gebrauchteSensoren,
        kfzKennzeichen: selectedPaechter.kfzKennzeichen,
        erschliessungskosten: selectedPaechter.erschliessungskosten,
        ausgeschiedenAm: formatCachedDate(selectedPaechter.ausgeschiedenAm)
    };


    const handleSubmit = (values) => {

        /*
        const mobilheime = (val) => {
            console.log(val);
            if(val !== '') {
                // get id's of array of objects, see https://stackoverflow.com/a/64110076/2732698
                return val.map( (item)=>item.id);
            }
            return [];
        }
        */

        const parzellen = (val) => {
            console.log(val);
            if(val !== '') {
                // get id's of array of objects, see https://stackoverflow.com/a/64110076/2732698
                return val.map( (item)=>item.id);
            }
            return [];
        }

        const formatBool = (val) => {
            console.log(val);
            if(val == '') {
                return false;
            }
            return val;
        }

        
        updatePaechter({ 
            variables: {
                id: paechterId,
                anrede: values.anrede,
                name: values.name,
                vorname: values.vorname,
                strasse: values.strasse,
                plz: values.plz,
                ort: values.ort,
                vorherigeMeldeadresse: values.vorherigeMeldeadresse,
                telefonFestnetz: values.telefonFestnetz,
                telefonMobil: values.telefonMobil,
                // format date back to yyyy-MM-dd
                geburtsdatum: formatDate(values.geburtsdatum),
                namePartner: values.namePartner,
                geburtsname: values.geburtsname,
                geburtsdatumPartner: formatDate(values.geburtsdatumPartner),
                eingetragenInPachtvertrag: formatBool(values.eingetragenInPachtvertrag),
                ausweisnummerPaechter: values.ausweisnummerPaechter,
                ausweisnummerPartner: values.ausweisnummerPartner,
                weitereKontaktpersonen: values.weitereKontaktpersonen,
                iban: values.iban,
                zusatzpflege: values.zusatzpflege,
                auflagen: values.auflagen,
                auflagenfrist: formatDate(values.auflagenfrist),
                antragPv: formatDate(values.antragPv),
                pruefungPv: formatDate(values.pruefungPv),
                trennschalterPv: formatBool(values.trennschalterPv),
                duldungen: values.duldungen,
                briefkastenNr: values.briefkastenNr,
                sensor1: values.sensor1,
                kaufdatumSensor1: formatDate(values.kaufdatumSensor1),
                sensor2: values.sensor2,
                kaufdatumSensor2: formatDate(values.kaufdatumSensor2),
                magnetkarte: values.magnetkarte,
                ausgabedatumMagnetkarte: formatDate(values.ausgabedatumMagnetkarte),
                gebrauchteSensoren: parseInt(values.gebrauchteSensoren),
                kfzKennzeichen: values.kfzKennzeichen,
                erschliessungskosten: values.erschliessungskosten,
                ausgeschiedenAm: formatDate(values.ausgeschiedenAm)
            }
        });
        
    };




    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Pächter ändern</Title>
                            
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>
                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Anrede</InputLabel>
                                            <Select
                                                MenuProps={{
                                                    sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                    }
                                                }}
                                                id="anrede"
                                                name="anrede"
                                                value={values.anrede}
                                                onChange={handleChange}
                                                error={touched.anrede && Boolean(errors.anrede)}
                                                helperText={touched.anrede && errors.anrede}
                                            >
                                                <MenuItem className='selectField' value='Herr'>Herr</MenuItem>
                                                <MenuItem className='selectField' value='Frau'>Frau</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.anrede && errors.anrede ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.anrede && errors.anrede}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="name"
                                            name="name"
                                            label="Nachname"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="vorname"
                                            name="vorname"
                                            label="Vorname"
                                            value={values.vorname}
                                            onChange={handleChange}
                                            error={touched.vorname && Boolean(errors.vorname)}
                                            helperText={touched.vorname && errors.vorname}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="strasse"
                                            name="strasse"
                                            label='Strasse und Hausnummer'
                                            value={values.strasse}
                                            onChange={handleChange}
                                            error={touched.strasse && Boolean(errors.strasse)}
                                            helperText={touched.strasse && errors.strasse}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="plz"
                                            name="plz"
                                            label="PLZ"
                                            value={values.plz}
                                            onChange={handleChange}
                                            error={touched.plz && Boolean(errors.plz)}
                                            helperText={touched.plz && errors.plz}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="ort"
                                            name="ort"
                                            label="Ort"
                                            value={values.ort}
                                            onChange={handleChange}
                                            error={touched.ort && Boolean(errors.ort)}
                                            helperText={touched.ort && errors.ort}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="vorherigeMeldeadresse"
                                            name="vorherigeMeldeadresse"
                                            label="Vorherige Meldeadresse"
                                            value={values.vorherigeMeldeadresse}
                                            onChange={handleChange}
                                            error={touched.vorherigeMeldeadresse && Boolean(errors.vorherigeMeldeadresse)}
                                            helperText={touched.vorherigeMeldeadresse && errors.vorherigeMeldeadresse}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="telefonFestnetz"
                                            name="telefonFestnetz"
                                            label="Telefon Festnetz"
                                            value={values.telefonFestnetz}
                                            onChange={handleChange}
                                            error={touched.telefonFestnetz && Boolean(errors.telefonFestnetz)}
                                            helperText={touched.telefonFestnetz && errors.telefonFestnetz}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="telefonMobil"
                                            name="telefonMobil"
                                            label="Telefon Mobil"
                                            value={values.telefonMobil}
                                            onChange={handleChange}
                                            error={touched.telefonMobil && Boolean(errors.telefonMobil)}
                                            helperText={touched.telefonMobil && errors.telefonMobil}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="geburtsdatum"
                                            name="geburtsdatum"
                                            label='Geburtsdatum'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.geburtsdatum}
                                            onChange={handleChange}
                                            error={touched.geburtsdatum && Boolean(errors.geburtsdatum)}
                                            helperText={touched.geburtsdatum && errors.geburtsdatum}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="namePartner"
                                            name="namePartner"
                                            label="Name Partner"
                                            value={values.namePartner}
                                            onChange={handleChange}
                                            error={touched.namePartner && Boolean(errors.namePartner)}
                                            helperText={touched.namePartner && errors.namePartner}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="geburtsname"
                                            name="geburtsname"
                                            label="Geburtsname"
                                            value={values.geburtsname}
                                            onChange={handleChange}
                                            error={touched.geburtsname && Boolean(errors.geburtsname)}
                                            helperText={touched.geburtsname && errors.geburtsname}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="geburtsdatumPartner"
                                            name="geburtsdatumPartner"
                                            label='Geburtsdatum Partner'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.geburtsdatumPartner}
                                            onChange={handleChange}
                                            error={touched.geburtsdatumPartner && Boolean(errors.geburtsdatumPartner)}
                                            helperText={touched.geburtsdatumPartner && errors.geburtsdatumPartner}
                                        />
                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                        >
                                            <InputLabel>Eingetragen in Pachtvertrag</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="eingetragenInPachtvertrag"
                                                name="eingetragenInPachtvertrag"
                                                value={values.eingetragenInPachtvertrag}
                                                onChange={handleChange}
                                                error={touched.eingetragenInPachtvertrag && Boolean(errors.eingetragenInPachtvertrag)}
                                                helperText={touched.eingetragenInPachtvertrag && errors.eingetragenInPachtvertrag}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.eingetragenInPachtvertrag && errors.eingetragenInPachtvertrag ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.eingetragenInPachtvertrag && errors.eingetragenInPachtvertrag}
                                                </FormHelperText>
                                            ) : null}

                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="ausweisnummerPaechter"
                                            name="ausweisnummerPaechter"
                                            label="Ausweisnummer Pächter"
                                            value={values.ausweisnummerPaechter}
                                            onChange={handleChange}
                                            error={touched.ausweisnummerPaechter && Boolean(errors.ausweisnummerPaechter)}
                                            helperText={touched.ausweisnummerPaechter && errors.ausweisnummerPaechter}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="ausweisnummerPartner"
                                            name="ausweisnummerPartner"
                                            label="Ausweisnummer Partner"
                                            value={values.ausweisnummerPartner}
                                            onChange={handleChange}
                                            error={touched.ausweisnummerPartner && Boolean(errors.ausweisnummerPartner)}
                                            helperText={touched.ausweisnummerPartner && errors.ausweisnummerPartner}
                                        />
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            variant='filled'
                                            id="weitereKontaktpersonen"
                                            name="weitereKontaktpersonen"
                                            label="Weitere Kontaktpersonen"
                                            value={values.weitereKontaktpersonen}
                                            onChange={handleChange}
                                            error={touched.weitereKontaktpersonen && Boolean(errors.weitereKontaktpersonen)}
                                            helperText={touched.weitereKontaktpersonen && errors.weitereKontaktpersonen}
                                        />
                                        
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="iban"
                                            name="iban"
                                            label="IBAN"
                                            value={values.iban}
                                            onChange={handleChange}
                                            error={touched.iban && Boolean(errors.iban)}
                                            helperText={touched.iban && errors.iban}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            multiline
                                            rows={3}
                                            id="zusatzpflege"
                                            name="zusatzpflege"
                                            label="Zusatzpflege"
                                            value={values.zusatzpflege}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            multiline
                                            rows={5}
                                            id="auflagen"
                                            name="auflagen"
                                            label="Auflagen"
                                            value={values.auflagen}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="auflagenfrist"
                                            name="auflagenfrist"
                                            label="Auflagenfrist"
                                            placeholder='TT.MM.JJJJ'
                                            value={values.auflagenfrist}
                                            onChange={handleChange}
                                            error={touched.auflagenfrist && Boolean(errors.auflagenfrist)}
                                            helperText={touched.auflagenfrist && errors.auflagenfrist}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="antragPv"
                                            name="antragPv"
                                            label="Antrag PV"
                                            placeholder='TT.MM.JJJJ'
                                            value={values.antragPv}
                                            onChange={handleChange}
                                            error={touched.antragPv && Boolean(errors.antragPv)}
                                            helperText={touched.antragPv && errors.antragPv}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="pruefungPv"
                                            name="pruefungPv"
                                            label="Prüfung PV"
                                            placeholder='TT.MM.JJJJ'
                                            value={values.pruefungPv}
                                            onChange={handleChange}
                                            error={touched.pruefungPv && Boolean(errors.pruefungPv)}
                                            helperText={touched.pruefungPv && errors.pruefungPv}
                                        />
                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                        >
                                            <InputLabel>Trennschalter PV</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="trennschalterPv"
                                                name="trennschalterPv"
                                                value={values.trennschalterPv}
                                                onChange={handleChange}
                                                error={touched.trennschalterPv && Boolean(errors.trennschalterPv)}
                                                helperText={touched.trennschalterPv && errors.trennschalterPv}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.trennschalterPv && errors.trennschalterPv ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.trennschalterPv && errors.trennschalterPv}
                                                </FormHelperText>
                                            ) : null}

                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="duldungen"
                                            name="duldungen"
                                            label="Duldungen"
                                            value={values.duldungen}
                                            onChange={handleChange}
                                            error={touched.duldungen && Boolean(errors.duldungen)}
                                            helperText={touched.duldungen && errors.duldungen}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="briefkastenNr"
                                            name="briefkastenNr"
                                            label="Briefkasten Nr."
                                            value={values.briefkastenNr}
                                            onChange={handleChange}
                                            error={touched.briefkastenNr && Boolean(errors.briefkastenNr)}
                                            helperText={touched.briefkastenNr && errors.briefkastenNr}
                                        />
                                        
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="sensor1"
                                            name="sensor1"
                                            label="Sensor 1"
                                            value={values.sensor1}
                                            onChange={handleChange}
                                            error={touched.sensor1 && Boolean(errors.sensor1)}
                                            helperText={touched.sensor1 && errors.sensor1}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="kaufdatumSensor1"
                                            name="kaufdatumSensor1"
                                            label="Kaufdatum Sensor 1"
                                            placeholder='TT.MM.JJJJ'
                                            value={values.kaufdatumSensor1}
                                            onChange={handleChange}
                                            error={touched.kaufdatumSensor1 && Boolean(errors.kaufdatumSensor1)}
                                            helperText={touched.kaufdatumSensor1 && errors.kaufdatumSensor1}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="sensor2"
                                            name="sensor2"
                                            label="Sensor 2"
                                            value={values.sensor2}
                                            onChange={handleChange}
                                            error={touched.sensor2 && Boolean(errors.sensor2)}
                                            helperText={touched.sensor2 && errors.sensor2}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="kaufdatumSensor2"
                                            name="kaufdatumSensor2"
                                            label="Kaufdatum Sensor 2"
                                            placeholder='TT.MM.JJJJ'
                                            value={values.kaufdatumSensor2}
                                            onChange={handleChange}
                                            error={touched.kaufdatumSensor2 && Boolean(errors.kaufdatumSensor2)}
                                            helperText={touched.kaufdatumSensor2 && errors.kaufdatumSensor2}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="magnetkarte"
                                            name="magnetkarte"
                                            label="Magnetkarte"
                                            value={values.magnetkarte}
                                            onChange={handleChange}
                                            error={touched.magnetkarte && Boolean(errors.magnetkarte)}
                                            helperText={touched.magnetkarte && errors.magnetkarte}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="ausgabedatumMagnetkarte"
                                            name="ausgabedatumMagnetkarte"
                                            label="Ausgabedatum Magnetkarte"
                                            placeholder='TT.MM.JJJJ'
                                            value={values.ausgabedatumMagnetkarte}
                                            onChange={handleChange}
                                            error={touched.ausgabedatumMagnetkarte && Boolean(errors.ausgabedatumMagnetkarte)}
                                            helperText={touched.ausgabedatumMagnetkarte && errors.ausgabedatumMagnetkarte}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="gebrauchteSensoren"
                                            name="gebrauchteSensoren"
                                            label="Gebrauchte Sensoren"
                                            value={values.gebrauchteSensoren}
                                            onChange={handleChange}
                                            error={touched.gebrauchteSensoren && Boolean(errors.gebrauchteSensoren)}
                                            helperText={touched.gebrauchteSensoren && errors.gebrauchteSensoren}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="kfzKennzeichen"
                                            name="kfzKennzeichen"
                                            label="KFZ-Kennzeichen"
                                            value={values.kfzKennzeichen}
                                            onChange={handleChange}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="erschliessungskosten"
                                            name="erschliessungskosten"
                                            label="Erschließungskosten"
                                            value={values.erschliessungskosten}
                                            onChange={handleChange}
                                            error={touched.erschliessungskosten && Boolean(errors.erschliessungskosten)}
                                            helperText={touched.erschliessungskosten && errors.erschliessungskosten}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="ausgeschiedenAm"
                                            name="ausgeschiedenAm"
                                            label="Ausgeschieden am"
                                            placeholder='TT.MM.JJJJ'
                                            value={values.ausgeschiedenAm}
                                            onChange={handleChange}
                                            error={touched.ausgeschiedenAm && Boolean(errors.ausgeschiedenAm)}
                                            helperText={touched.ausgeschiedenAm && errors.ausgeschiedenAm}
                                        />

                                        <Button color="primary" variant="contained" type="submit">
                                            ändern
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>
                                    </Form>
                            )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};



