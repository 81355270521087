import * as React from 'react';

import DeleteRechnungslauf from './Delete';
import LockRechnungslauf from './Lock';
import StornoRechnungslauf from './Storno';


import { useNavigate } from "react-router-dom";

import { styled, alpha } from '@mui/material/styles';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LockIcon from '@mui/icons-material/Lock';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import MenuBookIcon from '@mui/icons-material/MenuBook';



const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(0.5),
    minWidth: 160,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ContextMenu({ params, handleOpenAlert, assignAlertMessage, assignAlertSeverity }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openLockDialog, setOpenLockDialog] = React.useState(false);
  const [openStornoDialog, setOpenStornoDialog] = React.useState(false);

  const navigate = useNavigate();
  
  const handleClickLock = () => {
    setOpenLockDialog(true);
  };

  const handleClickStorno = () => {
    setOpenStornoDialog(true);
  };
  
  const handleClickDelete = () => {
      setOpenDialog(true);
  };

  const handleClickListLogs = (data) => {
    navigate(`/logs/${data.rechnungslaufId}/list`);  
  };

    
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      handleOpenAlert;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  // slash in Rechnungsnummer austauschen
  const rechnungslaufnummer = params.row.id;
  const file_link = 'https://backend.mobilheimpark.itoop.de/downloads/rechnungen/rechnungslaeufe/' + rechnungslaufnummer;


  return (
      <>
      <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}  
          //onClick={ () => { handleClick(); handleOpenAlert() }}
      >
          <MoreVertIcon />
      </IconButton>
      <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
      >
          <a href={file_link} target="_blank" rel="noopener noreferrer">
              <MenuItem onClick={() => { handleClose() }} disableRipple>
                  <FileDownloadIcon />
                  PDF
              </MenuItem> 
          </a> 
          <MenuItem onClick={() => { handleClickLock(); handleClose() }} disableRipple>
              <LockIcon />
              Abschliessen
          </MenuItem>
          <MenuItem onClick={() => { handleClickStorno(); handleClose() }} disableRipple>
              <RestoreIcon />
              Stornieren
          </MenuItem>
          <MenuItem onClick={() => { handleClickDelete(); handleClose() }} disableRipple>
              <DeleteForeverIcon />
              Löschen
          </MenuItem>
          <MenuItem onClick={() => { handleClickListLogs({ rechnungslaufId: params.row.id }); handleClose() }} disableRipple>
              <MenuBookIcon />
              Logs
          </MenuItem>      
      </StyledMenu>
      <LockRechnungslauf
            rechnungslauf={{ id: params.row.id }}
            isOpenLockDialog={openLockDialog === true}
            handleCloseLockDialog={() => setOpenLockDialog(false)}
            assignAlertMessage={assignAlertMessage}
            assignAlertSeverity={assignAlertSeverity}
            handleOpenAlert={handleOpenAlert}
        />
      <StornoRechnungslauf
            rechnungslauf={{ stornoVon: params.row.id, rechnungsart: params.row.rechnungsart }}
            isOpenStornoDialog={openStornoDialog === true}
            handleCloseStornoDialog={() => setOpenStornoDialog(false)}
            assignAlertMessage={assignAlertMessage}
            assignAlertSeverity={assignAlertSeverity}
            handleOpenAlert={handleOpenAlert}
      />
      <DeleteRechnungslauf
        rechnungslauf={{ id: params.row.id, rechnungsart: params.row.rechnungsart }}
        isOpenDialog={openDialog === true}
        handleCloseDialog={() => setOpenDialog(false)}
        assignAlertMessage={assignAlertMessage}
        assignAlertSeverity={assignAlertSeverity}
        handleOpenAlert={handleOpenAlert}
      />
      </>
  );
}
