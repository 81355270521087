import * as React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';

import { UPDATE_ZAEHLERSTAND, GET_ZAEHLERSTAENDE } from './graphql';
import {GET_ZAEHLER} from '../Zaehler/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { FormHelperText } from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import AlertMessage from '../Alerts/AlertMessage';
import Autocomplete from '@mui/material/Autocomplete';



let loads;


// fomat chached date to dd.MM.yyyy so the prefilled date can be filled in in correct format
function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}

// fomat date back to yyyy-MM-dd so it can be written back to database
function formatDate(date) {
    if(date) {
        date = date.split(".");
        // if day has not 2 digits
        if (date[0].length < 2) {
            date[0] = "0" + date[0];
        }
        // if month has not 2 digits
        if (date[1].length < 2) {
            date[1] = "0" + date[1];
        }
        date = date[2] + "-" + date[1] + "-" + date[0]; 
    } else {
        date = null;
    }   
    return date;
}


// format currency from database format 00.00 to 00,00, so it is displayed correctly in frontend
function formatCurrencyFromDB(amount) {
    if(amount) {
        return amount.replace(".", ",");
    }
    return null;
}

// format currency back from frontend format 00,00 to database format 00.00, so it can be written back to database
function formatCurrencyForDB(amount) {
    if(amount) {
        return amount.replace(",", ".");
    }
    return null;
}

// format currency for correct display in alert
function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




function GetZaehler() {
    const { loading, error, data, refetch } = useQuery(GET_ZAEHLER, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;

    console.log(data);
        
    return data.zaehler.map(({ id, zaehlernummer, energieart, parzelle  }) => (
        {id, zaehlernummer, energieart, parzelle}
    ));
    
}




// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');

const today = new Date();

const currentYear = today.getFullYear();

const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');

// if date has to be in the future
const validateDateFuture = validateDate.min(tomorrow, 'Das Datum muss in der Zukunft liegen');

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    );

const validationSchema = yup.object({
    ablesedatum: validateDatePast.required('Dieses Feld ist ein Pflichtfeld'),
    zaehler: yup.object().required("Es muss ein Zähler ausgewählt werden"),
    stand: yup
        .number()
        .typeError('Bitte den Zählerstand in ganzen Zahlen erfassen')
        .min(0, 'Der Zählerstand darf nicht negativ sein')
        .required('Bitte den Zählerstand erfassen'),
    schaetzung: yup.boolean().required('Bitte auswählen, ob es sich um einen Schätzwert handelt oder nicht'),
});




export default function UpdateZaehlerstand() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const zaehlerData = GetZaehler();

    const params = useParams();
    const zaehlerstandID = params.id;
    const navigate = useNavigate();

    const client = useApolloClient();
    
    // get cached zahlung - on client- to prefill update form - so no query is needed
    // see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-fragments
    const selectedZaehlerstand = client.readFragment({
        id: 'ZaehlerstandType:' + zaehlerstandID, // The value of the apollo client cache ID
        fragment: gql`
          fragment selectedZaehler on ZaehlerstandType {
            id
            ablesedatum
            zaehler {
                id
                zaehlernummer
                energieart
                parzelle {
                    standort
                }
            }
            stand
            schaetzung
          }
        `,
    });

    
    // useState to store values of parzelleId
    const [zaehlerValue, setZaehlerValue] = React.useState(selectedZaehlerstand.zaehler);


    const [updateZaehlerstand, { data, loading, error, refetch }] = useMutation(UPDATE_ZAEHLERSTAND, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_ZAEHLERSTAENDE }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.updateZaehlerstand?.id)) {
                setAlertMessage('Zählerstand mit der ID ' + data.updateZaehlerstand.id + ' erfolgreich geändert');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/energie/zaehlerstaende`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);



    const goBack = () => {
		navigate(-1);
	}

    const initialValues = {
        ablesedatum: formatCachedDate(selectedZaehlerstand.ablesedatum),
        zaehler: selectedZaehlerstand.zaehler,
        stand: selectedZaehlerstand.stand,
        schaetzung: selectedZaehlerstand.schaetzung
    };


    


    const handleSubmit = (values) => {

        const formatBool = (val) => {
            if(val == '') {
                return false;
            }
            return val;
        }

        updateZaehlerstand({ 
            variables: {
                id: zaehlerstandID,
                ablesedatum: formatDate(values.ablesedatum),
                zaehler: values.zaehler.id,
                stand: parseInt(values.stand),
                schaetzung: formatBool(values.schaetzung)
            }
        });
        
    };




    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Zählerstand bearbeiten</Title>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>
                                        
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="ablesedatum"
                                            name="ablesedatum"
                                            label='Ablesedatum'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.ablesedatum}
                                            onChange={handleChange}
                                            error={touched.ablesedatum && Boolean(errors.ablesedatum)}
                                            helperText={touched.ablesedatum && errors.ablesedatum}
                                        />

                                        <Autocomplete
                                            options={zaehlerData}
                                            filterSelectedOptions
                                            disabled
                                            value={zaehlerValue}
                                            onChange={(e, zaehlerValue) => {
                                                // at first set UseState
                                                setZaehlerValue(zaehlerValue);
                                                // then use state to set field value
                                                setFieldValue(
                                                    "zaehler", zaehlerValue
                                                );
                                            }}
                                            // important so that initially selected values get considered
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            getOptionLabel={(option) => (option.parzelle.standort + " | Nr. " + option.zaehlernummer + " | " + option.energieart)}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    {option.parzelle.standort} | Nr. {option.zaehlernummer} | {option.energieart}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params}
                                                    variant='filled'
                                                    label="Zaehler"
                                                    name="zaehler"
                                                    required
                                                    error={touched.zaehler && Boolean(errors.zaehler)}
                                                    helperText={touched.zaehler && errors.zaehler}
                                                />
                                            )}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id='stand'
                                            name='stand'
                                            label='Zählerstand'
                                            value={values.stand}
                                            onChange={handleChange}
                                            error={touched.stand && Boolean(errors.stand)}
                                            helperText={touched.stand && errors.stand}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Schätzung</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="schaetzung"
                                                name="schaetzung"
                                                value={values.schaetzung}
                                                onChange={handleChange}
                                                error={touched.schaetzung && Boolean(errors.schaetzung)}
                                                helperText={touched.schaetzung && errors.schaetzung}
                                            >
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.schaetzung && errors.schaetzung ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.schaetzung && errors.schaetzung}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>
                                        
                                        <Button color="primary" variant="contained" type="submit">
                                            ändern
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};

