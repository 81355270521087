import * as React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';

import {UPDATE_EINZELKOSTEN, GET_EINZELKOSTEN} from './graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AlertMessage from '../Alerts/AlertMessage';




let loads;

// yup validation
const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    .required("Dieses Feld ist ein Pflichtfeld");

const currentDate = new Date();
const currentYear = currentDate.getFullYear();



const validationSchema = yup.object({
    gueltigAb: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        .test('len', 'Bitte das Jahr vierstellig erfassen', val => val && val.toString().length === 4)
        .required('Bitte das Jahr vierstellig erfassen'),
    kostenart: requiredString,
    kosten: euroValidator,
});



// format currency from database format 00.00 to 00,00, so it is displayed correctly in frontend
function formatCurrencyFromDB(amount) {
    return amount.replace(".", ",");
}

// format currency back from frontend format 00,00 to database format 00.00, so it can be written back to database
function formatCurrencyForDB(amount) {
    return amount.replace(",", ".");
}

// format currency for correct display in alert
function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




export default function UpdateEinzelkosten() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const params = useParams();
    const einzelkostenID = params.id;
    const navigate = useNavigate();

    const client = useApolloClient();
    
    // get cached zahlung - on client- to prefill update form - so no query is needed
    // see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-fragments
    const selectedEinzelkosten = client.readFragment({
        id: 'EinzelkostenType:' + einzelkostenID, // The value of the apollo client cache ID
        fragment: gql`
          fragment selectedEinzelkosten on EinzelkostenType {
            id
            gueltigAb
            kostenart
            kosten
          }
        `,
    });

    
    const [updateEinzelkosten, { data, loading, error, refetch }] = useMutation(UPDATE_EINZELKOSTEN, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_EINZELKOSTEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.updateEinzelkosten?.id)) {
                var betragMitCent = formatCurrency(data.updateEinzelkosten.kosten);
                setAlertMessage('Einzelkosten über EUR ' + betragMitCent + ' erfolgreich geändert');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/nebenkosten/einzelkosten`);
                }, 2000);
            }
        }
    });


    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    const goBack = () => {
		navigate(-1);
	}

    const initialValues = {
        gueltigAb: selectedEinzelkosten.gueltigAb,
        kostenart: selectedEinzelkosten.kostenart,
        kosten: formatCurrencyFromDB(selectedEinzelkosten.kosten),
    };


    


    const handleSubmit = (values) => {

        const formatBool = (val) => {
            if(val == '') {
                return false;
            }
            return val;
        }

        updateEinzelkosten({ 
            variables: {
                id: einzelkostenID,
                gueltigAb: parseInt(values.gueltigAb),
                kostenart: values.kostenart,
                kosten: formatCurrencyForDB(values.kosten),
            }
        });
        
    };




    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Einzelkosten bearbeiten</Title>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="gueltigAb"
                                            name="gueltigAb"
                                            label="Gültig ab (Jahr)"
                                            value={values.gueltigAb}
                                            onChange={handleChange}
                                            error={touched.gueltigAb && Boolean(errors.gueltigAb)}
                                            helperText={touched.gueltigAb && errors.gueltigAb}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Kostenart</InputLabel>
                                            <Select
                                                MenuProps={{
                                                    sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                    }
                                                }}
                                                id="kostenart"
                                                name="kostenart"
                                                value={values.kostenart}
                                                onChange={handleChange}
                                                error={touched.kostenart && Boolean(errors.kostenart)}
                                                helperText={touched.kostenart && errors.kostenart}
                                            >
                                                <MenuItem className='selectField' value='Biomülltonne 60 l'>Biomülltonne 60 l</MenuItem>
                                                <MenuItem className='selectField' value='Restmülltonne 60 l'>Restmülltonne 60 l</MenuItem>
                                                <MenuItem className='selectField' value='Restmülltonne 120 l'>Restmülltonne 120 l</MenuItem>
                                                <MenuItem className='selectField' value='Restmülltonne 240 l'>Restmülltonne 240 l</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.kostenart && errors.kostenart ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333 !important", marginLeft: "16px !important" }}
                                                >
                                                    {touched.kostenart && errors.kostenart}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="kosten"
                                            name="kosten"
                                            label="Kosten (EUR)"
                                            value={values.kosten}
                                            onChange={handleChange}
                                            error={touched.kosten && Boolean(errors.kosten)}
                                            helperText={touched.kosten && errors.kosten}
                                        />

                                        <Button color="primary" variant="contained" type="submit">
                                            ändern
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};

