import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_PAECHTER } from '../Paechter/graphql';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 20,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30, // Set a minimum height for all rows
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 13,
    },
    row: {
        flexDirection: 'row',
        padding: 5,
    },
    evenRow: {
        backgroundColor: '#f0f0f0', // Light gray for even rows
    },
    oddRow: {
        backgroundColor: '#ffffff', // White for odd rows
    },
    cell: {
        padding: 2,
    },
    name: {
        width: '35%',
    },
    vorname: {
        width: '35%',
    },
    kontierungsnummer: {
        width: '30%',
    },
});

const Debitoren = () => {
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER);

    const paechterList = data ? data.paechter
        .filter(item => !item.name.toLowerCase().includes('mob3ls'))
        .map(item => ({
            name: item.name,
            vorname: item.vorname,
            kontierungsnummer: item.pachtPaechter[0]?.kontierungsnummer,
        })) 
    : [];

    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        {/* Render table header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.name]}>Name</Text>
                            <Text style={[styles.cell, styles.header, styles.vorname]}>Vorname</Text>
                            <Text style={[styles.cell, styles.header, styles.kontierungsnummer]}>Kontierungsnummer</Text>
                        </View>

                        {/* Render rows */}
                        {paechterList.map((item, index) => (
                            <View key={index} style={[styles.row, index % 2 === 0 ? styles.evenRow : styles.oddRow]}>
                                <Text style={[styles.cell, styles.name]} wrap>{item.name}</Text>
                                <Text style={[styles.cell, styles.vorname]} wrap>{item.vorname}</Text>
                                <Text style={[styles.cell, styles.kontierungsnummer]} wrap>{item.kontierungsnummer}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default Debitoren;
