import * as React from 'react';

import IssueMahnung from './Issue';
import DeleteMahnung from './Delete';

import { styled, alpha } from '@mui/material/styles';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(0.5),
    minWidth: 160,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export function ContextMenu({ params, handleOpenAlert, assignAlertMessage, assignAlertSeverity }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openIssueDialog, setOpenIssueDialog] = React.useState(false);

  const handleClickIssue = () => {
    setOpenIssueDialog(true);
  };

  const handleClickDelete = () => {
      setOpenDialog(true);
  };

    
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      handleOpenAlert;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //console.log('params: ', params);

  
  // slash in Gutschriftsnummer austauschen
  const mahnungs_id = params.row.id;
  const file_link = 'https://backend.mobilheimpark.itoop.de/downloads/mahnungen/M_' + mahnungs_id;

  return (
        <>

        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}  
            //onClick={ () => { handleClick(); handleOpenAlert() }}
        >
            <MoreVertIcon />
        </IconButton>

        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            
            {params.row.status == 'Ausgegeben' && (
                <a href={file_link} target="_blank" rel="noopener noreferrer">
                    <MenuItem onClick={() => { handleClose() }} disableRipple>
                        <FileDownloadIcon />
                        PDF
                    </MenuItem> 
                </a> 
            )}
            
            <MenuItem onClick={() => { handleClickIssue(); handleClose() }} disableRipple>
                <CheckCircleIcon />
                Ausgeben
            </MenuItem>
            <MenuItem onClick={() => { handleClickDelete(); handleClose() }} disableRipple>
                <DeleteForeverIcon />
                Löschen
            </MenuItem>         
        </StyledMenu>

        <IssueMahnung
            mahnung={{ id: params.row.id, mahnstufe: params.row.mahnstufe, paechter: params.row.paechter }}
            isOpenIssueDialog={openIssueDialog === true}
            handleCloseIssueDialog={() => setOpenIssueDialog(false)}
            assignAlertMessage={assignAlertMessage}
            assignAlertSeverity={assignAlertSeverity}
            handleOpenAlert={handleOpenAlert}
        />
        
        <DeleteMahnung
            mahnung={{ id: params.row.id, mahnstufe: params.row.mahnstufe, paechter: params.row.paechter }}
            isOpenDialog={openDialog === true}
            handleCloseDialog={() => setOpenDialog(false)}
            assignAlertMessage={assignAlertMessage}
            assignAlertSeverity={assignAlertSeverity}
            handleOpenAlert={handleOpenAlert}
        />

        </>
  );
}






export function ContextMenuArchiv({ params, handleOpenAlert, assignAlertMessage, assignAlertSeverity }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openIssueDialog, setOpenIssueDialog] = React.useState(false);

  const handleClickIssue = () => {
    setOpenIssueDialog(true);
  };

  const handleClickDelete = () => {
      setOpenDialog(true);
  };

    
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      handleOpenAlert;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //console.log('params: ', params);

  
  // slash in Gutschriftsnummer austauschen
  const mahnungs_id = params.row.id;
  const file_link = 'https://backend.mobilheimpark.itoop.de/downloads/mahnungen/M_' + mahnungs_id;

  return (
        <>

        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}  
            //onClick={ () => { handleClick(); handleOpenAlert() }}
        >
            <MoreVertIcon />
        </IconButton>

        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            
            {params.row.status == 'Ausgegeben' && (
                <a href={file_link} target="_blank" rel="noopener noreferrer">
                    <MenuItem onClick={() => { handleClose() }} disableRipple>
                        <FileDownloadIcon />
                        PDF
                    </MenuItem> 
                </a> 
            )}
            
            <MenuItem onClick={() => { handleClickDelete(); handleClose() }} disableRipple>
                <DeleteForeverIcon />
                Löschen
            </MenuItem>         
        </StyledMenu>

        <DeleteMahnung
            mahnung={{ id: params.row.id, mahnstufe: params.row.mahnstufe, paechter: params.row.paechter }}
            isOpenDialog={openDialog === true}
            handleCloseDialog={() => setOpenDialog(false)}
            assignAlertMessage={assignAlertMessage}
            assignAlertSeverity={assignAlertSeverity}
            handleOpenAlert={handleOpenAlert}
        />

        </>
  );
}




export function ContextMenuPachtBoard({ params, handleOpenAlert, assignAlertMessage, assignAlertSeverity }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      handleOpenAlert;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  // slash in Gutschriftsnummer austauschen
  const mahnungs_id = params.row.id;
  const file_link = 'https://backend.mobilheimpark.itoop.de/downloads/mahnungen/M_' + mahnungs_id;

  return (
        <>

        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}  
        >
            <MoreVertIcon />
        </IconButton>

        {params.row.status == 'Ausgegeben' && (
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
              
              
            <a href={file_link} target="_blank" rel="noopener noreferrer">
                <MenuItem onClick={() => { handleClose() }} disableRipple>
                    <FileDownloadIcon />
                    PDF
                </MenuItem> 
            </a> 
              
              
            </StyledMenu>
        )}

        </>
  );
}









