import * as React from 'react';
import { useParams } from "react-router-dom";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';
import { GET_MOBILHEIME } from './graphql';
import { GET_PARZELLEN } from '../Parzellen/graphql';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { Link } from "react-router-dom";

import Autocomplete from '@mui/material/Autocomplete';
import Title from '../Utils/Title'
import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


let loads;

function GetMobilheime() {
    const { loading, error, data, refetch } = useQuery(GET_MOBILHEIME, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;
    return data.mobilheime;
}



function GetParzellen() {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
    if (loading) {
        loads = true;
        //console.log('loading...loads: ' + loads);
        return loads;
    };
    if (error) return <p>Error :(</p>;
    return data.parzellen;
}



// fomat chached date to dd.MM.yyyy
function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
    } else {
        // important: set date to empty string - otherwise yup will make field required!!
        date = '';
    }
    return date;
}

function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
        maximumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
    }).format(number);
}



export default function ShowMobilheim() {

    const params = useParams();
    const mobilheimID = params.id;
    const mobilheime = GetMobilheime();

    // we need the paechterSet if we navigate through queries
    const getParzellen = GetParzellen();

    const navigate = useNavigate();
    const goBack = () => {
		navigate(-1);
	}

    const handleEditButton = () => {
        navigate(`/mobilheim/${mobilheimID}/update`);
    }

    // filter to get mobilheim with id
    const filtered = Object.values(mobilheime).filter(mobilheim => mobilheim.id === mobilheimID);
    console.log(filtered);


    /*
    const LinkTextFieldParzelle = () => {

        const inputStyles = {
            height: '56px',
        };

        return (
            <TextField
                id="link-textfield"
                label="Standort (Parzelle)"
                variant="filled"
                fullWidth
                size="medium"
                InputProps={{
                inputComponent: standortLink,
                style: inputStyles,
                }}
                value={standortLink}
                // Add any other props as needed
                InputLabelProps={{
                    shrink: filtered[0].parzelle ? true : false,
                }}
            />
        );
    };
    
    const standortLink = () => {
            if (filtered[0].parzelle) {
                return (
                    <Typography component="div" className="reference-link">
                        <Link to={`/parzelle/${filtered[0].parzelle.id}/show`}>
                            {filtered[0].parzelle?.standort} 
                        </Link>
                    </Typography>
                )
            }
            return '';
    };
    


    // get paechter
    const paechterData = filtered[0].parzelle.paechterSet;

    const LinkTextFieldPaechter = () => {

        const inputStyles = {
            height: '56px',
        };

        
        return (
            <TextField
                id="link-textfield"
                label="Pächter"
                variant="filled"
                fullWidth
                disabled
                size="medium"
                InputProps={{
                    inputComponent: Namen,
                    style: inputStyles,
                }}
                value={Namen}
                InputLabelProps={{
                    shrink: paechterData.length > 0 ? true : false,
                }}
            />
        );
          

          
    };

    const Namen = () => (
        paechterData.map(item => {
            return (
                <Typography component="div" className="reference-link">
                    <Link to={`/paechter/${item.id}/show`}>
                        {item.name + ', ' + item.vorname}
                    </Link>
                </Typography>
            )
        })
    );


    < LinkTextFieldParzelle />
    < LinkTextFieldPaechter />
    
    */


  

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                        >
                            
                            {filtered.map(mobilheim => {
                                return (
                                    <>
                                        <Title>Mobilheim "{mobilheim.parzelle?.standort}" anzeigen</Title>
                                        <Title></Title>

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Standort"
                                            value={mobilheim.parzelle.standort}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Typ"
                                            value={mobilheim.typ}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Anbau"
                                            value={mobilheim.anbau}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Baujahr"
                                            value={mobilheim.baujahr}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Kaufdatum"
                                            value={formatCachedDate(mobilheim.kaufdatum)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Vorbesitzer"
                                            value={mobilheim.vorbesitzer}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Kaufpreis (EUR)"
                                            value={mobilheim.kaufpreis}
                                        />
                                        
                                    </>                                    
                                );
                                })
                            }
                            <Button color="primary" variant="contained" onClick={handleEditButton}>
                                bearbeiten
                            </Button>
                            <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                zurück
                            </Button>
                            
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};