import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import {GET_NEBENKOSTENRECHNUNGEN, STORNO_NEBENKOSTENRECHNUNG} from './graphql'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


export default function StornoNebenkostenrechnung({ nebenkostenrechnung, isOpenStornoDialog, handleCloseStornoDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {

    const [stornoNebenkostenrechnung, { data, loading, error, refetch }] = useMutation(STORNO_NEBENKOSTENRECHNUNG, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_NEBENKOSTENRECHNUNGEN },
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.createNebenkostenrechnung?.id)) {
                assignAlertMessage('Pachtrechnung mit der Nummer ' + data.createNebenkostenrechnung.stornoVon + ' storniert. Neue Pachtrechnung mit der Nr. ' +
                    data.createNebenkostenrechnung.rechnungsnummer + ' erstellt.');
                assignAlertSeverity('success');
            }
        },
    });


    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);
    
    
    function StornoNebenkostenrechnung(givenId) {
        return stornoNebenkostenrechnung({
            variables: {
                stornoVon: givenId,
                pacht: '',
                abrechnungsperiode: '',
                rechnungslaufId: null,
            }
        });
    };


    return (
        <>
            {isOpenStornoDialog ? (
                <Dialog
                    open={isOpenStornoDialog}
                    onClose={handleCloseStornoDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie die Nebenkostenrechnung mit der Nummer {nebenkostenrechnung.stornoVon} stornieren?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Eine Stornierung ist sinnvoll, wenn Sie zwischenzeitlich den Datenbestand geändert haben und nun eine Rechnungskorrektur erfolgen soll.<br />
                            Wenn Sie die Rechnung stornieren, wird diese als storniert gekennzeichnet und eine neue Nebenkostenrechnung erstellt.<br />
                            <b>Denken Sie bitte daran, ggfs. auch die Gesamtrechnung zu stornieren!</b>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseStornoDialog}>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseStornoDialog();
                                StornoNebenkostenrechnung(nebenkostenrechnung.stornoVon);
                                handleOpenAlert();
                            }
                        }
                        autoFocus
                        >Stonieren</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}