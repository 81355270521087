import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export default function MainMenu() {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Box sx={{ width: '100%' }}>
        <List component="nav" aria-label="dashboard">
            <ListItemButton
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}
                component={RouterLink} to="/"
            >
                <ListItemIcon>
                    <HomeOutlinedIcon />
                </ListItemIcon>
                <ListItemText 
                    primary="Dashboard"
                    /*
                    sx={{
                        color: '#f2f2f2',
                        '&:hover, &:focus': {color: '#ffffff'}
                    }}
                    */ 
                />
            </ListItemButton>
        </List>

        <Divider />

        <List component="nav" aria-label="objects">
            <ListItemButton
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
                component={RouterLink} to="/paechter"
            >
                <ListItemIcon>
                    <GroupOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Pächter" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 2}
                onClick={(event) => handleListItemClick(event, 2)}
                component={RouterLink} to="/mobilheime"
            >
                <ListItemIcon>
                    <HolidayVillageOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Mobilheime" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, 3)}
                component={RouterLink} to="/parzellen"
            >
                <ListItemIcon>
                    <DatasetOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Parzellen" />
            </ListItemButton>

        </List>
        
        <Divider />

        <List component="nav" aria-label="costs">

            <ListItemButton
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
                component={RouterLink} to="/pacht"
            >
                <ListItemIcon>
                    <AccountBalanceWalletOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Pacht" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 5}
                onClick={(event) => handleListItemClick(event, 5)}
                component={RouterLink} to="/energie/zaehlerstaende"
            >
                <ListItemIcon>
                    <ElectricBoltOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Energie" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 6}
                onClick={(event) => handleListItemClick(event, 6)}
                component={RouterLink} to="/nebenkosten"
            >
                <ListItemIcon>
                    <PointOfSaleOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Nebenkosten" />
            </ListItemButton>

        </List>

        <Divider />
        
        <List component="nav" aria-label="finance">

            <ListItemButton
                selected={selectedIndex === 7}
                onClick={(event) => handleListItemClick(event, 7)}
                component={RouterLink} to="/rechnungen/pachtrechnungen"
            >
                <ListItemIcon>
                    <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Rechnungen" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 8}
                onClick={(event) => handleListItemClick(event, 8)}
                component={RouterLink} to="/gutschriften"
            >
                <ListItemIcon>
                    <CardGiftcardIcon />
                </ListItemIcon>
                <ListItemText primary="Gutschriften" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 9}
                onClick={(event) => handleListItemClick(event, 9)}
                component={RouterLink} to="/zahlungen"
            >
                <ListItemIcon>
                    <EuroSymbolIcon />
                </ListItemIcon>
                <ListItemText primary="Zahlungen" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 10}
                onClick={(event) => handleListItemClick(event, 10)}
                component={RouterLink} to="/mahnungen"
            >
                <ListItemIcon>
                    <NotificationImportantIcon />
                </ListItemIcon>
                <ListItemText primary="Mahnungen" />
            </ListItemButton>

        </List>
            
        <Divider />

        <List component="nav" aria-label="statistics">

            <ListItemButton
                selected={selectedIndex === 11}
                onClick={(event) => handleListItemClick(event, 11)}
                component={RouterLink} to="/Listen"
            >
                <ListItemIcon>
                    <FormatListBulletedOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Listen" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 12}
                onClick={(event) => handleListItemClick(event, 12)}
                component={RouterLink} to="/statistiken"
            >
                <ListItemIcon>
                    <EqualizerIcon />
                </ListItemIcon>
                <ListItemText primary="Statistiken" />
            </ListItemButton>

        </List>

        <Divider />

        <List component="nav" aria-label="settings">

            <ListItemButton
                selected={selectedIndex === 13}
                onClick={(event) => handleListItemClick(event, 13)}
                component={RouterLink} to="/einstellungen"
            >
                <ListItemIcon>
                    <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Einstellungen" />
            </ListItemButton>
            
        </List>

    </Box>
  );
}