import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import {GET_MAHNUNGEN, ISSUE_MAHNUNG} from './graphql'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


export default function IssueMahnung({ mahnung, isOpenIssueDialog, handleCloseIssueDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
    
   

    const [issueMahnung, { data, loading, error, refetch }] = useMutation(ISSUE_MAHNUNG, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_MAHNUNGEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.issueMahnung?.ok)) {
                assignAlertMessage('Mahnung mit der ID ' + mahnung.id + ' ausgegeben. Die PDF steht nun zur Verfügung.');
                assignAlertSeverity('success');
            }
        },
    });


    
    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);

    
    
    function IssueMahnung(givenId) {
        return issueMahnung({
            variables: {
                id: givenId
            }
        });
    };
    

    return (
        <>
            {isOpenIssueDialog ? (
                <Dialog
                    open={isOpenIssueDialog}
                    onClose={handleCloseIssueDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie die {mahnung.mahnstufe} mit der ID {mahnung.id} an {mahnung.paechter} ausgeben?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Der Status wird dann in "Ausgegeben" geändert und die PDF erstellt.<br />
                            Bei Bedarf kann die Mahnung auch noch nach der Ausgabe gelöscht werden.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseIssueDialog}>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseIssueDialog();
                                IssueMahnung(mahnung.id);
                                handleOpenAlert();
                            }
                        }
                        autoFocus
                        >Ausgeben</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}