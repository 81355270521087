import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

import {CREATE_PARZELLE, GET_PARZELLEN} from './graphql';
import {GET_MOBILHEIME} from '../Mobilheime/graphql';
//import { GET_PAECHTER } from '../Paechter/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import AlertMessage from '../Alerts/AlertMessage';
import Autocomplete from '@mui/material/Autocomplete';


let loads;


// fomat date to yyyy-MM-dd so it can be written back to database
function formatDate(date) {
    // format only, if the date field is not an empty string
    // otherwise we get an error at non-mandatoy date fields, if date fields are not filled
    if(date === '') {
        // we have to return null, because in backend we can only make a date field optional when setting 'null=True' in models.py
        return null;
    } else {
        date = date.split(".");
        // if day has not 2 digits
        if (date[0].length < 2) {
            date[0] = "0" + date[0];
        }
        // if month has not 2 digits
        if (date[1].length < 2) {
            date[1] = "0" + date[1];
        }
        date = date[2] + "-" + date[1] + "-" + date[0];
        return date;
    }
}



function GetPaechter() {
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;

    console.log(data);
      
    return data.paechter.map(({ id, name, vorname }) => (
        {id, name, vorname}
    ));
    
}


// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');

const today = new Date();

const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');

// if date has to be in the future
const validateDateFuture = validateDate.min(tomorrow, 'Das Datum muss in der Zukunft liegen');

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');


const validationSchema = yup.object({
    standort: requiredString,
    parzellengroesse: requiredInt
        .typeError('Bitte die Quadratmeterzahl erfassen')
        .positive('Bitte eine positive Zahl erfassen'),
    fiSchalterNeu: requiredString,
    gasdruckpruefung: validateDatePast,
    geplanteGasdruckpruefung: validateDateFuture,
    gelbeTonne: yup.boolean().required('Bitte wählen'),
    blaueTonne: yup.boolean().required('Bitte wählen'),
    stellplatzparzelle: yup.boolean().required('Bitte wählen'),
    verwaltungsparzelle: yup.boolean().required('Bitte wählen'),
    schluesselImBuero: yup.boolean().required('Bitte wählen'),
});




export default function CreateParzelle() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeleteParzelle' of child 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();

    //const [paechterValue, setPaechterValue] = React.useState([]);

    //const paechterData = GetPaechter();


    const [createParzelle, { data, loading, error, refetch }] = useMutation(CREATE_PARZELLE, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_PARZELLEN },
            //{ query: GET_PAECHTER }
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.createParzelle?.id)) {
                setAlertMessage('Parzelle ' + data.createParzelle.standort + ' erfolgreich angelegt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/parzellen`);
                }, 2000);
            }
        }
    });


    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        standort: '',
        //paechter: [],
        parzellengroesse: '',
        besonderheiten: '',
        zusatzpflege: '',
        fiSchalterNeu: '',
        gasdruckpruefung: '',
        geplanteGasdruckpruefung: '',
        sav: '',
        bioTonneNr: '',
        gelbeTonne: '',
        blaueTonne: '',
        restmuellTonneNr: '',
        stellplatzparzelle: '',
        verwaltungsparzelle: '',
        schacht: '',
        schluesselImBuero: '',
    };


    const handleSubmit = (values) => {

        const formatBool = (val) => {
            if(val == '') {
                return false;
            }
            return val;
        }

        /*
        const mapPaechter = (val) => {
            console.log(val);
            if(val !== '') {
                // get id's of array of objects, see https://stackoverflow.com/a/64110076/2732698
                return val.map( (item)=>item.id);
            }
            return [];
        }
        */
        
        createParzelle({ 
            variables: {
                standort: values.standort,
                //paechterId: mapPaechter(values.paechter),
                parzellengroesse: values.parzellengroesse,
                fiSchalterNeu: values.fiSchalterNeu,
                besonderheiten: values.besonderheiten,
                zusatzpflege: values.zusatzpflege,
                gasdruckpruefung: formatDate(values.gasdruckpruefung),
                geplanteGasdruckpruefung: formatDate(values.geplanteGasdruckpruefung),
                sav: values.sav,
                bioTonneNr: values.bioTonneNr,
                gelbeTonne: formatBool(values.gelbeTonne),
                blaueTonne: formatBool(values.blaueTonne),
                restmuellTonneNr: values.restmuellTonneNr,
                stellplatzparzelle: formatBool(values.stellplatzparzelle),
                verwaltungsparzelle: formatBool(values.verwaltungsparzelle),
                schacht: values.schacht,
                schluesselImBuero: formatBool(values.schluesselImBuero),
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Parzelle anlegen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="standort"
                                            name="standort"
                                            label="Standort"
                                            value={values.standort}
                                            onChange={handleChange}
                                            error={touched.standort && Boolean(errors.standort)}
                                            helperText={touched.standort && errors.standort}
                                        />


                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            type='number'
                                            id="parzellengroesse"
                                            name="parzellengroesse"
                                            label='Grösse (in Quadratmetern)'
                                            value={values.parzellengroesse}
                                            onChange={handleChange}
                                            error={touched.parzellengroesse && Boolean(errors.parzellengroesse)}
                                            helperText={touched.parzellengroesse && errors.parzellengroesse}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="besonderheiten"
                                            name="besonderheiten"
                                            label="Besonderheiten"
                                            value={values.besonderheiten}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            fullWidth
                                            multiline
                                            variant='filled'
                                            rows={3}
                                            id="zusatzpflege"
                                            name="zusatzpflege"
                                            label='Zusatzpflege'
                                            value={values.zusatzpflege}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="aufmassVom"
                                            name="aufmassVom"
                                            label='Aufmass vom'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.aufmassVom}
                                            onChange={handleChange}
                                            error={touched.aufmassVom && Boolean(errors.aufmassVom)}
                                            helperText={touched.aufmassVom && errors.aufmassVom}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="gasdruckpruefung"
                                            name="gasdruckpruefung"
                                            label='Gasdruckprüfung'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.gasdruckpruefung}
                                            onChange={handleChange}
                                            error={touched.gasdruckpruefung && Boolean(errors.gasdruckpruefung)}
                                            helperText={touched.gasdruckpruefung && errors.gasdruckpruefung}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="geplanteGasdruckpruefung"
                                            name="geplanteGasdruckpruefung"
                                            label='Geplante Gasdruckprüfung'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.geplanteGasdruckpruefung}
                                            onChange={handleChange}
                                            error={touched.geplanteGasdruckpruefung && Boolean(errors.geplanteGasdruckpruefung)}
                                            helperText={touched.geplanteGasdruckpruefung && errors.geplanteGasdruckpruefung}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>FI-Schalter</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="fiSchalterNeu"
                                                name="fiSchalterNeu"
                                                value={values.fiSchalterNeu}
                                                onChange={handleChange}
                                                error={touched.fiSchalterNeu && Boolean(errors.fiSchalterNeu)}
                                                helperText={touched.fiSchalterNeu && errors.fiSchalterNeu}
                                            >
                                                <MenuItem className='selectField' value='Dig'>Dig</MenuItem>
                                                <MenuItem className='selectField' value='DigDreh'>DigDreh</MenuItem>
                                                <MenuItem className='selectField' value='AnaDreh'>AnaDreh</MenuItem>
                                                <MenuItem className='selectField' value='Ana'>Ana</MenuItem>
                                                <MenuItem className='selectField' value='Kein FI-Schalter'>Kein FI-Schalter</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.fiSchalterNeu && errors.fiSchalterNeu ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.fiSchalterNeu && errors.fiSchalterNeu}
                                                </FormHelperText>
                                            ) : null}

                                        </FormControl>

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                        >
                                            <InputLabel>SAV</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="sav"
                                                name="sav"
                                                value={values.sav}
                                                onChange={handleChange}
                                                error={touched.sav && Boolean(errors.sav)}
                                                helperText={touched.sav && errors.sav}
                                            >
                                                <MenuItem className='selectField' value='Nein'>Nein</MenuItem>
                                                <MenuItem className='selectField' value='Ja'>Ja</MenuItem>
                                                <MenuItem className='selectField' value='Ja, Kasten'>Ja, Kasten</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.sav && errors.sav ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.sav && errors.sav}
                                                </FormHelperText>
                                            ) : null}

                                        </FormControl>

                                        

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="bioTonneNr"
                                            name="bioTonneNr"
                                            label="Biotonne Nr."
                                            value={values.bioTonneNr}
                                            onChange={handleChange}
                                        />
                                        

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Gelbe Tonne</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="gelbeTonne"
                                                name="gelbeTonne"
                                                value={values.gelbeTonne}
                                                onChange={handleChange}
                                                error={touched.gelbeTonne && Boolean(errors.gelbeTonne)}
                                                helperText={touched.gelbeTonne && errors.gelbeTonne}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.gelbeTonne && errors.gelbeTonne ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.gelbeTonne && errors.gelbeTonne}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Blaue Tonne</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="blaueTonne"
                                                name="blaueTonne"
                                                value={values.blaueTonne}
                                                onChange={handleChange}
                                                error={touched.blaueTonne && Boolean(errors.blaueTonne)}
                                                helperText={touched.blaueTonne && errors.blaueTonne}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.blaueTonne && errors.blaueTonne ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.blaueTonne && errors.blaueTonne}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>



                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="restmuellTonneNr"
                                            name="restmuellTonneNr"
                                            label="Restmüll Tonne Nr."
                                            value={values.restmuellTonneNr}
                                            onChange={handleChange}
                                            error={touched.restmuellTonneNr && Boolean(errors.restmuellTonneNr)}
                                            helperText={touched.restmuellTonneNr && errors.restmuellTonneNr}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Stellplatzparzelle</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="stellplatzparzelle"
                                                name="stellplatzparzelle"
                                                value={values.stellplatzparzelle}
                                                onChange={handleChange}
                                                error={touched.stellplatzparzelle && Boolean(errors.stellplatzparzelle)}
                                                helperText={touched.stellplatzparzelle && errors.stellplatzparzelle}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.stellplatzparzelle && errors.stellplatzparzelle ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.stellplatzparzelle && errors.stellplatzparzelle}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Verwaltungsparzelle</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="verwaltungsparzelle"
                                                name="verwaltungsparzelle"
                                                value={values.verwaltungsparzelle}
                                                onChange={handleChange}
                                                error={touched.verwaltungsparzelle && Boolean(errors.verwaltungsparzelle)}
                                                helperText={touched.verwaltungsparzelle && errors.verwaltungsparzelle}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.verwaltungsparzelle && errors.verwaltungsparzelle ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.verwaltungsparzelle && errors.verwaltungsparzelle}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="schacht"
                                            name="schacht"
                                            label="Schacht"
                                            value={values.schacht}
                                            onChange={handleChange}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Schlüssel im Büro</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="schluesselImBuero"
                                                name="schluesselImBuero"
                                                value={values.schluesselImBuero}
                                                onChange={handleChange}
                                                error={touched.schluesselImBuero && Boolean(errors.schluesselImBuero)}
                                                helperText={touched.schluesselImBuero && errors.schluesselImBuero}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.schluesselImBuero && errors.schluesselImBuero ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.schluesselImBuero && errors.schluesselImBuero}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        

                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                            )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




