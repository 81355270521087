import * as React from 'react';
import { useQuery } from '@apollo/client';

import { GET_GEMEINKOSTEN, DELETE_GEMEINKOSTEN } from './graphql'
import Title from '../Utils/Title'
import LoadingAnimation from '../Utils/LoadingAnimation';

import ContextMenu from './ContextMenu';
import DeleteGemeinkosten from './Delete';
import AlertMessage from '../Alerts/AlertMessage';

import { Link } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';



function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
        maximumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
    }).format(number);
}

function formatBool(val) {
    //console.log('Wert: ' + val.value);
    //if(val == true) {
    if(val.value) {
        return 'Ja';
    } else {
        return 'Nein';
    }
}

const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);





export default function ListGemeinkosten() {
    
    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');
    
    const [loadingState, setLoadingState] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_GEMEINKOSTEN, {
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    const gemeinkostenList = data? data.gemeinkosten.map(item => {
        const id = item.id;
        const gueltigkeit = item.gueltigkeit;
        const reparaturkosten = item.reparaturkosten;
        const versicherung = item.versicherung;
        const grundbesitzabgaben = item.grundbesitzabgaben;
        const strassenbeleuchtung = item.strassenbeleuchtung;
        return { 
            id,
            gueltigkeit,
            reparaturkosten,
            versicherung,
            grundbesitzabgaben,
            strassenbeleuchtung
        };
    }) : '';

    const rows = gemeinkostenList;

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }
    

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'gueltigkeit', headerName: 'Gültigkeit (Jahr)', width: 150, },
        { field: 'reparaturkosten', headerName: 'Reparatur-/Wartungskosten', width: 210, type: 'number', valueFormatter: (params) => formatCurrency(params.value), },
        { field: 'versicherung', headerName: 'Versicherung', width: 150, type: 'number', valueFormatter: (params) => formatCurrency(params.value), },
        { field: 'grundbesitzabgaben', headerName: 'Grundbesitzabgaben', width: 190, type: 'number', valueFormatter: (params) => formatCurrency(params.value), },
        { field: 'strassenbeleuchtung', headerName: 'Straßenbeleuchtung', width: 190, type: 'number', valueFormatter: (params) => formatCurrency(params.value), },
        {
            field: "actions",
            headerName:
                <>
                <Tooltip title="Gemeinkosten anlegen">
                    <Link to={`/nebenkosten/gemeinkosten/create`}>
                        <IconButton>
                            <AddBoxIcon
                                variant='filled'
                                
                                color='action'
                                sx={{
                                    '&:hover': {
                                        color:'#233044'
                                    },
                                    marginLeft: '0.1rem',
                                    fontSize: '1.25rem'
                                }}
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
                </>,
            
            description: "",
            sortable: false,
            filterable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <ContextMenu
                        params={params}
                        assignAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
                        assignAlertSeverity={(alertSeverity) => setAlertSeverity(alertSeverity)}
                        handleOpenAlert={() => {setOpenAlert(true) }}
                    />
                );
            }
        }
    ];
    
    return (
        <>
        
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Nebenkosten - Gemeinkosten</Title>

                    <div className="link-button-container">
                        <div className='link-buttons'>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/nebenkosten/einzelkosten">
                                Preise Einzelkosten
                            </Button>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/nebenkosten">
                                Einzelkosten
                            </Button>
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'gueltigAb', sort: 'desc' }],
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                checkboxSelection
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                loading={loadingState}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );

}