import React from 'react';
//import { React } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ApolloClient, HttpLink, InMemoryCache, from, ApolloProvider } from '@apollo/client';
import { onError } from "@apollo/client/link/error";

import App from './App';
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from './reportWebVitals';







const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(`global log - index.js: [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
    }
    if (networkError) {
        console.log(`global log - index.js: [Network error]: ${networkError}`);
    }
});



const httpLink = new HttpLink({
    uri: 'https://backend.mobilheimpark.itoop.de/graphql/',
    credentials: 'include',
    defaultOptions: {
        query: {
            errorPolicy: 'all',
        },
        mutate: {
            errorPolicy: 'all',
        },
    },
});


const client = new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: true,
    link: from([errorLink, httpLink]),
});

// Supported in React 18+
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ApolloProvider client={client}>
        <BrowserRouter>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </BrowserRouter>
    </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
