import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import {GET_ZAEHLER, DELETE_ZAEHLER} from './graphql'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';




function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}


export default function DeleteZaehler({ zaehler, isOpenDialog, handleCloseDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
    
    const [deleteZaehler, { data, loading, error, refetch }] = useMutation(DELETE_ZAEHLER, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_ZAEHLER }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.deleteZaehler?.ok)) {
                assignAlertMessage('Zähler mit der Nummer ' + zaehler.zaehlernummer + ' gelöscht');
                assignAlertSeverity('success');
            }
        },
    });

    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);

    
    
    function DeleteZaehler(givenId) {
        return deleteZaehler({
            variables: {
                id: givenId
                // provoke error
                //id: 1,
            }
        });
    };
    

    return (
        <>
            {isOpenDialog ? (
                <Dialog
                    open={isOpenDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie den Zähler mit der Nummer {zaehler.zaehlernummer} wirklich löschen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Das Löschen kann <b>nicht rückgängig</b> gemacht werden!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}autoFocus>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseDialog();
                                DeleteZaehler(zaehler.id);
                                handleOpenAlert();
                            }
                        }
                        >Löschen</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}