import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import {GET_NEBENKOSTEN, DELETE_NEBENKOKSTEN} from './graphql'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';




function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}


export default function DeleteNebenkosten({ nebenkosten, isOpenDialog, handleCloseDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
    
    const formattedBetrag = formatCurrency(nebenkosten.preis);

    const [deleteNebenkosten, { data, loading, error, refetch }] = useMutation(DELETE_NEBENKOKSTEN, {
        fetchPolicy: 'network-only',
        //fetchPolicy: 'cache-first',
        //fetchPolicy: 'no-cache',
        refetchQueries: [{ query: GET_NEBENKOSTEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.deleteNebenkosten?.ok)) {
                assignAlertMessage('Nebenkosten mit der ID ' + nebenkosten.id + ' gelöscht');
                assignAlertSeverity('success');
            }
        },
    });

    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);
    
    
    function DeleteNebenkosten(givenId) {
        return deleteNebenkosten({
            variables: {
                id: givenId
                // provoke error
                //id: 1,
            }
        });
    };
    

    return (
        <>
            {isOpenDialog ? (
                <Dialog
                    open={isOpenDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie die Nebenkosten mit der ID {nebenkosten.id} wirklich löschen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Das Löschen kann <b>nicht rückgängig</b> gemacht werden!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}autoFocus>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseDialog();
                                DeleteNebenkosten(nebenkosten.id);
                                handleOpenAlert();
                            }
                        }
                        >Löschen</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}