import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import { GET_PARZELLEN, DELETE_PARZELLE } from './graphql'
import { GET_PAECHTER } from '../Paechter/graphql';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



export default function DeleteParzelle({ parzelle, isOpenDialog, handleCloseDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
  
    const [deleteParzelle, { data, loading, error, refetch }] = useMutation(DELETE_PARZELLE, {
        fetchPolicy: 'network-only',
        //fetchPolicy: 'cache-first',
        //fetchPolicy: 'no-cache',
        refetchQueries: [
            { query: GET_PARZELLEN },
            { query: GET_PAECHTER }
        ],
        errorPolicy: 'all',
        
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.deleteParzelle?.ok)) {
                assignAlertMessage('Parzelle mit Standort ' + parzelle.standort + ' gelöscht');
                assignAlertSeverity('success');
            }
        },
    });


    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);

    
    
    function DeleteParzelle(givenId) {
        return deleteParzelle({
            variables: {
                id: givenId
                // provoke error
                //id: 1,
            }
        });
    };
    

    return (
        <>
            {isOpenDialog ? (
                <Dialog
                    open={isOpenDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie die Parzelle mit dem Standort "{parzelle.standort}" wirklich löschen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Das Löschen kann <b>nicht rückgängig</b> gemacht werden!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}autoFocus>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseDialog();
                                DeleteParzelle(parzelle.id);
                                handleOpenAlert();
                            }
                        }
                        >Löschen</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}