import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

import {CREATE_ENERGIEPREIS, GET_ENERGIEPREISE} from './graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AlertMessage from '../Alerts/AlertMessage';


let loads;


// format currency back from format 00,00 to 00.00, so it can be written back to database
function formatBackCurrency(amount) {
    if (amount) {
        return amount.replace(",", ".");
    }
    return;
}


// yup validation

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    .string()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    .required("Dieses Feld ist ein Pflichtfeld");

const currentDate = new Date();
const currentYear = currentDate.getFullYear();



const validationSchema = yup.object({
    gueltigAb: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        //.min(currentYear, 'Das Jahr darf nicht in der Vergangenheit liegen')
        .test('len', 'Bitte das Jahr vierstellig erfassen', val => val && val.toString().length === 4)
        .required('Bitte das Jahr vierstellig erfassen'),
    preisart: requiredString,
    preis: euroValidator,
});


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




export default function CreateEnergiepreis() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();

    const [createEnergiepreis, { data, loading, error, refetch }] = useMutation(CREATE_ENERGIEPREIS, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_ENERGIEPREISE }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        
        onCompleted: (data) => {
            if (Boolean(data.createEnergiepreis?.id)) {
                var betragMitCent = formatCurrency(data.createEnergiepreis.preis);
                setAlertMessage('Energiepreis über EUR ' + betragMitCent + ' erfolgreich angelegt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/energie/energiepreise`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);

    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        gueltigAb: '',
        preisart: '',
        preis: '',
    };


    const handleSubmit = (values) => {
        
        const checkParzelle = (val) => {
            console.log(val);
            if(val) {
                return val.id;
            }
            return null;
        }

        createEnergiepreis({ 
            variables: {
                gueltigAb: parseInt(values.gueltigAb),
                preisart: values.preisart,
                preis: formatBackCurrency(values.preis),
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Energiepreis anlegen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="gueltigAb"
                                            name="gueltigAb"
                                            label="Gültig ab (Jahr)"
                                            value={values.gueltigAb}
                                            onChange={handleChange}
                                            error={touched.gueltigAb && Boolean(errors.gueltigAb)}
                                            helperText={touched.gueltigAb && errors.gueltigAb}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Preisart</InputLabel>
                                            <Select
                                                MenuProps={{
                                                    sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                    }
                                                }}
                                                id="preisart"
                                                name="preisart"
                                                value={values.preisart}
                                                onChange={handleChange}
                                                error={touched.preisart && Boolean(errors.preisart)}
                                                helperText={touched.preisart && errors.preisart}
                                            >
                                                <MenuItem className='selectField' value='Strom'>Strom</MenuItem>
                                                <MenuItem className='selectField' value='Wasser'>Wasser</MenuItem>
                                                <MenuItem className='selectField' value='Gas'>Gas</MenuItem>
                                                <MenuItem className='selectField' value='Stromzählermiete'>Stromzählermiete</MenuItem>
                                                <MenuItem className='selectField' value='Wasserzählermiete'>Wasserzählermiete</MenuItem>
                                                <MenuItem className='selectField' value='Gaszählermiete'>Gaszählermiete</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.preisart && errors.preisart ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333 !important", marginLeft: "16px !important" }}
                                                >
                                                    {touched.preisart && errors.preisart}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="preis"
                                            name="preis"
                                            label="Preis (EUR)"
                                            value={values.preis}
                                            onChange={handleChange}
                                            error={touched.preis && Boolean(errors.preis)}
                                            helperText={touched.preis && errors.preis}
                                        />

                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




