import { gql } from '@apollo/client';

// JWT Authentication
export const LOGIN = gql`
    mutation TokenAuth($email: String!, $password: String!) {
        tokenAuth(email: $email, password: $password) {
            token
            payload
                
        }
    }
`;


export const LOGOUT = gql`
    mutation DeleteTokenCookie {
        deleteTokenCookie {
            deleted
        }
    }
`;


export const GET_USERINFOS = gql`
    query LoggedInUser {
        loggedInUser {
            firstName
            lastName
            email
        }
    }
`;


// standard model backend authentication
export const MODELBACKEND = gql`
    mutation AuthenticateModelBackend($username: String!, $password: String!) {
        authenticateModelBackend(username: $username, password: $password) {
            user {
                id
                username
                # Add other fields you want to retrieve
            }
        }
    }
`;