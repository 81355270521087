import * as React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';

import {UPDATE_PACHTPREIS, GET_PACHTPREISE} from './graphql';
import {GET_PARZELLEN} from '../Parzellen/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import AlertMessage from '../Alerts/AlertMessage';
import Autocomplete from '@mui/material/Autocomplete';

let loads;



// Get Parzellen
function GetParzellen() {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;

    // Es dürfen nur Stellplatzparzellen ausgegeben werden
    const filteredParzellen = data.parzellen.filter(item => item.anzahlStellplaetze > 0);
        
    //return data.parzellen.map(({ id, standort, anzahlStellplaetze }) => (
    return filteredParzellen.map(({ id, standort, anzahlStellplaetze }) => (
        {id, standort, anzahlStellplaetze}
    ));
    
}


// yup validation

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    .required("Dieses Feld ist ein Pflichtfeld");

const currentDate = new Date();
const currentYear = currentDate.getFullYear();



const validationSchema = yup.object({
    gueltigAb: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        //.min(currentYear, 'Das Jahr darf nicht in der Vergangenheit liegen')
        .required('Bitte das Jahr vierstellig erfassen'),
    preis: euroValidator,
    // Es muss entweder im Feld Mobilheimparzelle Ja gewählt oder eine Stellplatzparzelle ausgewählt werden
    mobilheimparzelle: yup.boolean().required('Bitte auswählen, ob der Preis für die Mobilheimparzellen gelten soll'),
    stellplatzparzelle: yup.object()
        .typeError('Bitte gegebenenfalls eine Stellplatzparzelle auswählen')
        .nullable(true)
        .test('oneOfTwoFields', 'Es muss entweder das Feld Mobilheimparzelle auf Ja gesetzt oder eine Stellplatzparzelle ausgewählt werden', function (value) {
            const { parent } = this;
            if (!parent.mobilheimparzelle && !value) {
                return this.createError({
                    path: 'stellplatzparzelle',
                    message: 'Es muss entweder das Feld Mobilheimparzelle auf Ja gesetzt oder eine Stellplatzparzelle ausgewählt werden',
                });
            }
            // 
            if (parent.mobilheimparzelle && value) {
                return this.createError({
                    path: 'stellplatzparzelle',
                    message: 'Es darf nicht gleichzeitig das Feld Mobilheimparzelle auf Ja gesetzt und eine Stellplatzparzelle ausgewählt werden',
                });
            }
            return true;
        }),
});



// format currency from database format 00.00 to 00,00, so it is displayed correctly in frontend
function formatCurrencyFromDB(amount) {
    return amount.replace(".", ",");
}

// format currency back from frontend format 00,00 to database format 00.00, so it can be written back to database
function formatCurrencyForDB(amount) {
    return amount.replace(",", ".");
}

// format currency for correct display in alert
function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




export default function UpdatePachtpreis() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    //const [parzelleStandort, setParzelleStandort] = React.useState("");
    const parzelleData = GetParzellen();

    const params = useParams();
    const pachtpreisID = params.id;
    const navigate = useNavigate();

    const client = useApolloClient();
    
    // get cached zahlung - on client- to prefill update form - so no query is needed
    // see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-fragments
    const selectedPachtpreis = client.readFragment({
        id: 'PachtpreiseType:' + pachtpreisID, // The value of the apollo client cache ID
        fragment: gql`
          fragment selectedPachtpreis on PachtpreiseType {
            id
            gueltigAb
            preis
            mobilheimparzelle
            stellplatzparzelle{
                id
                standort
            }
          }
        `,
    });

    
    // useState to store values of paechterId
    const [stellplatzparzelleValue, setStellplatzparzelleValue] = React.useState(selectedPachtpreis.stellplatzparzelle);


    const [updatePachtpreis, { data, loading, error, refetch }] = useMutation(UPDATE_PACHTPREIS, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_PACHTPREISE }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.updatePachtpreis?.id)) {
                var betragMitCent = formatCurrency(data.updatePachtpreis.preis);
                setAlertMessage('Pachtpreis über EUR ' + betragMitCent + ' erfolgreich geändert');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/pachtpreise`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    const goBack = () => {
		navigate(-1);
	}

    const initialValues = {
        gueltigAb: selectedPachtpreis.gueltigAb,
        preis: formatCurrencyFromDB(selectedPachtpreis.preis),
        mobilheimparzelle: selectedPachtpreis.mobilheimparzelle,
        stellplatzparzelle: selectedPachtpreis.stellplatzparzelle
    };


    


    const handleSubmit = (values) => {

        const formatBool = (val) => {
            if(val == '') {
                return false;
            }
            return val;
        }

        updatePachtpreis({ 
            variables: {
                id: pachtpreisID,
                gueltigAb: parseInt(values.gueltigAb),
                preis: formatCurrencyForDB(values.preis),
                mobilheimparzelle: formatBool(values.mobilheimparzelle),
                stellplatzparzelle: values.stellplatzparzelle?.id,
            }
        });
        
    };




    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Pachtpreis bearbeiten</Title>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="gueltigAb"
                                            name="gueltigAb"
                                            label="Gültig ab (Jahr)"
                                            value={values.gueltigAb}
                                            onChange={handleChange}
                                            error={touched.gueltigAb && Boolean(errors.gueltigAb)}
                                            helperText={touched.gueltigAb && errors.gueltigAb}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="preis"
                                            name="preis"
                                            label="Preis (EUR)"
                                            value={values.preis}
                                            onChange={handleChange}
                                            error={touched.preis && Boolean(errors.preis)}
                                            helperText={touched.preis && errors.preis}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Mobilheimparzelle</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="mobilheimparzelle"
                                                name="mobilheimparzelle"
                                                value={values.mobilheimparzelle}
                                                onChange={handleChange}
                                                error={touched.mobilheimparzelle && Boolean(errors.mobilheimparzelle)}
                                                helperText={touched.mobilheimparzelle && errors.mobilheimparzelle}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.mobilheimparzelle && errors.mobilheimparzelle ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.mobilheimparzelle && errors.mobilheimparzelle}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>



                                        <Autocomplete
                                            options={parzelleData}
                                            filterSelectedOptions
                                            value={stellplatzparzelleValue}
                                            onChange={(e, stellplatzparzelleValue) => {
                                                // at first set UseState
                                                setStellplatzparzelleValue(stellplatzparzelleValue);
                                                // then use state to set field value
                                                setFieldValue(
                                                    "stellplatzparzelle", stellplatzparzelleValue
                                                    //value !== null ? value : initialValues.parzelleId
                                                );
                                            }}
                                            // important so that initially selected values get considered
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            getOptionLabel={(option) => option.standort}
                                            //getOptionLabel={(option) => (option.name + ", " + option.vorname)}

                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    {option.standort}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params}
                                                    variant='filled'
                                                    label="Stellplatzparzelle"
                                                    name="stellplatzparzelle"
                                                    required
                                                    error={touched.stellplatzparzelle && Boolean(errors.stellplatzparzelle)}
                                                    helperText={touched.stellplatzparzelle && errors.stellplatzparzelle}
                                                />
                                            )}
                                        />

                                        
                                        <Button color="primary" variant="contained" type="submit">
                                            ändern
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};

