//import { useQuery, gql, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';


export const GET_ENERGIEPREISE = gql`
    query GetEnergiepreise {
        energiepreise{
            id
            gueltigAb
            preisart
            preis
        }
    }
`;


export const CREATE_ENERGIEPREIS = gql`
    mutation CreateEnergiepreis(
        $gueltigAb: Int!,
        $preisart: String!,
        $preis: Decimal!,
        ) {
        createEnergiepreis(
            gueltigAb: $gueltigAb,
            preisart: $preisart,
            preis: $preis,
             ) {
                id
                gueltigAb
                preisart
                preis
        }
    }
`;


export const UPDATE_ENERGIEPREIS = gql`
    mutation UpdateEnergiepreis(
        $id: ID!,
        $gueltigAb: Int!,
        $preisart: String!,
        $preis: Decimal!,
        ) {
            updateEnergiepreis(
                id: $id,
                gueltigAb: $gueltigAb,
                preisart: $preisart,
                preis: $preis,
                ) {
                    id
                    gueltigAb
                    preisart
                    preis
        }
    }
`;


export const DELETE_ENERGIEPREIS = gql`
    mutation DeleteEnergiepreis($id: ID!){
        deleteEnergiepreis(id: $id) {
            ok
        }
    }
`;
