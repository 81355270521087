import * as React from 'react';
import { useParams } from "react-router-dom";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';
import { GET_ZAEHLERSTAENDE } from './graphql';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { Link } from "react-router-dom";

import Autocomplete from '@mui/material/Autocomplete';
import Title from '../Utils/Title'
import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


let loads;

function GetZaehlerstaende() {
    const { loading, error, data, refetch } = useQuery(GET_ZAEHLERSTAENDE, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };
    if (error) return <p>Error :(</p>;
    
    const zaehlerstaendeList = data.zaehlerstand.map(item => {
        const id = item.id;
        const ablesedatum = item.ablesedatum;
        const zaehlernummer = item.zaehler?.zaehlernummer;
        const energieart = item.zaehler?.energieart;
        const stand = item.stand;
        const schaetzung = item.schaetzung;
        
        return { 
            id,
            ablesedatum,
            zaehlernummer,
            energieart,
            stand,
            schaetzung
        };
      });
    return zaehlerstaendeList;
}


function formatCurrency(number) {
    if (number) {
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number);
    }
    return '';
}

// fomat chached date to dd.MM.yyyy
function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
    } else {
        // important: set date to empty string - otherwise yup will make field required!!
        date = '';
    }
    return date;
}



export default function ShowZaehlerstand() {

    const params = useParams();
    const zaehlerstandID = params.id;
    const zaehlerData = GetZaehlerstaende();

    const navigate = useNavigate();
    const goBack = () => {
		navigate(-1);
	}

    const handleEditButton = () => {
        navigate(`/energie/zaehlerstaende/${zaehlerstandID}/update`);
    }

    const filtered = Object.values(zaehlerData).filter(zaehlerstand => zaehlerstand.id === zaehlerstandID);


    // set const for 'Schlüssel im Büro'    
    function formatBoolSchaetzung() {
        if (filtered[0].schaetzung) {
            if(filtered[0].schaetzung.toString() == 'true') {
                return 'Ja';
            }
            else {
                return 'Nein';
            }
        }
        return 'Nein';
    }
    const formatSchaetzung = formatBoolSchaetzung();




    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                        >
                            
                            {filtered.map(zaehlerstand => {
                                return (
                                    <>
                                        <Title>Zählerstand des Zählers mit der Nr. {zaehlerstand.zaehlernummer} anzeigen</Title>

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Ablesedatum"
                                            value={formatCachedDate(zaehlerstand.ablesedatum)}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Zählernummer"
                                            value={zaehlerstand.zaehlernummer}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Zählerstand"
                                            value={zaehlerstand.stand}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Energieart"
                                            value={zaehlerstand.energieart}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Schätzung"
                                            value={formatSchaetzung}
                                        />

                                    </>                                    
                                );
                                })
                            }
                            <Button color="primary" variant="contained" onClick={handleEditButton}>
                                bearbeiten
                            </Button>
                            <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                zurück
                            </Button>
                            
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};