import * as React from 'react';
import { useQuery } from '@apollo/client';

import {GET_RECHNUNGSLAEUFE, DELETE_RECHNUNGSLAUF} from './graphql'
import Title from '../Utils/Title'
import LoadingAnimation from '../Utils/LoadingAnimation';

import ContextMenu from './ContextMenu';
import DeleteRechnungslauf from './Delete';
import AlertMessage from '../Alerts/AlertMessage';

import { Link } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';



function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
        maximumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
    }).format(number);
}


function formatBool(val) {
    //console.log('Wert: ' + val.value);
    //if(val == true) {
    if(val.value) {
        return 'Ja';
    } else {
        return 'Nein';
    }
}

function formatDate(date) {
    if(date.value == null) {
        return;
    } else {
        date = date.value.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}

const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);





export default function ListPachtrechnungen() {
    
    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');
    
    const [loadingState, setLoadingState] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_RECHNUNGSLAEUFE, {
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    const rechnungslaeufeList = data? data.rechnungslaeufe.map(item => {
        const id = item.id;
        const rechnungsart = item.rechnungsart;
        const abrechnungsperiode = item.abrechnungsperiode;
        const anzahlVerarbeitet = item.anzahlVerarbeitet;
        const anzahlHinweise = item.anzahlHinweise;
        const anzahlFehler = item.anzahlFehler;
        const stornoVon = item.stornoVon;
        const storniert = item.storniert;
        const abgeschlossen = item.abgeschlossen;
        return { 
            id,
            rechnungsart,
            abrechnungsperiode,
            anzahlVerarbeitet,
            anzahlHinweise,
            anzahlFehler,
            stornoVon,
            storniert,
            abgeschlossen,
        };
    }) : '';

    const rows = rechnungslaeufeList;

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }
    

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 70 },
        { field: 'rechnungsart', headerName: 'Rechnungsart', width: 150, },
        { field: 'abrechnungsperiode', headerName: 'Abrechnungsperiode', width: 150 },
        { field: 'anzahlVerarbeitet', headerName: 'Verarbeitet', width: 130, type: 'number', headerAlign: 'center', align: 'center' },
        { field: 'anzahlHinweise', headerName: 'Hinweise', width: 130, type: 'number', headerAlign: 'center', align: 'center' },
        { field: 'anzahlFehler', headerName: 'Fehler', width: 130, type: 'number', headerAlign: 'center', align: 'center' },
        { field: 'stornoVon', headerName: 'Storno von', width: 110, headerAlign: 'center', align: 'center' },
        { field: 'storniert', headerName: 'storniert', width: 110, type: 'boolean', valueFormatter: (params) => formatBool(params) },
        { field: 'abgeschlossen', headerName: 'abgeschlossen', width: 140, type: 'boolean', valueFormatter: (params) => formatBool(params) },
        {
            field: "actions",
            headerName:
                <>
                <Tooltip title="Rechnungslauf anlegen">
                    <Link to={`/rechnungen/rechnungslauf/create`}>
                        <IconButton>
                            <AddBoxIcon
                                variant='filled'
                                
                                color='action'
                                sx={{
                                    '&:hover': {
                                        color:'#233044'
                                    },
                                    marginLeft: '0.1rem',
                                    fontSize: '1.25rem'
                                }}
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
                </>,
            
            description: "",
            sortable: false,
            filterable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <ContextMenu
                        params={params}
                        assignAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
                        assignAlertSeverity={(alertSeverity) => setAlertSeverity(alertSeverity)}
                        handleOpenAlert={() => {setOpenAlert(true) }}
                    />
                );
            }
        }
    ];
    
    return (
        <>
        
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Rechnungsläufe</Title>

                    <div className="link-button-container">
                        <div className='link-buttons'>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/rechnungen/pachtrechnungen">
                                Pachtrechnungen
                            </Button>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/rechnungen/energierechnungen">
                                Energierechnungen
                            </Button>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/rechnungen/nebenkostenrechnungen">
                                Nebenkostenrechnungen
                            </Button>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/rechnungen/gesamtrechnungen">
                                Gesamtrechnungen
                            </Button>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/rechnungen/sonstige-rechnungen">
                                Sonstige Rechnungen
                            </Button>
                        </div>
                    </div>


                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'id', sort: 'desc' }],
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                checkboxSelection
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                loading={loadingState}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );

}