import * as React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from "react-router-dom";

import { GET_PACHT, DELETE_PACHT } from '../Pacht/graphql';
import { GET_PAECHTER } from '../Paechter/graphql';


import Title from '../Utils/Title'
import LoadingAnimation from '../Utils/LoadingAnimation';

import ContextMenu from '../Pacht/ContextMenu';
import DeletePacht from '../Pacht/Delete';
import AlertMessage from '../Alerts/AlertMessage';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

//import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';



function formatCurrency(number) {
    if (number) {
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
            maximumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
        }).format(number);
    }
    return;
}


function formatDate(date) {
    if(date.value == null) {
        return;
    } else {
        date = date.value.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


let loads;


function formatBool(val) {
    //console.log('Wert: ' + val.value);
    //if(val == true) {
    if(val.value) {
        return 'Ja';
    } else {
        return 'Nein';
    }
}



function GetPaechter() {
    //const [loads, setLoading] = React.useState(false)
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER, {
            onCompleted: () => {
                loads=false;
                return loads;
            }
    });

    if (loading) {
            loads = true;
            return loads;
    };
    if (error) {
            console.log(error);
            alert(error);
            return error;
    }

    console.log(data);
    
    const paechterList = data.paechter.map(item => {
            
            //const pachtbeginn = formatDate(item.pachtPaechter.pachtbeginn);
            const paechter = item.name + ', ' + item.vorname;
            const strasse = item.strasse;
            //const parzelle = item.parzelle.standort;
            //const pachtpreis = item.pachtpreis;
            return { 
                //pachtbeginn,
                paechter,
                strasse,
                //parzelle,
                //pachtpreis,
                //pacht
            };
        });
        const firstFivePaechter = paechterList.slice(0, 5);
        return firstFivePaechter;
}





export default function Orders() {
    
    const paechterData = GetPaechter();

    console.log(paechterData);

    if (Array.isArray(paechterData)) {
        // pachtData is an array, so it can be safely mapped
        return (
            <React.Fragment>
                <Title>Pächter</Title>
                <Table size="small">
                    {/* ... Table header */}
                    <TableBody>
                        {paechterData.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.paechter}</TableCell>
                                <TableCell>{row.strasse}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Link to={`/paechter`} sx={{ mt: 3 }}>
                    Alle Pächter anzeigen
                </Link>
            </React.Fragment>
        );
    } else if (typeof pachtData === 'boolean') {
        // Handle the loading state
        return <p>Loading...</p>;
    } else {
        // Handle the error state
        //return <p>Error: {paechterData.message}</p>;
    }
}