import { gql } from '@apollo/client';


export const GET_RECHNUNGSLAEUFE = gql`
    query GetRechnungslaeufe {
        rechnungslaeufe{
            id
            rechnungsdatum
            rechnungsart
            abrechnungsperiode
            anzahlVerarbeitet
            anzahlHinweise
            anzahlFehler
            stornoVon
            abgeschlossen
            storniert
        }
    }
`;


export const CREATE_RECHNUNGSLAUF = gql`
    mutation CreateRechnungslauf(
        $rechnungsart: String!,
        $stornoVon: String,
        ) {
        createRechnungslauf(
            rechnungsart: $rechnungsart,
            stornoVon: $stornoVon,
             ) {
                id
                rechnungsdatum
                rechnungsart
                abrechnungsperiode
                anzahlVerarbeitet
                anzahlHinweise
                anzahlFehler
                stornoVon
                abgeschlossen
                storniert
            }
    }
`;


export const LOCK_RECHNUNGSLAUF = gql`
    mutation LockRechnungslauf($id: ID!){
        lockRechnungslauf(id: $id) {
            ok
        }
    }
`;


// Für eine Stornierung nutzen wir im backend die mutation createPachtrechnung
export const STORNO_RECHNUNGSLAUF = gql`
    mutation StornoRechnungslauf(
        $stornoVon: String,
        $rechnungsart: String!,
        ){
        stornoRechnungslauf(
            stornoVon: $stornoVon,
            rechnungsart: $rechnungsart,
            ) {
                id
                rechnungsdatum
                rechnungsart
                abrechnungsperiode
                anzahlVerarbeitet
                anzahlHinweise
                anzahlFehler
                stornoVon
                abgeschlossen
                storniert
        }
    }
`;


export const DELETE_RECHNUNGSLAUF = gql`
    mutation DeleteRechnungslauf($id: ID!){
        deleteRechnungslauf(id: $id) {
            ok
        }
    }
`;


