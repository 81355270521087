import * as React from 'react';
import { useQuery } from '@apollo/client';

import { GET_MAHNUNGEN, GET_ARCHIVIERTE_MAHNUNGEN } from './graphql'
import Title from '../Utils/Title'
import LoadingAnimation from '../Utils/LoadingAnimation';

import { Link } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import { ContextMenu, ContextMenuArchiv } from './ContextMenu';

import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, duration, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';

import AlertMessage from '../Alerts/AlertMessage';



function formatDate(date) {
    if(date.value == null) {
        return;
    } else {
        date = date.value.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
        maximumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
    }).format(number);
}



const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);





export function ListMahnungen() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeleteParzelle' of cparzellehild 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');
    
    
    const [loadingState, setLoadingState] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_MAHNUNGEN, {
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    const mahnungenList = data? data.mahnungen.map(item => {
        const id = item.id;
        const status = item.status;
        const rechnungsart = item.rechnungsart;
        const rechnungsnummer = item.rechnungsnummer;
        const rechnungsbetrag = item.rechnungsbetrag;
        const rechnungsdatum = item.rechnungsdatum;
        const paechter = item.pacht.paechter.name + ', ' + item.pacht.paechter.vorname;
        const mahnstufe = item.mahnstufe;
        const bislangGezahlt = item.bislangGezahlt;
        const offenerBetrag = item.offenerBetrag;
        const ausstellungsdatum = item.ausstellungsdatum;
        const kontierungsnummer = item.pacht.kontierungsnummer;
        return { 
            id,
            status,
            rechnungsart,
            rechnungsnummer,
            rechnungsbetrag,
            rechnungsdatum,
            paechter,
            mahnstufe,
            bislangGezahlt,
            offenerBetrag,
            ausstellungsdatum,
            kontierungsnummer,
        };
    }) : '';

    const rows = mahnungenList;

    const navigate = useNavigate();
    
    //console.log('mounting component ListMobilheime...');
    
    const columns = [
        { field: 'id', headerName: 'ID', width: 80, type: 'number', headerAlign: 'center', align: 'center' },
        { field: 'status', headerName: 'Status', width: 110 },
        { field: 'mahnstufe', headerName: 'Mahnstufe', width: 100 },
        { field: 'ausstellungsdatum', headerName: 'Ausgestellt am', width: 120, type: 'date', valueFormatter: (params) => formatDate(params) },
        { field: 'paechter', headerName: 'Pächter', width: 180 },
        { field: 'kontierungsnummer', headerName: 'Kontierungs-Nr.', width: 120 },
        { field: 'rechnungsart', headerName: 'Rechnungsart', width: 150 },
        { field: 'rechnungsnummer', headerName: 'Rechnung-Nr.', width: 100, headerAlign: 'right', align: 'right' },
        { field: 'rechnungsdatum', headerName: 'Rechnungsdatum', width: 130, type: 'date', valueFormatter: (params) => formatDate(params), align: 'center' },
        { field: 'bislangGezahlt', headerName: 'Bislang gezahlt', width: 120, headerAlign: 'right', align: 'right', valueFormatter: (params) => formatCurrency(params.value), },
        { field: 'offenerBetrag', headerName: 'Offener Betrag', type: 'number', width: 120, headerAlign: 'right', align: 'right', valueFormatter: (params) => formatCurrency(params.value), },
        {
            field: "actions",
            headerName:
                <>
                
                </>,
            
            description: "",
            sortable: false,
            filterable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <ContextMenu
                        params={params}
                        assignAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
                        assignAlertSeverity={(alertSeverity) => setAlertSeverity(alertSeverity)}
                        handleOpenAlert={() => {setOpenAlert(true) }}
                    />
                );
            }
        }
    ];
    
    return (
        <>

        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Mahnungen</Title>

                    <div className="link-button-container">
                        <div className='link-buttons'>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/archivierte-mahnungen">
                                Archiv
                            </Button>
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'paechter', sort: 'asc' }],
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                loading={loadingState}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );

}





export function ListArchivierteMahnungen() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeleteParzelle' of cparzellehild 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');
    
    
    const [loadingState, setLoadingState] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_ARCHIVIERTE_MAHNUNGEN, {
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    const mahnungenList = data? data.archivierteMahnungen.map(item => {
        const id = item.id;
        const status = item.status;
        const rechnungsart = item.rechnungsart;
        const rechnungsnummer = item.rechnungsnummer;
        const rechnungsbetrag = item.rechnungsbetrag;
        const rechnungsdatum = item.rechnungsdatum;
        const paechter = item.pacht.paechter.name + ', ' + item.pacht.paechter.vorname;
        const mahnstufe = item.mahnstufe;
        const bislangGezahlt = item.bislangGezahlt;
        const offenerBetrag = item.offenerBetrag;
        const ausstellungsdatum = item.ausstellungsdatum;
        const kontierungsnummer = item.pacht.kontierungsnummer;
        return { 
            id,
            status,
            rechnungsart,
            rechnungsnummer,
            rechnungsbetrag,
            rechnungsdatum,
            paechter,
            mahnstufe,
            bislangGezahlt,
            offenerBetrag,
            ausstellungsdatum,
            kontierungsnummer,
        };
    }) : '';

    const rows = mahnungenList;

    const navigate = useNavigate();
    
    //console.log('mounting component ListMobilheime...');
    
    const columns = [
        { field: 'id', headerName: 'ID', width: 80, type: 'number', headerAlign: 'center', align: 'center' },
        { field: 'status', headerName: 'Status', width: 110 },
        { field: 'mahnstufe', headerName: 'Mahnstufe', width: 100 },
        { field: 'ausstellungsdatum', headerName: 'Ausgestellt am', width: 120, type: 'date', valueFormatter: (params) => formatDate(params) },
        { field: 'paechter', headerName: 'Pächter', width: 180 },
        { field: 'kontierungsnummer', headerName: 'Kontierungs-Nr.', width: 120 },
        { field: 'rechnungsart', headerName: 'Rechnungsart', width: 150 },
        { field: 'rechnungsnummer', headerName: 'Rechnung-Nr.', width: 100, headerAlign: 'right', align: 'right' },
        { field: 'rechnungsdatum', headerName: 'Rechnungsdatum', width: 130, type: 'date', valueFormatter: (params) => formatDate(params), align: 'center' },
        { field: 'bislangGezahlt', headerName: 'Bislang gezahlt', width: 120, headerAlign: 'right', align: 'right', valueFormatter: (params) => formatCurrency(params.value), },
        { field: 'offenerBetrag', headerName: 'Offener Betrag', type: 'number', width: 120, headerAlign: 'right', align: 'right', valueFormatter: (params) => formatCurrency(params.value), },
        {
            field: "actions",
            headerName:
                <>
                
                </>,
            
            description: "",
            sortable: false,
            filterable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <ContextMenuArchiv
                        params={params}
                        assignAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
                        assignAlertSeverity={(alertSeverity) => setAlertSeverity(alertSeverity)}
                        handleOpenAlert={() => {setOpenAlert(true) }}
                    />
                );
            }
        }
    ];
    
    return (
        <>

        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Archivierte Mahnungen</Title>

                    <div className="link-button-container">
                        <div className='link-buttons'>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/mahnungen">
                                Mahnungen
                            </Button>
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'paechter', sort: 'asc' }],
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                loading={loadingState}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );

}