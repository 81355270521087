import React from 'react';
import ReactDOM from 'react-dom';

import { Formik, Form } from "formik";

import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import {CREATE_MOBILHEIM, GET_MOBILHEIME} from './graphql';
import {GET_PAECHTER} from '../Paechter/graphql';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Autocomplete from '@mui/material/Autocomplete';
import { GET_PARZELLEN } from '../Parzellen/graphql';

import AlertMessage from '../Alerts/AlertMessage';



let loads;


// fomat date to yyyy-MM-dd so it can be written back to database
function formatDate(date) {
    // format only, if the date field is not an empty string
    // otherwise we get an error at non-mandatoy date fields, if date fields are not filled
    if(date === '') {
        // we have to return null, because in backend we can only make a date field optional when setting 'null=True' in models.py
        return null;
    } else {
        date = date.split(".");
        // if day has not 2 digits
        if (date[0].length < 2) {
            date[0] = "0" + date[0];
        }
        // if month has not 2 digits
        if (date[1].length < 2) {
            date[1] = "0" + date[1];
        }
        date = date[2] + "-" + date[1] + "-" + date[0];
        return date;
    }
}


// format currency back from frontend format 00,00 to database format 00.00, so it can be written back to database
function formatCurrencyForDB(amount) {
    if (amount) {
        return amount.replace(",", ".");
    }
    return null;
}

// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');

const today = new Date();

const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');

// if date has to be in the future
const validateDateFuture = validateDate.min(tomorrow, 'Das Datum muss in der Zukunft liegen');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');

const validationSchema = yup.object({
    parzelle: yup.object().required("Es muss eine Parzelle ausgewählt werden"),
    //paechter: yup.object().required("Es muss ein Pächter ausgewählt werden"),
    typ: yup.string().required('Dieses Feld ist ein Pflichtfeld'),
    kaufdatum: validateDate,
    //kaufpreis: euroValidator.required('Dieses Feld ist ein Pflichtfeld'),
    kaufpreis: requiredString,
});




// Get Parzellen
function GetParzellen() {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;


    // filter for parzellen without mobilheim because of one-to-one-relationship of mobilheim <> parzelle
    // also filter for parzellen without Anzahl Stellplätze > 0
    console.log(data);
    //const filteredData = data.parzellen.filter(parzelle => parzelle.mobilheim === null && (parzelle.anzahlStellplaetze === null || parzelle.anzahlStellplaetze === 0));
    const filteredData = data.parzellen.filter(parzelle =>(parzelle.stellplatzparzelle === false && parzelle.verwaltungsparzelle === false));
    console.log(filteredData);
        
    //return filteredData.parzellen.map(({ id, standort }) => (
    return filteredData.map(({ id, standort }) => (
        {id, standort}
    ));
    
}


function GetPaechter() {
    //const [loads, setLoading] = React.useState(false)
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER, {
        onCompleted: () => {
            loads=false;
            //console.log('completed. loads: ' + loads);
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        //console.log('loading...loads: ' + loads);
        return loads;
    };
    if (error) return <p>Error :(</p>;

    //console.log(JSON.stringify(data));
        
    return data.paechter.map(({ id, name, vorname }) => (
        {id, name, vorname}
    ));
    
}



export default function CreateMobilheim() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeleteParzelle' of child 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');


    //const [mobilheimStandort, setMobilheimStandort] = React.useState("");

    const navigate = useNavigate();

    const parzelleData = GetParzellen();

    //const paechterData = GetPaechter();
    

    const [createMobilheim, { data, loading, error, refetch }] = useMutation(CREATE_MOBILHEIM, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_MOBILHEIME }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {

            if (Boolean(data.createMobilheim?.id)) {
                setAlertMessage('Mobilheim mit der ID ' + data.createMobilheim.id + ' erfolgreich angelegt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/mobilheime`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    const goBack = () => {
		navigate(-1);
	}


    const initialValues = {
        //parzelle: { standort: "", id: null },
        parzelle: '',
        //paechter: '',
        typ: '',
        anbau: '',
        baujahr: '',
        kaufdatum: '',
        vorbesitzer: '',
        kaufpreis: '',
        
    };


    const handleSubmit = (values) => {
        
        //console.log(values.parzelle.id);
        
        
        const checkCurrency = (val) => {
            if(val == '') {
                return null;
            }
            return val;
        }

        function formatCurrency(amount) {
            if(val == '') {
                return null;
            }
            return amount.replace(",", ".");
        }
        
        createMobilheim({ 
            variables: {
                parzelle: values.parzelle.id,
                //paechter: values.paechter.id,
                typ: values.typ,
                anbau: values.anbau,
                baujahr: values.baujahr,
                // format date back to yyyy-MM-dd
                kaufdatum: formatDate(values.kaufdatum),
                vorbesitzer: values.vorbesitzer,
                //kaufpreis: formatCurrencyForDB(values.kaufpreis),
                kaufpreis: values.kaufpreis,
            }
        });
        
    };



    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Mobilheim anlegen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>
                                        <Autocomplete
                                            id="parzelle"
                                            name="parzelle"
                                            options={parzelleData}
                                            filterSelectedOptions
                                            getOptionLabel={(option) => option.standort}
                                            
                                            onChange={(e, value) => {
                                                //console.log(value);
                                                setFieldValue(
                                                    "parzelle", value
                                                    //value !== null ? value : initialValues.parzelle
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    margin="normal"
                                                    label="Standort (Parzelle)"
                                                    fullWidth
                                                    variant='filled'
                                                    name="parzelle"
                                                    {...params}
                                                    required
                                                    error={touched.parzelle && Boolean(errors.parzelle)}
                                                    helperText={touched.parzelle && errors.parzelle}
                                                />
                                            )}
                                        />
                                        
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="typ"
                                            name="typ"
                                            label="Typ"
                                            value={values.typ}
                                            onChange={handleChange}
                                            error={touched.typ && Boolean(errors.typ)}
                                            helperText={touched.typ && errors.typ}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="anbau"
                                            name="anbau"
                                            label='Anbau'
                                            value={values.anbau}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="baujahr"
                                            name="baujahr"
                                            label="Baujahr"
                                            value={values.baujahr}
                                            onChange={handleChange}
                                            error={touched.baujahr && Boolean(errors.baujahr)}
                                            helperText={touched.baujahr && errors.baujahr}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="kaufdatum"
                                            name="kaufdatum"
                                            label='Kaufdatum'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.kaufdatum}
                                            onChange={handleChange}
                                            error={touched.kaufdatum && Boolean(errors.kaufdatum)}
                                            helperText={touched.kaufdatum && errors.kaufdatum}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="vorbesitzer"
                                            name="vorbesitzer"
                                            label="Vorbesitzer"
                                            value={values.vorbesitzer}
                                            onChange={handleChange}
                                            error={touched.vorbesitzer && Boolean(errors.vorbesitzer)}
                                            helperText={touched.vorbesitzer && errors.vorbesitzer}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="kaufpreis"
                                            name="kaufpreis"
                                            label="Kaufpreis"
                                            value={values.kaufpreis}
                                            onChange={handleChange}
                                            error={touched.kaufpreis && Boolean(errors.kaufpreis)}
                                            helperText={touched.kaufpreis && errors.kaufpreis}
                                        />
                                        
                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                            )}
                            </Formik>

                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};
