//import { useQuery, gql, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';


export const GET_PACHTPREISE = gql`
    query GetPachtpreise {
        pachtpreise{
            id
            gueltigAb
            preis
            mobilheimparzelle
            stellplatzparzelle {
                id
                standort
                stellplatzparzelle
            }
        }
    }
`;


export const CREATE_PACHTPREIS = gql`
    mutation CreatePachtpreis(
        $gueltigAb: Int!,
        $preis: Decimal!,
        $mobilheimparzelle: Boolean!,
        $stellplatzparzelle: ID
        ) {
        createPachtpreis(
            gueltigAb: $gueltigAb,
            preis: $preis,
            mobilheimparzelle: $mobilheimparzelle,
            stellplatzparzelle: $stellplatzparzelle
             ) {
                id
                gueltigAb
                preis
                mobilheimparzelle
                stellplatzparzelle
        }
    }
`;

export const UPDATE_PACHTPREIS = gql`
    mutation UpdatePachtpreis(
        $id: ID!,
        $gueltigAb: Int!,
        $preis: Decimal!,
        $mobilheimparzelle: Boolean!,
        $stellplatzparzelle: ID,
        ) {
            updatePachtpreis(
                id: $id,
                gueltigAb: $gueltigAb,
                preis: $preis,
                mobilheimparzelle: $mobilheimparzelle,
                stellplatzparzelle: $stellplatzparzelle
                ) {
                    id
                    gueltigAb
                    preis
                    mobilheimparzelle
                    stellplatzparzelle
        }
    }
`;


export const DELETE_PACHTPREIS = gql`
    mutation DeletePachtpreis($id: ID!){
        deletePachtpreis(id: $id) {
            ok
        }
    }
`;
