import { gql } from '@apollo/client';


export const GET_GUTSCHRIFTEN = gql`
    query GetGutschriften {
        gutschriften{
            id
            ausstellungsdatum
            rechnungsnummer
            kontierungsnummer
            gutschriftsbetrag
            parzelleStandort
            paechtername
            paechtervorname
            stornoVon
            storniert
            abgeschlossen
            datumUrsprungsrechnung
            nummerUrsprungsrechnung
        }
    }
`;


export const GET_GUTSCHRIFTEN_OF_PACHT = gql`
    query GetGutschriften($pachtID: ID!) {
        gutschriftenOfPacht(pachtId: $pachtID) {
            id
            ausstellungsdatum
            rechnungsnummer
            kontierungsnummer
            gutschriftsbetrag
            parzelleStandort
            paechtername
            paechtervorname
            stornoVon
            storniert
            abgeschlossen
            datumUrsprungsrechnung
            nummerUrsprungsrechnung
        }
    }
`;



export const CREATE_GUTSCHRIFT = gql`
    mutation CreateGutschrift(
        $ausstellungsdatum: Date!,
        $leistungsdatum: Date,
        $nummerUrsprungsrechnung: String,
        $pacht: ID,
        $gutschriftspositionen: String!,
        $stornoVon: String,
        $mwst: Decimal,
        ) {
        createGutschrift(
            ausstellungsdatum: $ausstellungsdatum,
            leistungsdatum: $leistungsdatum,
            nummerUrsprungsrechnung: $nummerUrsprungsrechnung,
            pacht: $pacht,
            gutschriftspositionen: $gutschriftspositionen,
            stornoVon: $stornoVon,
            mwst: $mwst,
             ) {
                id
                rechnungsnummer
                nummerUrsprungsrechnung
                ausstellungsdatum
                leistungsdatum
                gutschriftsbetrag
                kontierungsnummer
                storniert
                stornoVon
                abgeschlossen
            }
    }
`;


export const LOCK_GUTSCHRIFT = gql`
    mutation LockGutschrift($id: ID!){
        lockGutschrift(id: $id) {
            ok
        }
    }
`;



// Für eine Stornierung nutzen wir im backend die mutation createGutschrift
export const STORNO_GUTSCHRIFT = gql`
    mutation CreateGutschrift(
        $ausstellungsdatum: Date!,
        $leistungsdatum: Date,
        $nummerUrsprungsrechnung: String!,
        $gutschriftspositionen: String!,
        $stornoVon: String,
        $mwst: Decimal,
        ) {
        createGutschrift(
            ausstellungsdatum: $ausstellungsdatum,
            leistungsdatum: $leistungsdatum,
            nummerUrsprungsrechnung: $nummerUrsprungsrechnung,
            gutschriftspositionen: $gutschriftspositionen,
            stornoVon: $stornoVon,
            mwst: $mwst,
            ) {
                id
                rechnungsnummer
                nummerUrsprungsrechnung
                ausstellungsdatum
                leistungsdatum
                gutschriftsbetrag
                kontierungsnummer
                storniert
                stornoVon
                abgeschlossen
            }
    }
`;



export const DELETE_GUTSCHRIFT = gql`
    mutation DeleteGutschrift($id: ID!){
        deleteGutschrift(id: $id) {
            ok
        }
    }
`;


export const GET_RECHNUNGSNUMMERN = gql`
    query GetRechnungsnummern {
        rechnungsnummern{
            id
            nummer
        }
    }
`;


