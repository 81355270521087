import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";

import Title from '../Utils/Title'

import { GET_LOGS } from './graphql';

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { format, parseISO } from 'date-fns';

import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';




const formatDateTime = (dateString) => {
    const date = parseISO(dateString);
    return format(date, 'dd.MM.yyyy HH:mm:ss');
};

const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);


export default function ListLogs() {
    
    // url parameter auslesen, um nur die log entries der einen ID der entsprechenden Rechnungsart abzurufen
    const params = useParams();
    const rechnungslaufId = parseInt(params.rechnungslaufId);

    const [loadingState, setLoadingState] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_LOGS, {
        variables:{
            item: rechnungslaufId
        },
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    const logList = data? data.logs.map(item => {
        const id = item.id;
        const createdAt = item.createdAt;
        const level = item.level;
        const modul = item.modul;
        const entry = item.entry;
        return { 
            id,
            createdAt,
            level,
            modul,
            entry,
        };
    }) : '';

    const rows = logList;


    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }
    

    const columns = [
        { field: 'createdAt', headerName: 'Zeitpunkt', width: 170, type: 'date', valueFormatter: (params) => formatDateTime(params.value) },
        { field: 'level', headerName: 'Typ', width: 150, },
        { field: 'entry', headerName: 'Eintrag', width: 950, },
    ];
    
    return (
        <>

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Logs</Title>

                    <div className="link-button-container">
                        <div className='link-buttons'>
                            <Button color="primary" variant="contained" className='link-button' onClick={goBack}>
                                zurück
                            </Button>
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 15, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                checkboxSelection
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                loading={loadingState}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}


                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>

        </>
    );

}