import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

import { CREATE_RECHNUNGSLAUF, GET_RECHNUNGSLAEUFE } from './graphql';
import { GET_PACHTRECHNUNGEN } from '../Pachtrechnungen/graphql';
import { GET_ENERGIERECHNUNGEN } from '../Energierechnungen/graphql';
import { GET_NEBENKOSTENRECHNUNGEN } from '../Nebenkostenrechnungen/graphql';
import { GET_GESAMTRECHNUNGEN } from '../Gesamtrechnungen/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AlertMessage from '../Alerts/AlertMessage';



let loads;


// yup validation
const validationSchema = yup.object({
    rechnungsart: yup.string().required("Es muss eine Rechnungsart ausgewählt werden"),
});
      
      



export default function CreateRechnungslauf() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();



    const [createRechnungslauf, { data, loading, error, refetch }] = useMutation(CREATE_RECHNUNGSLAUF, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_RECHNUNGSLAEUFE },
            { query: GET_PACHTRECHNUNGEN },
            { query: GET_ENERGIERECHNUNGEN },
            { query: GET_NEBENKOSTENRECHNUNGEN },
            { query: GET_GESAMTRECHNUNGEN },
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.createRechnungslauf?.id)) {
                setAlertMessage('Rechnungslauf mit der ID ' + data.createRechnungslauf.id + ' erfolgreich ausgeführt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/rechnungen/rechnungslaeufe`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        rechnungsart: '',
    };


    const handleSubmit = (values) => {

        createRechnungslauf({ 
            variables: {
                rechnungsart: values.rechnungsart,
                stornoVon: null,
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Rechnungslauf ausführen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <FormControl 
                                            variant='filled'
                                            required
                                            fullWidth
                                        >
                                            <InputLabel>Rechnungsart</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="rechnungsart"
                                                name="rechnungsart"
                                                value={values.rechnungsart}
                                                onChange={handleChange}
                                                error={touched.rechnungsart && Boolean(errors.rechnungsart)}
                                                helperText={touched.rechnungsart && errors.rechnungsart}
                                            >
                                                <MenuItem className='selectField' value={'Pacht'}>Pacht</MenuItem>
                                                <MenuItem className='selectField' value={'Energie'}>Energie</MenuItem>
                                                <MenuItem className='selectField' value={'Nebenkosten'}>Nebenkosten</MenuItem>
                                                <MenuItem className='selectField' value={'Gesamt'}>Gesamt</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.rechnungsart && errors.rechnungsart ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.rechnungsart && errors.rechnungsart}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <Button color="primary" variant="contained" type="submit">
                                            ausführen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




