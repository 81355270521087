import * as React from 'react';

import { useMutation } from '@apollo/client';

import { GET_RECHNUNGSLAEUFE, DELETE_RECHNUNGSLAUF } from './graphql';
import { GET_PACHTRECHNUNGEN } from '../Pachtrechnungen/graphql';
import { GET_ENERGIERECHNUNGEN } from '../Energierechnungen/graphql';
import { GET_NEBENKOSTENRECHNUNGEN } from '../Nebenkostenrechnungen/graphql';
import { GET_GESAMTRECHNUNGEN } from '../Gesamtrechnungen/graphql';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AlertMessage from '../Alerts/AlertMessage';


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


export default function DeleteRechnungslauf({ rechnungslauf, isOpenDialog, handleCloseDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
    
    

    const [deleteRechnungslauf, { data, loading, error, refetch }] = useMutation(DELETE_RECHNUNGSLAUF, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_RECHNUNGSLAEUFE },
            { query: GET_PACHTRECHNUNGEN },
            { query: GET_ENERGIERECHNUNGEN },
            { query: GET_NEBENKOSTENRECHNUNGEN },
            { query: GET_GESAMTRECHNUNGEN },
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.deleteRechnungslauf?.ok)) {
                assignAlertMessage('Rechnungslauf mit der ID ' + rechnungslauf.id + ' gelöscht');
                assignAlertSeverity('success');
            }
        },
    });

    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);

    
    
    function DeleteRechnungslauf(givenId) {
        return deleteRechnungslauf({
            variables: {
                id: givenId
                // provoke error
                //id: 1,
            }
        });
    };
    

    return (
        <>
        {isOpenDialog ? (
            <Dialog
                open={isOpenDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Möchten Sie den Rechnungslauf mit der ID {rechnungslauf.id} wirklich löschen?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Das Löschen kann <b>nicht rückgängig</b> gemacht werden!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}autoFocus>Nein</Button>
                    <Button
                    onClick={
                        () => {
                            handleCloseDialog();
                            DeleteRechnungslauf(rechnungslauf.id);
                            handleOpenAlert();
                        }
                    }
                    >Löschen</Button>
                </DialogActions>
            </Dialog>
        ) : ''}   
        </>
    );  

}