//import { useQuery, gql, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';


export const GET_NEBENKOSTEN = gql`
    query GetNebenkosten {
        nebenkosten{
            id
            pacht {
                id
                paechter {
                    name
                    vorname
                }
                parzelle {
                    standort
                }
                pachtbeginn
                kontierungsnummer
            }
            gueltigkeit
            restmuelltonne
            biomuelltonne
        }
    }
`;


export const CREATE_NEBENKOSTEN = gql`
    mutation CreateNebenkosten(
        $pacht: ID!
        $gueltigkeit: Date!,
        $restmuelltonne: String,
        $biomuelltonne: String,
        ) {
        createNebenkosten(
            pacht: $pacht,
            gueltigkeit: $gueltigkeit,
            restmuelltonne: $restmuelltonne,
            biomuelltonne: $biomuelltonne,
             ) {
                id
                pacht
                gueltigkeit
                restmuelltonne
                biomuelltonne
        }
    }
`;


export const UPDATE_NEBENKOSTEN = gql`
    mutation UpdateNebenkosten(
        $id: ID!,
        $pacht: ID!
        $gueltigkeit: Date!,
        $restmuelltonne: String,
        $biomuelltonne: String,
        ) {
            updateNebenkosten(
                id: $id,
                pacht: $pacht,
                gueltigkeit: $gueltigkeit,
                restmuelltonne: $restmuelltonne,
                biomuelltonne: $biomuelltonne,
                ) {
                    id
                    pacht
                    gueltigkeit
                    restmuelltonne
                    biomuelltonne
                }
    }
`;


export const DELETE_NEBENKOKSTEN = gql`
    mutation DeleteNebenkosten($id: ID!){
        deleteNebenkosten(id: $id) {
            ok
        }
    }
`;
