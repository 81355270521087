import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

import { CREATE_ZAEHLER, GET_ZAEHLER } from './graphql';
import { GET_PARZELLEN } from '../Parzellen/graphql';
import { GET_ZAEHLERSTAENDE } from '../Zaehlerstaende/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AlertMessage from '../Alerts/AlertMessage';
import Autocomplete from '@mui/material/Autocomplete';


let loads;


// fomat date to yyyy-MM-dd so it can be written back to database
function formatDate(date) {
    // format only, if the date field is not an empty string
    // otherwise we get an error at non-mandatoy date fields, if date fields are not filled
    if(date === '') {
        // we have to return null, because in backend we can only make a date field optional when setting 'null=True' in models.py
        return null;
    } else {
        date = date.split(".");
        // if day has not 2 digits
        if (date[0].length < 2) {
            date[0] = "0" + date[0];
        }
        // if month has not 2 digits
        if (date[1].length < 2) {
            date[1] = "0" + date[1];
        }
        date = date[2] + "-" + date[1] + "-" + date[0];
        return date;
    }
}

// format currency back from format 00,00 to 00.00, so it can be written back to database
function formatBackCurrency(number) {
    if (number) {
        return number.replace(",", ".");
    }
    return null;
}

function formatCurrency(number) {
    if (number) {
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number);
    }
    return null;
}




function GetZaehler() {
    const { loading, error, data, refetch } = useQuery(GET_ZAEHLER, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };
    if (error) return <p>Error :(</p>;

    const zaehlerList = data.zaehler.map(item => {
        const id = item.id;
        const zaehlernummer = item.zaehlernummer;
        const parzelle = item.parzelle?.standort;
        const energieart = item.energieart;
        const installationAm = item.installationAm;
        const zaehlerstandBeiInstallation = item.zaehlerstandBeiInstallation;
        const letzteEichung = item.letzteEichung;
        const naechsteEichung = item.naechsteEichung;
        return { 
            id,
            zaehlernummer,
            parzelle,
            energieart,
            installationAm,
            zaehlerstandBeiInstallation,
            letzteEichung,
            naechsteEichung
        };
      });
    return zaehlerList;
}



// Get Parzellen
function GetParzellen() {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;

        
    return data.parzellen.map(({ id, standort }) => (
        {id, standort}
    ));
    
}




// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');

const today = new Date();

const currentYear = today.getFullYear();

const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');

// if date has to be in the future
const validateDateFuture = validateDate.min(tomorrow, 'Das Datum muss in der Zukunft liegen');

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    );


const validationSchema = yup.object({
    zaehlernummer: requiredString,
    parzelle: yup.object().required("Es muss eine Parzelle ausgewählt werden"),
    energieart: requiredString,
    installationAm: validateDatePast.required('Dieses Feld ist ein Pflichtfeld'),
    letzteEichung: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        .min(2000, 'Das Jahr liegt zu weit zurück')
        .max(currentYear, 'Das Jahr darf nicht in der Zukunft liegen')
        .required('Bitte das Jahr vierstellig erfassen'),
    /*
    Das Feld naechsteEichung wird automatisch vom backend befüllt
    naechsteEichung: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        .min(currentYear, 'Das Jahr darf nicht in der Vergangenheit liegen')
        .required('Bitte das Jahr vierstellig erfassen')
        .when('letzteEichung', (letzteEichung, schema) => {
            return schema.test({
                name: 'is-after-letzteEichung',
                message: 'Das nächste Eichungsdatum muss nach dem letzten Eichungsdatum liegen',
                test: function (naechsteEichung) {
                    return naechsteEichung && letzteEichung ? naechsteEichung > letzteEichung : true;
                },
            });
        }),
    */
    zaehlermiete: yup.boolean().required('Bitte auswählen, ob die Zählermiete in Rechnung gestellt werden soll'),
    pauschalpreis: euroValidator.nullable(true),
    deinstallationAm: validateDate.when('installationAm', (installationAm, schema) => {
        return schema.test({
            name: 'is-after-installationAm',
            message: 'Das Deinstallationsdatum muss nach dem Installationsdatum liegen',
            test: function (deinstallationAm) {
                return deinstallationAm && installationAm ? deinstallationAm > installationAm : true;
            },
        });
    }),
    zaehlerstandBeiInstallation: requiredInt,
});
      
      






export default function CreateZaehler() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();

    const parzelleData = GetParzellen();


    const [createZaehler, { data, loading, error, refetch }] = useMutation(CREATE_ZAEHLER, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_ZAEHLER },
            { query: GET_ZAEHLERSTAENDE }
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.createZaehler?.id)) {
                setAlertMessage('Zähler mit der Nummer ' + data.createZaehler.zaehlernummer + ' erfolgreich angelegt. Zählerstand wurde automatisch erfasst.');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/energie/zaehler`);
                }, 2000);
            }
        }
    });



    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                let newMessage;
                if(message.includes('Duplicate entry')) {
                    newMessage = 'Anlage nicht möglich: Diese Zählernummer wurde bereits bei einem anderen Zähler vergeben und Zählernummern dürfen nicht mehrfach vorkommen.';
                    setAlertMessage(newMessage);
                } else {
                    setAlertMessage(message);
                };
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        zaehlernummer: '',
        parzelle: '',
        energieart: '',
        installationAm: '',
        zaehlerstandBeiInstallation: '',
        letzteEichung: '',
        zaehlermiete: '',
        pauschalpreis: '',
        zaehlermiete: true,
        deinstallationAm: '',
    };


    const handleSubmit = (values) => {
        
        createZaehler({ 
            variables: {
                zaehlernummer: values.zaehlernummer,
                parzelle: values.parzelle.id,
                energieart: values.energieart,
                installationAm: formatDate(values.installationAm),
                zaehlerstandBeiInstallation: parseInt(values.zaehlerstandBeiInstallation),
                letzteEichung: parseInt(values.letzteEichung),
                zaehlermiete: values.zaehlermiete,
                pauschalpreis: formatBackCurrency(values.pauschalpreis),
                deinstallationAm: null,
                zaehlerstandBeiDeinstallation: null,
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Zähler anlegen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="zaehlernummer"
                                            name="zaehlernummer"
                                            label="Zählernummer"
                                            value={values.zaehlernummer}
                                            onChange={handleChange}
                                            error={touched.zaehlernummer && Boolean(errors.zaehlernummer)}
                                            helperText={touched.zaehlernummer && errors.zaehlernummer}
                                        />

                                        <Autocomplete
                                            id="parzelle"
                                            name="parzelle"
                                            options={parzelleData}
                                            filterSelectedOptions
                                            //multiple
                                            getOptionLabel={(option) => (option.standort)}
                                            onChange={(e, value) => {
                                                setFieldValue(
                                                    "parzelle", value
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    margin="normal"
                                                    //required
                                                    label="Parzelle"
                                                    fullWidth
                                                    variant='filled'
                                                    name="parzelle"
                                                    {...params}
                                                    error={touched.parzelle && Boolean(errors.parzelle)}
                                                    helperText={touched.parzelle && errors.parzelle}
                                                />
                                            )}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                        >
                                            <InputLabel>Energieart</InputLabel>
                                            <Select
                                                MenuProps={{
                                                    sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                    }
                                                }}
                                                id="energieart"
                                                name="energieart"
                                                required
                                                value={values.energieart}
                                                onChange={handleChange}
                                                error={touched.energieart && Boolean(errors.energieart)}
                                                helperText={touched.energieart && errors.energieart}
                                            >
                                                <MenuItem className='selectField' value='Strom'>Strom</MenuItem>
                                                <MenuItem className='selectField' value='Wasser'>Wasser</MenuItem>
                                                <MenuItem className='selectField' value='Gas'>Gas</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.energieart && errors.energieart ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333 !important", marginLeft: "16px !important" }}
                                                >
                                                    {touched.energieart && errors.energieart}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="installationAm"
                                            name="installationAm"
                                            label='Installation am'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.installationAm}
                                            onChange={handleChange}
                                            error={touched.installationAm && Boolean(errors.installationAm)}
                                            helperText={touched.installationAm && errors.installationAm}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="zaehlerstandBeiInstallation"
                                            name="zaehlerstandBeiInstallation"
                                            label='Zählerstand bei Installation'
                                            value={values.zaehlerstandBeiInstallation}
                                            onChange={handleChange}
                                            error={touched.zaehlerstandBeiInstallation && Boolean(errors.zaehlerstandBeiInstallation)}
                                            helperText={touched.zaehlerstandBeiInstallation && errors.zaehlerstandBeiInstallation}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id='letzteEichung'
                                            name='letzteEichung'
                                            label='Letzte Eichung'
                                            placeholder='JJJJ'
                                            value={values.letzteEichung}
                                            onChange={handleChange}
                                            error={touched.letzteEichung && Boolean(errors.letzteEichung)}
                                            helperText={touched.letzteEichung && errors.letzteEichung}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Zählermiete in Rechnung stellen</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="zaehlermiete"
                                                name="zaehlermiete"
                                                value={values.zaehlermiete}
                                                onChange={handleChange}
                                                error={touched.zaehlermiete && Boolean(errors.zaehlermiete)}
                                                helperText={touched.zaehlermiete && errors.zaehlermiete}
                                            >
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.zaehlermiete && errors.zaehlermiete ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.zaehlermiete && errors.zaehlermiete}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="pauschalpreis"
                                            name="pauschalpreis"
                                            label="Pauschalpreis (EUR)"
                                            value={values.pauschalpreis}
                                            onChange={handleChange}
                                            error={touched.pauschalpreis && Boolean(errors.pauschalpreis)}
                                            helperText={touched.pauschalpreis && errors.pauschalpreis}
                                        />

                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




