import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

import {CREATE_NEBENKOSTEN, GET_NEBENKOSTEN} from './graphql';
import { GET_PACHT } from '../Pacht/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';

import AlertMessage from '../Alerts/AlertMessage';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


let loads;


function GetPacht() {
    const { loading, error, data, refetch } = useQuery(GET_PACHT, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };
    if (error) {
        console.log(error);
        alert(error);
        return error;
    }

    // Da Nebenkosten nur für Mobilheimparzellen, also weder für Stellplatzparzellen, noch für Verwaltungsparzellen, anfallen, filtern wir
    console.log('data: ', data);
    const today = new Date();

    const pachtList = data.pacht
        //.filter(pacht => pacht.parzelle.stellplatzparzelle === false && pacht.parzelle.verwaltungsparzelle === false)
        // Aufgrund Pacht ID 439 (Elkenhans) stellplatzparzellen wieder reingenommen
        .filter(pacht => pacht.parzelle.verwaltungsparzelle === false)
        .filter(pacht => !pacht.pachtende || new Date(pacht.pachtende) > new Date())
        .map(item => {
            const id = item.id;
            const parzelle = item.parzelle.standort;
            const paechter = item.paechter.name + ', ' + item.paechter.vorname;
            const pachtbeginn = item.pachtbeginn;
            const pachtpreis = item.pachtpreis;
            const pachtende = item.pachtende;
            return { 
                id,
                parzelle,
                paechter,
                pachtbeginn,
                pachtpreis,
                pachtende,
            };
    });
    
    // sort by paechter
    pachtList.sort((a, b) => {
        let paechterA = a.paechter.toLowerCase();
        let paechterB = b.paechter.toLowerCase();
        
        if (paechterA < paechterB) return -1;
        if (paechterA > paechterB) return 1;
        return 0;
    });

    return pachtList;
}


// format currency back from format 00,00 to 00.00, so it can be written back to database
function formatBackCurrency(amount) {
    if (amount) {
        return amount.replace(",", ".");
    }
    return;
}

function formatDateForDB(date) {
    if(date == null) {
        return;
    } else {
        date = date.split(".");
        date = date[2] + "-" + date[1] + "-" + date[0];
        return date;
    }
}

function formatDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


// yup validation

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

// date validation
const minDate = new Date('1900-01-01');

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');


// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}




let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    .string()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    .required("Dieses Feld ist ein Pflichtfeld");

const currentDate = new Date();
const currentYear = currentDate.getFullYear();



const validationSchema = yup.object({
    pacht: yup.object().required("Es muss ein Pächter ausgewählt werden"),
    gueltigkeit: validateDate.required("Dieses Feld ist ein Pflichtfeld"),
    restmuelltonne: requiredString,
    biomuelltonne: requiredString
});


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




export default function CreateNebenkosten() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const pachtData = GetPacht();

    const navigate = useNavigate();

    const [createNebenkosten, { data, loading, error, refetch }] = useMutation(CREATE_NEBENKOSTEN, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_NEBENKOSTEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.createNebenkosten?.id)) {
                setAlertMessage('Nebenkosten mit der ID ' + data.createNebenkosten.id +
                 ' für das Jahr ' + new Date(data.createNebenkosten.gueltigkeit).getFullYear() + ' erfolgreich angelegt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/nebenkosten`);
                }, 2000);
            }
        }
    });


    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);

    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        pacht: '',
        gueltigkeit: '',
        restmuelltonne: '',
        biomuelltonne: '',
    };


    const handleSubmit = (values) => {
        
        createNebenkosten({ 
            variables: {
                pacht: values.pacht.id,
                gueltigkeit: formatDateForDB(values.gueltigkeit),
                restmuelltonne: values.restmuelltonne,
                biomuelltonne: values.biomuelltonne,
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Nebenkosten anlegen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <Autocomplete
                                            id="pacht"
                                            name="pacht"
                                            options={pachtData}
                                            filterSelectedOptions
                                            getOptionLabel={(option) => ('Pächter: ' + option.paechter + ' | Parzelle: ' + option.parzelle + " | Beginn: " + formatDate(option.pachtbeginn))}
                                            onChange={(e, value) => {
                                                setFieldValue(
                                                    "pacht", value
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    label="Pacht"
                                                    fullWidth
                                                    variant='filled'
                                                    name="pacht"
                                                    {...params}
                                                    error={touched.pacht && Boolean(errors.pacht)}
                                                    helperText={touched.pacht && errors.pacht}
                                                />
                                            )}
                                        />


                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="gueltigkeit"
                                            name="gueltigkeit"
                                            label='Gültig ab'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.gueltigkeit}
                                            onChange={handleChange}
                                            error={touched.gueltigkeit && Boolean(errors.gueltigkeit)}
                                            helperText={touched.gueltigkeit && errors.gueltigkeit}
                                        />
                                        
                                        
                                        <FormControl 
                                            variant='filled'
                                            required
                                            fullWidth
                                        >
                                            <InputLabel>Restmülltonne</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="restmuelltonne"
                                                name="restmuelltonne"
                                                value={values.restmuelltonne}
                                                onChange={handleChange}
                                                error={touched.restmuelltonne && Boolean(errors.restmuelltonne)}
                                                helperText={touched.restmuelltonne && errors.restmuelltonne}
                                            >
                                                <MenuItem className='selectField' value={'Restmülltonne 60 l'}>Restmülltonne 60 l</MenuItem>
                                                <MenuItem className='selectField' value={'Restmülltonne 120 l'}>Restmülltonne 120 l</MenuItem>
                                                <MenuItem className='selectField' value={'Restmülltonne 240 l'}>Restmülltonne 240 l</MenuItem>
                                                <MenuItem className='selectField' value={'Keine'}>Keine</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.restmuelltonne && errors.restmuelltonne ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.restmuelltonne && errors.restmuelltonne}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <FormControl 
                                            variant='filled'
                                            required
                                            fullWidth
                                        >
                                            <InputLabel>Biomülltonne</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="biomuelltonne"
                                                name="biomuelltonne"
                                                value={values.biomuelltonne}
                                                onChange={handleChange}
                                                error={touched.biomuelltonne && Boolean(errors.biomuelltonne)}
                                                helperText={touched.biomuelltonne && errors.biomuelltonne}
                                            >
                                                <MenuItem className='selectField' value={'Biomülltonne 60 l'}>Biomülltonne 60 l</MenuItem>
                                                <MenuItem className='selectField' value={'Keine'}>Keine</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.biomuelltonne && errors.biomuelltonne ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.biomuelltonne && errors.biomuelltonne}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>


                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




