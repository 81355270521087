import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

import {CREATE_ZAHLUNG, GET_ZAHLUNGEN} from './graphql';
import {GET_PAECHTER} from '../Paechter/graphql';
//import { GET_PACHT } from '../Pacht/graphql';
import { GET_AKTUELLE_PACHTEN } from '../Pacht/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AlertMessage from '../Alerts/AlertMessage';
import Autocomplete from '@mui/material/Autocomplete';


let loads;



function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}

function formatDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split(".");
        date = date[2] + "-" + date[1] + "-" + date[0];
        return date;
    }
}




// format currency back from format 00,00 to 00.00, so it can be written back to database
function formatBackCurrency(amount) {
    return amount.replace(",", ".");
}


function GetPacht() {
    const { loading, error, data, refetch } = useQuery(GET_AKTUELLE_PACHTEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };
    if (error) {
        console.log(error);
        alert(error);
        return error;
    }

    //console.log(data);
    
    const pachtList = data?.aktuellePachten
        .filter(item =>{
            const pachtende = item.pachtende? new Date(item.pachtende):null;
            // get first day of current year
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            // Create a new Date object representing January 1st of the current year
            const firstDayOfCurrentYear = new Date(currentYear, 0, 1);
            // only pachten with pachtende greater then or equal first day of current year
            return !pachtende || pachtende >= firstDayOfCurrentYear;
        })
        .map(item => {
            const id = item.id;
            const parzelle = item.parzelle.standort;
            const paechter = item.paechter.name + ', ' + item.paechter.vorname;
            const pachtbeginn = item.pachtbeginn;
            const pachtpreis = item.pachtpreis;
            const pachtende = item.pachtende;
            return { 
                id,
                parzelle,
                paechter,
                pachtbeginn,
                pachtpreis,
                pachtende,
            };
    });

    // sort Rechnungen by paechter    
    pachtList.sort((a, b) => {
        let paechterA = a.paechter.toLowerCase();
        let paechterB = b.paechter.toLowerCase();
        
        if (paechterA < paechterB) return -1;
        if (paechterA > paechterB) return 1;
        return 0;
    });

    return pachtList;
}


// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');

const today = new Date();

const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');

// if date has to be in the future
const validateDateFuture = validateDate.min(tomorrow, 'Das Datum muss in der Zukunft liegen');

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^-?\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    .required("Dieses Feld ist ein Pflichtfeld");


const validationSchema = yup.object({
    betrag: euroValidator,
    buchungsdatum: validateDatePast.required('Dieses Feld ist ein Pflichtfeld'),
    //paechter: yup.object().required("Es muss ein Pächter ausgewählt werden"),
    pacht: yup.object().required("Es muss ein Pächter ausgewählt werden"),
    zahlungsart: requiredString,
});


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




export default function CreateZahlung() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();

    //const paechterData = GetPaechter();
    const pachtData = GetPacht();


    const [createZahlung, { data, loading, error, refetch }] = useMutation(CREATE_ZAHLUNG, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_ZAHLUNGEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.createZahlung?.id)) {
                var betragMitCent = formatCurrency(data.createZahlung.betrag);
                setAlertMessage('Zahlung über EUR ' + betragMitCent + ' erfolgreich angelegt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/zahlungen`);
                }, 2000);
            }
        }
    });


    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        betrag: '',
        buchungsdatum: '',
        //paechter: '',
        pacht: '',
        zahlungsart: '',
        hinweis: '',
        ausgleichszahlung: false,
        pachtzahlung: false,
        sonstigeRechnung: false,
    };


    const handleSubmit = (values) => {
        /*
        const getPaechterId = (val) => {
            console.log(val);
            if(val !== '') {
                // get id's of array of objects, see https://stackoverflow.com/a/64110076/2732698
                //return val.map( (item)=>item.id);
                return [val.map( (item)=>item.id)];
            }
            return [];
        }*/
        
        createZahlung({ 
            variables: {
                betrag: formatBackCurrency(values.betrag),
                buchungsdatum: formatDate(values.buchungsdatum),
                //paechter: getPaechterId(values.paechter),
                //paechter: values.paechter.id,
                pacht: values.pacht.id,
                zahlungsart: values.zahlungsart,
                hinweis: values.hinweis,
                ausgleichszahlung: values.ausgleichszahlung,
                pachtzahlung: values.pachtzahlung,
                sonstigeRechnung: values.sonstigeRechnung,
                }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Zahlung anlegen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    
                                    <Form noValidate>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="betrag"
                                            name="betrag"
                                            label="Betrag"
                                            value={values.betrag}
                                            onChange={handleChange}
                                            error={touched.betrag && Boolean(errors.betrag)}
                                            helperText={touched.betrag && errors.betrag}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="buchungsdatum"
                                            name="buchungsdatum"
                                            label='Buchungsdatum'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.buchungsdatum}
                                            onChange={handleChange}
                                            error={touched.buchungsdatum && Boolean(errors.buchungsdatum)}
                                            helperText={touched.buchungsdatum && errors.buchungsdatum}
                                        />


                                        <Autocomplete
                                            id="pacht"
                                            name="pacht"
                                            options={pachtData}
                                            filterSelectedOptions
                                            getOptionLabel={(option) => ('Pächter: ' + option.paechter + ' | Parzelle: ' + option.parzelle + " | Beginn: " + formatCachedDate(option.pachtbeginn))}
                                            onChange={(e, value) => {
                                                setFieldValue(
                                                    "pacht", value
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    label="Pacht"
                                                    fullWidth
                                                    variant='filled'
                                                    name="pacht"
                                                    {...params}
                                                    error={touched.pacht && Boolean(errors.pacht)}
                                                    helperText={touched.pacht && errors.pacht}
                                                />
                                            )}
                                        />


                                        
                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Zahlungsart</InputLabel>
                                            <Select
                                                MenuProps={{
                                                    sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                    }
                                                }}
                                                id="zahlungsart"
                                                name="zahlungsart"
                                                value={values.zahlungsart}
                                                onChange={handleChange}
                                                error={touched.zahlungsart && Boolean(errors.zahlungsart)}
                                                helperText={touched.zahlungsart && errors.zahlungsart}
                                            >
                                                <MenuItem className='selectField' value='Überweisung'>Überweisung</MenuItem>
                                                <MenuItem className='selectField' value='Barzahlung'>Barzahlung</MenuItem>
                                                <MenuItem className='selectField' value='Kreditkarte'>Kreditkarte</MenuItem>
                                                <MenuItem className='selectField' value='PayPal'>PayPal</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.zahlungsart && errors.zahlungsart ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333 !important", marginLeft: "16px !important" }}
                                                >
                                                    {touched.zahlungsart && errors.zahlungsart}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="hinweis"
                                            name="hinweis"
                                            label='Hinweis'
                                            value={values.hinweis}
                                            onChange={handleChange}
                                            error={touched.hinweis && Boolean(errors.hinweis)}
                                            helperText={touched.hinweis && errors.hinweis}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                        >
                                            <InputLabel>Ausgleichszahlung</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="ausgleichszahlung"
                                                name="ausgleichszahlung"
                                                value={values.ausgleichszahlung}
                                                onChange={handleChange}
                                                error={touched.ausgleichszahlung && Boolean(errors.ausgleichszahlung)}
                                                helperText={touched.ausgleichszahlung && errors.ausgleichszahlung}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.ausgleichszahlung && errors.ausgleichszahlung ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.ausgleichszahlung && errors.ausgleichszahlung}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                        >
                                            <InputLabel>Pachtzahlung</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="pachtzahlung"
                                                name="pachtzahlung"
                                                value={values.pachtzahlung}
                                                onChange={handleChange}
                                                error={touched.pachtzahlung && Boolean(errors.pachtzahlung)}
                                                helperText={touched.pachtzahlung && errors.pachtzahlung}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.pachtzahlung && errors.pachtzahlung ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.pachtzahlung && errors.pachtzahlung}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                        >
                                            <InputLabel>Sonstige Rechnung</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="sonstigeRechnung"
                                                name="sonstigeRechnung"
                                                value={values.sonstigeRechnung}
                                                onChange={handleChange}
                                                error={touched.sonstigeRechnung && Boolean(errors.sonstigeRechnung)}
                                                helperText={touched.sonstigeRechnung && errors.sonstigeRechnung}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.sonstigeRechnung && errors.sonstigeRechnung ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.sonstigeRechnung && errors.sonstigeRechnung}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




