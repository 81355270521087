import * as React from 'react';
import { useQuery } from '@apollo/client';

import {GET_PAECHTER, GET_ARCHIVIERTE_PAECHTER, DELETE_PAECHTER} from './graphql';
import Title from '../Utils/Title';
import LoadingAnimation from '../Utils/LoadingAnimation';

import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import ContextMenu from './ContextMenu';

import { DataGrid } from '@mui/x-data-grid';

import { GridFilterItem } from '@mui/x-data-grid';

import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, duration, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';

import AlertMessage from '../Alerts/AlertMessage';



function formatdDate(date) {
    if(date.value == null) {
        return;
    } else {
        date = date.value.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


function formatBool(val) {
    //console.log('Wert: ' + val.value);
    //if(val == true) {
    if(val.value) {
        return 'Ja';
    } else {
        return 'Nein';
    }
}

const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);



export default function ListPaechter() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeletePaechter' of child 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');


    const [loadingPaechter, setLoadingPaechter] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_PAECHTER, {
        onCompleted: () => {
            setLoadingPaechter(false); // Set loading to false when data fetching is completed
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    const paechterList = data? data.paechter.map(item => {
        const id = item.id;
        const name = item.name;
        const vorname = item.vorname;
        const geburtsdatum = item.geburtsdatum;
        const pachtbeginn = item.pachtbeginn;
        const strasse  = item.strasse;
        const ausgeschiedenAm = item.ausgeschiedenAm;
        return { 
            id,
            name,
            vorname,
            geburtsdatum,
            pachtbeginn,
            strasse,
            ausgeschiedenAm,
        };
    }) : '';
    const rows = paechterList;



    const navigate = useNavigate();
    
    //console.log('mounting component ListPaechter...');
    
    const columns = [
        { field: 'id', headerName: 'ID', width: 100, type: 'number' },
        { field: 'name', headerName: 'Name', width: 250, editable: true },
        { field: 'vorname', headerName: 'Vorname', width: 150 },
        { field: 'geburtsdatum', headerName: 'Geburtsdatum', width: 170, valueFormatter: (params) => formatdDate(params) },
        { field: 'strasse', headerName: 'Strasse', width: 200, },
        {
            field: "actions",
            headerName:
                <>
                <Tooltip title="Paechter anlegen">
                    <Link to={`/paechter/create`}>
                        <IconButton>
                            <AddBoxIcon
                                variant='filled'
                                
                                color='action'
                                sx={{
                                    '&:hover': {
                                        color:'#233044'
                                    },
                                    marginLeft: '0.1rem',
                                    fontSize: '1.25rem'
                                }}
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
                </>,
            
            description: "",
            sortable: false,
            filterable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <ContextMenu
                        params={params}
                        assignAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
                        assignAlertSeverity={(alertSeverity) => setAlertSeverity(alertSeverity)}
                        handleOpenAlert={() => {setOpenAlert(true) }}
                    />
                );
            }
        }
    ];


    
    
    return (
        <>

        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Pächter</Title>

                    <div className="link-button-container">
                        <div className='link-buttons'>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/paechter/archiviert">
                                Archiv
                            </Button>
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'name', sort: 'asc' }],
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                checkboxSelection
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                //loading={loads}
                                loading={loadingPaechter}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                                //filterModel={defaultFilterModel}
                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );

}





export function ListArchiviertePaechter() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const [loadingArchiviertePaechter, setLoadingArchiviertePaechter] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_ARCHIVIERTE_PAECHTER, {
        onCompleted: () => {
            setLoadingArchiviertePaechter(false);
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    const archiviertePaechterList = data? data.archiviertePaechter.map(item => {
        const id = item.id;
        const name = item.name;
        const vorname = item.vorname;
        const geburtsdatum = item.geburtsdatum;
        const pachtbeginn = item.pachtbeginn;
        const strasse  = item.strasse;
        const ausgeschiedenAm = item.ausgeschiedenAm;
        return { 
            id,
            name,
            vorname,
            geburtsdatum,
            pachtbeginn,
            strasse,
            ausgeschiedenAm,
        };
    }) : '';
    const rows = archiviertePaechterList;

    const navigate = useNavigate();
    
    //console.log('mounting component ListPaechter...');
    
    const columns = [
        { field: 'id', headerName: 'ID', width: 100, type: 'number' },
        { field: 'name', headerName: 'Name', width: 250, editable: true },
        { field: 'vorname', headerName: 'Vorname', width: 150 },
        { field: 'geburtsdatum', headerName: 'Geburtsdatum', width: 170, valueFormatter: (params) => formatdDate(params) },
        { field: 'pachtbeginn', headerName: 'Pachtbeginn', width: 170, valueFormatter: (params) => formatdDate(params) },
        { field: 'strasse', headerName: 'Strasse', width: 200, },
        { field: 'ausgeschiedenAm', headerName: 'Ausgeschieden am', width: 170, valueFormatter: (params) => formatdDate(params) },
        {
            field: "actions",
            headerName:
                <>
                
                </>,
            
            description: "",
            sortable: false,
            filterable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <ContextMenu
                        params={params}
                        assignAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
                        assignAlertSeverity={(alertSeverity) => setAlertSeverity(alertSeverity)}
                        handleOpenAlert={() => {setOpenAlert(true) }}
                    />
                );
            }
        }
    ];


    
    
    return (
        <>

        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Archivierte Pächter</Title>

                    <div className="link-button-container">
                        <div className='link-buttons'>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/paechter">
                                Pächter
                            </Button>
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                    paginationModel: { pageSize: 25, page: 0 },
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                checkboxSelection
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                //loading={loads}
                                loading={loadingArchiviertePaechter}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                                //filterModel={defaultFilterModel}
                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );

}