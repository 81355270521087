import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import {GET_PACHTRECHNUNGEN, LOCK_PACHTRECHNUNG} from './graphql'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


export default function LockPachtrechnung({ pachtrechnung, isOpenLockDialog, handleCloseLockDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
    
   

    const formattedBetrag = formatCurrency(pachtrechnung.betrag);

    const [lockPachtrechnung, { data, loading, error, refetch }] = useMutation(LOCK_PACHTRECHNUNG, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_PACHTRECHNUNGEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            
            if (Boolean(data.lockPachtrechnung?.ok)) {
                assignAlertMessage('Pachtrechnung mit der Nummer ' + pachtrechnung.rechnungsnummer + ' vom ' +
                 formatCachedDate(pachtrechnung.rechnungsdatum) + ' abgeschlossen');
                assignAlertSeverity('success');
            }
        },
    });


    
    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);

    
    
    function LockPachtrechnung(givenId) {
        return lockPachtrechnung({
            variables: {
                id: givenId
            }
        });
    };
    

    return (
        <>
            {isOpenLockDialog ? (
                <Dialog
                    open={isOpenLockDialog}
                    onClose={handleCloseLockDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie die Pachtrechnung mit der Nummer {pachtrechnung.rechnungsnummer} abschliessen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sobald Sie die Rechnung herausgegeben haben, sollte diese abgeschlossen werden.<br />
                            Nach Abschluss der Rechnung kann diese nicht mehr gelöscht, aber bei Bedarf storniert werden.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseLockDialog}>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseLockDialog();
                                LockPachtrechnung(pachtrechnung.id);
                                handleOpenAlert();
                            }
                        }
                        autoFocus
                        >Abschliessen</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}