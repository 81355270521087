import * as React from 'react';

import { useMutation } from '@apollo/client';

import {GET_ENERGIEPREISE, DELETE_ENERGIEPREIS} from './graphql'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}


export default function DeleteEnergiepreis({ energiepreis, isOpenDialog, handleCloseDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
    
    const formattedBetrag = formatCurrency(energiepreis.preis);

    const [deleteEnergiepreis, { data, loading, error, refetch }] = useMutation(DELETE_ENERGIEPREIS, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_ENERGIEPREISE }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.deleteEnergiepreis?.ok)) {
                assignAlertMessage('Energiepreis über EUR ' + formattedBetrag + ' gelöscht');
                assignAlertSeverity('success');
            }
        },
    });

    
    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);

    
    
    function DeleteEnergiepreis(givenId) {
        return deleteEnergiepreis({
            variables: {
                id: givenId
                // provoke error
                //id: 1,
            }
        });
    };
    

    return (
        <>
            {isOpenDialog ? (
                <Dialog
                    open={isOpenDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie den Energiepreis mit dem Betrag in Höhe von EUR {formattedBetrag} wirklich löschen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Das Löschen kann <b>nicht rückgängig</b> gemacht werden!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}autoFocus>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseDialog();
                                DeleteEnergiepreis(energiepreis.id);
                                handleOpenAlert();
                            }
                        }
                        >Löschen</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}