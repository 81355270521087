import * as React from 'react';

import { styled, alpha } from '@mui/material/styles';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeletePacht from './Delete';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(0.5),
    minWidth: 160,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ContextMenu({ params, handleOpenAlert, assignAlertMessage, assignAlertSeverity }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  //const [openAlert, setOpenAlert] = React.useState(true);

  const handleClickDelete = () => {
      setOpenDialog(true);
  };

    
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      handleOpenAlert;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    

  return (
        <>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}  
            //onClick={ () => { handleClick(); handleOpenAlert() }}
        >
            <MoreVertIcon />
        </IconButton>
        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <Link to={`/pacht/${params.row.id}/board`}>
              <MenuItem onClick={handleClose} disableRipple>
                  <VisibilityIcon />
                  Anzeigen
              </MenuItem>
            </Link>
            <Link to={`/pacht/${params.row.id}/update`}>
                <MenuItem onClick={handleClose} disableRipple>
                    <EditIcon />
                    Bearbeiten
                </MenuItem>
            </Link>
            <Link to={`/zahlungsmodus/${params.row.id}/update`}>
                <MenuItem onClick={handleClose} disableRipple>
                    <CurrencyExchangeIcon />
                    Zahlungsmodus ändern
                </MenuItem>
            </Link>
            <MenuItem onClick={() => { handleClickDelete(); handleClose() }} disableRipple>
                    <DeleteForeverIcon />
                    Löschen
            </MenuItem> 
                    
        </StyledMenu>
        <DeletePacht
            pacht={{ id: params.row.id, paechter: params.row.paechter, pachtpreis: params.row.pachtpreis }}
            isOpenDialog={openDialog === true}
            handleCloseDialog={() => setOpenDialog(false)}
            assignAlertMessage={assignAlertMessage}
            assignAlertSeverity={assignAlertSeverity}
            handleOpenAlert={handleOpenAlert}
        />
        </>
  );
}
