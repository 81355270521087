import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import {GET_NEBENKOSTENRECHNUNGEN, LOCK_NEBENKOSTENRECHNUNG} from './graphql'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


export default function LockNebenkostenrechnung({ nebenkostenrechnung, isOpenLockDialog, handleCloseLockDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
    
   

    const [lockNebenkostenrechnung, { data, loading, error, refetch }] = useMutation(LOCK_NEBENKOSTENRECHNUNG, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_NEBENKOSTENRECHNUNGEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.lockNebenkostenrechnung?.ok)) {
                assignAlertMessage('Nebenkostenrechnung mit der Nummer ' + nebenkostenrechnung.rechnungsnummer + ' abgeschlossen');
                assignAlertSeverity('success');
            }
        },
    });


    
    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);

    
    
    function LockNebenkostenrechnung(givenId) {
        return lockNebenkostenrechnung({
            variables: {
                id: givenId
            }
        });
    };
    

    return (
        <>
            {isOpenLockDialog ? (
                <Dialog
                    open={isOpenLockDialog}
                    onClose={handleCloseLockDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie die Nebenkostenrechnung mit der Nummer {nebenkostenrechnung.rechnungsnummer} abschliessen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sobald Sie Rechnungen herausgegeben haben, sollten diese abgeschlossen werden.<br />
                            Nach Abschluss einer Rechnung kann diese nicht mehr gelöscht, aber bei Bedarf storniert werden.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseLockDialog}>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseLockDialog();
                                LockNebenkostenrechnung(nebenkostenrechnung.id);
                                handleOpenAlert();
                            }
                        }
                        autoFocus
                        >Abschliessen</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}