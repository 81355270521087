//import { useQuery, gql, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';


export const GET_ZAHLUNGSMODUS = gql`
    query GetZahlungsmodus {
        zahlungsmodus{
            id
            pacht{
                parzelle{
                    standort
                }
            }
            aenderungZum
            newModus
        }
    }
`;




export const CREATE_ZAHLUNGSMODUS = gql`
    mutation CreateZahlungsmodus(
        $pacht: ID!,
        $aenderungZum: Date!,
        ) {
            createZahlungsmodus(
                pacht: $pacht,
                aenderungZum: $aenderungZum,
                ) {
                    id
                    pacht
                    aenderungZum
        }
    }
`;


export const DELETE_ZAHLUNGSMODUS = gql`
    mutation DeleteZahlungsmodus($id: ID!){
        deleteZahlungsmodus(id: $id) {
            ok
        }
    }
`;
