import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

import { CALC_ENERGIEVERBRAEUCHE, GET_ENERGIEVERBRAEUCHE } from './graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AlertMessage from '../Alerts/AlertMessage';



let loads;

const today = new Date();
const currentYear = today.getFullYear();
const earliestYear = currentYear -3;

// yup validation
const validationSchema = yup.object({
    abrechnungsperiode: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        .min(earliestYear, 'Das Jahr liegt zu weit zurück')
        .max(currentYear, 'Das Jahr darf nicht in der Zukunft liegen')
        .required('Bitte das Jahr vierstellig erfassen'),
});
      
      



export default function CalcEnergieverbraeuche() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();



    const [calcEnergieverbraeuche, { data, loading, error, refetch }] = useMutation(CALC_ENERGIEVERBRAEUCHE, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_ENERGIEVERBRAEUCHE },
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.calcEnergieverbraeuche?.gesamtanzahlZaehler)) {
                setAlertMessage('Berechnung der Energieverbräuche erfolgreich durchgeführt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/energie/energieverbraeuche`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        abrechnungsperiode: '',
    };


    const handleSubmit = (values) => {

        calcEnergieverbraeuche({ 
            variables: {
                abrechnungsperiode: parseInt(values.abrechnungsperiode),
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Energieverbräuche neu berechnen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id='abrechnungsperiode'
                                            name='abrechnungsperiode'
                                            label='Abechnungsperiode'
                                            placeholder='JJJJ'
                                            value={values.abrechnungsperiode}
                                            onChange={handleChange}
                                            error={touched.abrechnungsperiode && Boolean(errors.abrechnungsperiode)}
                                            helperText={touched.abrechnungsperiode && errors.abrechnungsperiode}
                                        />

                                        <Button color="primary" variant="contained" type="submit">
                                            berechnen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




