import * as React from 'react';
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useMutation, gql, useQuery, useApolloClient, HttpLink, InMemoryCache, from, ApolloClient , ApolloProvider} from '@apollo/client';
import {UPDATE_PARZELLE, GET_PARZELLEN} from './graphql';
import { GET_PAECHTER } from '../Paechter/graphql';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


import AlertMessage from '../Alerts/AlertMessage';

import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';



// fomat cached date to dd.MM.yyyy so the prefilled date can be filled in in correct format
function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        console.log('date');
        return date;
    }
}

// fomat date back to yyyy-MM-dd so it can be written back to database
function formatDate(date) {
    console.log('jetzt kommt formatDate...');
    if(date) {
        date = date.split(".");
        // if day has not 2 digits
        if (date[0].length < 2) {
            date[0] = "0" + date[0];
        }
        // if month has not 2 digits
        if (date[1].length < 2) {
            date[1] = "0" + date[1];
        }
        date = date[2] + "-" + date[1] + "-" + date[0]; 
    } else {
        date = null;
    }   
    return date;
}


// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');

const today = new Date();

const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');

// if date has to be in the future
const validateDateFuture = validateDate.min(today, 'Das Datum muss in der Zukunft liegen');

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

const validationSchema = yup.object({
    standort: yup.string()
        .min(3, 'Der Standort muss aus mindestens 3 Buchstaben bestehen')
        .required('Dieses Feld ist ein Pflichtfeld'),
    parzellengroesse: yup.number()
        .typeError('Bitte die Quadratmeterzahl erfassen')
        .positive('Bitte eine positive Zahl erfassen')
        .required('Parzellengrösse ist ein Pflichtfeld.'),
    fiSchalterNeu: requiredString,
    gasdruckpruefung: validateDatePast,
    geplanteGasdruckpruefung: validateDateFuture,
    gelbeTonne: yup.boolean().required('Bitte wählen'),
    blaueTonne: yup.boolean().required('Bitte wählen'),
    stellplatzparzelle: yup.boolean().required('Bitte wählen'),
    verwaltungsparzelle: yup.boolean().required('Bitte wählen'),
    schluesselImBuero: yup.boolean().required('Bitte wählen'),
});

let loads;

// Get Parzellen
function GetPaechter() {
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;

    console.log(data);
      
    return data.paechter.map(({ id, name, vorname }) => (
        {id, name, vorname}
    ));
    
}




export default function UpdateParzelle() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeleteParzelle' of child 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const [parzelleStandort, setParzelleStandort] = React.useState("");

    const paechterData = GetPaechter();


    const params = useParams();
    const parzelleID = params.id;
    const navigate = useNavigate();

    const client = useApolloClient();
    
    // get cached parzelle - on client- to prefill update form - so no query is needed
    // see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-fragments
    const selectedParzelle = client.readFragment({
        id: 'ParzelleType:' + parzelleID, // The value of the apollo client cache ID
        fragment: gql`
          fragment selectedParzelle on ParzelleType {
            id
            standort
            parzellengroesse
            fiSchalterNeu
            besonderheiten
            zusatzpflege
            gasdruckpruefung
            geplanteGasdruckpruefung
            sav
            bioTonneNr
            gelbeTonne
            blaueTonne
            restmuellTonneNr
            stellplatzparzelle
            verwaltungsparzelle
            schacht
            schluesselImBuero
          }
        `,
    });


    const [paechterValue, setPaechterValue] = React.useState(selectedParzelle?.paechterSet);



    const [updateParzelle, { data, loading, error, refetch }] = useMutation(UPDATE_PARZELLE, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_PARZELLEN },
            { query: GET_PAECHTER }
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.updateParzelle?.id)) {
                setAlertMessage('Parzelle ' + data.updateParzelle.standort + ' erfolgreich geändert');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/parzellen`);
                }, 2000);
            }
        }
    });


    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage(message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    const goBack = () => {
		navigate(-1);
	}

    const initialValues = {
        standort: selectedParzelle.standort,
        paechter: selectedParzelle.paechterSet,
        parzellengroesse: selectedParzelle.parzellengroesse,
        fiSchalterNeu: selectedParzelle.fiSchalterNeu,
        besonderheiten: selectedParzelle.besonderheiten,
        zusatzpflege: selectedParzelle.zusatzpflege,
        gasdruckpruefung: formatCachedDate(selectedParzelle.gasdruckpruefung),
        geplanteGasdruckpruefung: formatCachedDate(selectedParzelle.geplanteGasdruckpruefung),
        sav: selectedParzelle.sav,
        bioTonneNr: selectedParzelle.bioTonneNr,
        gelbeTonne: selectedParzelle.gelbeTonne,
        blaueTonne: selectedParzelle.blaueTonne,
        restmuellTonneNr: selectedParzelle.restmuellTonneNr,
        stellplatzparzelle: selectedParzelle.stellplatzparzelle,
        verwaltungsparzelle: selectedParzelle.verwaltungsparzelle,
        schacht: selectedParzelle.schacht,
        schluesselImBuero: selectedParzelle.schluesselImBuero,
    };

    console.log(selectedParzelle.paechterSet);


    const handleSubmit = (values) => {
        
        const formatBool = (val) => {
            if(val == '') {
                return false;
            }
            return val;
        }

        const mapPaechter = (val) => {
            console.log(val);
            if(val !== '') {
                // get id's of array of objects, see https://stackoverflow.com/a/64110076/2732698
                return val.map( (item)=>item.id);
            }
            return [];
        }

        updateParzelle({ 
            variables: {
                id: parzelleID,
                standort: values.standort,
                //paechterId: mapPaechter(values.paechter),
                parzellengroesse: values.parzellengroesse,
                // format date back to yyyy-MM-dd
                fiSchalterNeu: values.fiSchalterNeu,
                besonderheiten: values.besonderheiten,
                zusatzpflege: values.zusatzpflege,
                gasdruckpruefung: formatDate(values.gasdruckpruefung),
                geplanteGasdruckpruefung: formatDate(values.geplanteGasdruckpruefung),
                sav: values.sav,
                bioTonneNr: values.bioTonneNr,
                gelbeTonne: formatBool(values.gelbeTonne),
                blaueTonne: values.blaueTonne,
                restmuellTonneNr: values.restmuellTonneNr,
                stellplatzparzelle: formatBool(values.stellplatzparzelle),
                verwaltungsparzelle: formatBool(values.verwaltungsparzelle),
                schacht: values.schacht,
                schluesselImBuero: formatBool(values.schluesselImBuero),
            }
        });
        
    };




    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Parzelle bearbeiten</Title>
                            
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                            {({ handleChange, values, touched, errors, setFieldValue }) => (
                                <Form noValidate>
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        required
                                        id="standort"
                                        name="standort"
                                        label="Standort"
                                        value={values.standort}
                                        onChange={handleChange}
                                        error={touched.standort && Boolean(errors.standort)}
                                        helperText={touched.standort && errors.standort}
                                    />

                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        required
                                        type='number'
                                        id="parzellengroesse"
                                        name="parzellengroesse"
                                        label='Grösse (in Quadratmetern)'
                                        value={values.parzellengroesse}
                                        onChange={handleChange}
                                        error={touched.parzellengroesse && Boolean(errors.parzellengroesse)}
                                        helperText={touched.parzellengroesse && errors.parzellengroesse}
                                    />
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id="besonderheiten"
                                        name="besonderheiten"
                                        label="Besonderheiten"
                                        value={values.besonderheiten}
                                        onChange={handleChange}
                                        error={touched.besonderheiten && Boolean(errors.besonderheiten)}
                                        helperText={touched.besonderheiten && errors.besonderheiten}
                                    />
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant='filled'
                                        rows={3}
                                        id="zusatzpflege"
                                        name="zusatzpflege"
                                        label='Zusatzpflege'
                                        value={values.zusatzpflege}
                                        onChange={handleChange}
                                        error={touched.zusatzpflege && Boolean(errors.zusatzpflege)}
                                        helperText={touched.zusatzpflege && errors.zusatzpflege}
                                    />
                                    
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id="gasdruckpruefung"
                                        name="gasdruckpruefung"
                                        label='Gasdruckprüfung'
                                        placeholder='TT.MM.JJJJ'
                                        value={values.gasdruckpruefung}
                                        onChange={handleChange}
                                        error={touched.gasdruckpruefung && Boolean(errors.gasdruckpruefung)}
                                        helperText={touched.gasdruckpruefung && errors.gasdruckpruefung}
                                    />
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id="geplanteGasdruckpruefung"
                                        name="geplanteGasdruckpruefung"
                                        label='Geplante Gasdruckprüfung'
                                        placeholder='TT.MM.JJJJ'
                                        value={values.geplanteGasdruckpruefung}
                                        onChange={handleChange}
                                        error={touched.geplanteGasdruckpruefung && Boolean(errors.geplanteGasdruckpruefung)}
                                        helperText={touched.geplanteGasdruckpruefung && errors.geplanteGasdruckpruefung}
                                    />

                                    <FormControl 
                                        variant='filled'
                                        fullWidth
                                    >
                                        <InputLabel>FI-Schalter</InputLabel>
                                        <Select
                                            MenuProps={{
                                                sx: {
                                                "&& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                },
                                                "&& .MuiMenuItem-root": {
                                                    backgroundColor: "transparent !important"
                                                },
                                                "&& .Mui-selected": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                }
                                                }
                                            }}
                                            id="fiSchalterNeu"
                                            name="fiSchalterNeu"
                                            value={values.fiSchalterNeu}
                                            onChange={handleChange}
                                            error={touched.fiSchalterNeu && Boolean(errors.fiSchalterNeu)}
                                            helperText={touched.fiSchalterNeu && errors.fiSchalterNeu}
                                        >
                                            <MenuItem className='selectField' value='Dig'>Dig</MenuItem>
                                            <MenuItem className='selectField' value='DigDreh'>DigDreh</MenuItem>
                                            <MenuItem className='selectField' value='AnaDreh'>AnaDreh</MenuItem>
                                            <MenuItem className='selectField' value='Ana'>Ana</MenuItem>
                                        </Select>
                                                                                    
                                        {touched.fiSchalterNeu && errors.fiSchalterNeu ? (
                                            <FormHelperText
                                                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                            >
                                                {touched.fiSchalterNeu && errors.fiSchalterNeu}
                                            </FormHelperText>
                                        ) : null}

                                    </FormControl>
                                            
                                    <FormControl 
                                        variant='filled'
                                        fullWidth
                                    >
                                        <InputLabel>SAV</InputLabel>
                                        <Select
                                            MenuProps={{
                                                sx: {
                                                "&& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                },
                                                "&& .MuiMenuItem-root": {
                                                    backgroundColor: "transparent !important"
                                                },
                                                "&& .Mui-selected": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                }
                                                }
                                            }}
                                            id="sav"
                                            name="sav"
                                            value={values.sav}
                                            onChange={handleChange}
                                            error={touched.sav && Boolean(errors.sav)}
                                            helperText={touched.sav && errors.sav}
                                        >
                                            <MenuItem className='selectField' value='Nein'>Nein</MenuItem>
                                            <MenuItem className='selectField' value='Ja'>Ja</MenuItem>
                                            <MenuItem className='selectField' value='Ja, Kasten'>Ja, Kasten</MenuItem>
                                        </Select>
                                                                                    
                                        {touched.sav && errors.sav ? (
                                            <FormHelperText
                                                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                            >
                                                {touched.sav && errors.sav}
                                            </FormHelperText>
                                        ) : null}

                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id="bioTonneNr"
                                        name="bioTonneNr"
                                        label="Biotonne Nr."
                                        value={values.bioTonneNr}
                                        onChange={handleChange}
                                    />

                                    <FormControl 
                                        variant='filled'
                                        fullWidth
                                        required
                                    >
                                        <InputLabel>Gelbe Tonne</InputLabel>
                                        <Select
                                            MenuProps={{
                                                sx: {
                                                "&& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                },
                                                "&& .MuiMenuItem-root": {
                                                    backgroundColor: "transparent !important"
                                                },
                                                "&& .Mui-selected": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                }
                                                }
                                            }}
                                            id="gelbeTonne"
                                            name="gelbeTonne"
                                            value={values.gelbeTonne}
                                            onChange={handleChange}
                                            error={touched.gelbeTonne && Boolean(errors.gelbeTonne)}
                                            helperText={touched.gelbeTonne && errors.gelbeTonne}
                                        >
                                            <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                            <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                        </Select>
                                                                                    
                                        {touched.gelbeTonne && errors.gelbeTonne ? (
                                            <FormHelperText
                                                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                            >
                                                {touched.gelbeTonne && errors.gelbeTonne}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>

                                    <FormControl 
                                        variant='filled'
                                        fullWidth
                                        required
                                    >
                                        <InputLabel>Blaue Tonne</InputLabel>
                                        <Select
                                            MenuProps={{
                                                sx: {
                                                "&& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                },
                                                "&& .MuiMenuItem-root": {
                                                    backgroundColor: "transparent !important"
                                                },
                                                "&& .Mui-selected": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                }
                                                }
                                            }}
                                            id="blaueTonne"
                                            name="blaueTonne"
                                            value={values.blaueTonne}
                                            onChange={handleChange}
                                            error={touched.blaueTonne && Boolean(errors.blaueTonne)}
                                            helperText={touched.blaueTonne && errors.blaueTonne}
                                        >
                                            <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                            <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                        </Select>
                                                                                    
                                        {touched.blaueTonne && errors.blaueTonne ? (
                                            <FormHelperText
                                                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                            >
                                                {touched.blaueTonne && errors.blaueTonne}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id="restmuellTonneNr"
                                        name="restmuellTonneNr"
                                        label="Restmüll Tonne Nr."
                                        value={values.restmuellTonneNr}
                                        onChange={handleChange}
                                        error={touched.restmuellTonneNr && Boolean(errors.restmuellTonneNr)}
                                        helperText={touched.restmuellTonneNr && errors.restmuellTonneNr}
                                    />
                                    
                                    <FormControl 
                                        variant='filled'
                                        fullWidth
                                        required
                                    >
                                        <InputLabel>Stellplatzparzelle</InputLabel>
                                        <Select
                                            MenuProps={{
                                                sx: {
                                                "&& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                },
                                                "&& .MuiMenuItem-root": {
                                                    backgroundColor: "transparent !important"
                                                },
                                                "&& .Mui-selected": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                }
                                                }
                                            }}
                                            id="stellplatzparzelle"
                                            name="stellplatzparzelle"
                                            value={values.stellplatzparzelle}
                                            onChange={handleChange}
                                            error={touched.stellplatzparzelle && Boolean(errors.stellplatzparzelle)}
                                            helperText={touched.stellplatzparzelle && errors.stellplatzparzelle}
                                        >
                                            <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                            <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                        </Select>
                                                                                    
                                        {touched.stellplatzparzelle && errors.stellplatzparzelle ? (
                                            <FormHelperText
                                                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                            >
                                                {touched.stellplatzparzelle && errors.stellplatzparzelle}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>

                                    <FormControl 
                                        variant='filled'
                                        fullWidth
                                        required
                                    >
                                        <InputLabel>Verwaltungsparzelle</InputLabel>
                                        <Select
                                            MenuProps={{
                                                sx: {
                                                "&& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                },
                                                "&& .MuiMenuItem-root": {
                                                    backgroundColor: "transparent !important"
                                                },
                                                "&& .Mui-selected": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                }
                                                }
                                            }}
                                            id="verwaltungsparzelle"
                                            name="verwaltungsparzelle"
                                            value={values.verwaltungsparzelle}
                                            onChange={handleChange}
                                            error={touched.verwaltungsparzelle && Boolean(errors.verwaltungsparzelle)}
                                            helperText={touched.verwaltungsparzelle && errors.verwaltungsparzelle}
                                        >
                                            <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                            <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                        </Select>
                                                                                    
                                        {touched.verwaltungsparzelle && errors.verwaltungsparzelle ? (
                                            <FormHelperText
                                                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                            >
                                                {touched.verwaltungsparzelle && errors.verwaltungsparzelle}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id="schacht"
                                        name="schacht"
                                        label="Schacht"
                                        value={values.schacht}
                                        onChange={handleChange}
                                        error={touched.schacht && Boolean(errors.schacht)}
                                        helperText={touched.schacht && errors.schacht}
                                    />

                                    <FormControl 
                                        variant='filled'
                                        fullWidth
                                    >
                                        <InputLabel>Schlüssel im Büro</InputLabel>
                                        <Select
                                            MenuProps={{
                                                sx: {
                                                "&& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                },
                                                "&& .MuiMenuItem-root": {
                                                    backgroundColor: "transparent !important"
                                                },
                                                "&& .Mui-selected": {
                                                    backgroundColor: "#f2f2f2 !important"
                                                }
                                                }
                                            }}
                                            id="schluesselImBuero"
                                            name="schluesselImBuero"
                                            value={values.schluesselImBuero}
                                            onChange={handleChange}
                                            error={touched.schluesselImBuero && Boolean(errors.schluesselImBuero)}
                                            helperText={touched.schluesselImBuero && errors.schluesselImBuero}
                                        >
                                            <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                            <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                        </Select>
                                                                                    
                                        {touched.schluesselImBuero && errors.schluesselImBuero ? (
                                            <FormHelperText
                                                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                            >
                                                {touched.schluesselImBuero && errors.schluesselImBuero}
                                            </FormHelperText>
                                        ) : null}

                                    </FormControl>

                                    <Button color="primary" variant="contained" type="submit">
                                        ändern
                                    </Button>
                                    <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                        zurück
                                    </Button>
                                </Form>
                            )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};

