//import { useQuery, gql, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';


export const GET_GEMEINKOSTEN = gql`
    query GetGemeinkosten {
        gemeinkosten{
            id
            gueltigkeit
            reparaturkosten
            versicherung
            grundbesitzabgaben
            strassenbeleuchtung
        }
    }
`;


export const CREATE_GEMEINKOSTEN = gql`
    mutation CreateGemeinkosten(
        $gueltigkeit: Int!,
        $reparaturkosten: Decimal!,
        $versicherung: Decimal!,
        $grundbesitzabgaben: Decimal!,
        $strassenbeleuchtung: Decimal!,
        ) {
        createGemeinkosten(
            gueltigkeit: $gueltigkeit,
            reparaturkosten: $reparaturkosten,
            versicherung: $versicherung,
            grundbesitzabgaben: $grundbesitzabgaben,
            strassenbeleuchtung: $strassenbeleuchtung
             ) {
                id
                gueltigkeit
                reparaturkosten
                versicherung
                grundbesitzabgaben
                strassenbeleuchtung
        }
    }
`;


export const UPDATE_GEMEINKOSTEN = gql`
    mutation UpdateGemeinkosten(
        $id: ID!,
        $gueltigkeit: Int!,
        $reparaturkosten: Decimal!,
        $versicherung: Decimal!,
        $grundbesitzabgaben: Decimal!,
        $strassenbeleuchtung: Decimal!
        ) {
            updateGemeinkosten(
                id: $id,
                gueltigkeit: $gueltigkeit,
                reparaturkosten: $reparaturkosten,
                versicherung: $versicherung,
                grundbesitzabgaben: $grundbesitzabgaben,
                strassenbeleuchtung: $strassenbeleuchtung
                ) {
                    id
                    gueltigkeit
                    reparaturkosten
                    versicherung
                    grundbesitzabgaben
                    strassenbeleuchtung
                }
    }
`;


export const DELETE_GEMEINKOSTEN = gql`
    mutation DeleteGemeinkosten($id: ID!){
        deleteGemeinkosten(id: $id) {
            ok
        }
    }
`;
