import * as React from 'react';
import { useParams } from "react-router-dom";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';
import { GET_ONE_MOBILHEIM } from '../../Mobilheime/graphql';
import { GET_PARZELLEN } from '../../Parzellen/graphql';
import { useNavigate } from "react-router-dom";
import LoadingAnimation from '../../Utils/LoadingAnimation';

import Autocomplete from '@mui/material/Autocomplete';
import Title from '../../Utils/Title'
import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


let loads;


function GetOneMobilheim(pachtID) {
    const { loading, error, data } = useQuery(GET_ONE_MOBILHEIM, {
        variables: { pachtID },
        onCompleted: () => {
            console.log('Query completed');
        }
    });

    if (loading) {
        return { loading: true, data: null, error: null };
    }
    if (error) {
        return { loading: false, data: null, error: error.message };
    }
    return { loading: false, data: data.oneMobilheim, error: null };
}





// fomat chached date to dd.MM.yyyy
function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
    } else {
        // important: set date to empty string - otherwise yup will make field required!!
        date = '';
    }
    return date;
}

function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
        maximumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
    }).format(number);
}



export default function ShowMobilheim() {

    const params = useParams();
    const pachtID = params.id;
    const { loading, data, error } = GetOneMobilheim(pachtID);
    const onemobilheim = data;

    const navigate = useNavigate();

    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
            <LoadingAnimation />
        </Box>
    );

    const goBack = () => {
		navigate(-1);
	}


    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid item xs={12}>

                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                        >
                            
                            {onemobilheim && onemobilheim.map(mobilheim => {
                                return (
                                    <>
                                        <Title>Mobilheim {mobilheim.parzelle?.standort}</Title>

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Standort"
                                            value={mobilheim.parzelle.standort}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Typ"
                                            value={mobilheim.typ}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Anbau"
                                            value={mobilheim.anbau}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Baujahr"
                                            value={mobilheim.baujahr}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Kaufdatum"
                                            value={formatCachedDate(mobilheim.kaufdatum)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Vorbesitzer"
                                            value={mobilheim.vorbesitzer}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Kaufpreis (EUR)"
                                            value={mobilheim.kaufpreis}
                                        />
                                        
                                    </>                                    
                                );
                                })
                            }
                            
                        </Box>
                        
                </Grid>
            </Grid>
        </Container>
    );
};