import { gql } from '@apollo/client';


export const GET_ZAEHLERSTAENDE = gql`
    query GetZaehlerstand {
        zaehlerstand{
            id
            ablesedatum
            zaehler {
                id
                zaehlernummer
                energieart
                parzelle {
                    standort
                }
            }
            stand
            schaetzung
        }
    }
`;


export const CREATE_ZAEHLERSTAND = gql`
    mutation CreateZaehlerstand(
        $ablesedatum: Date!,
        $zaehler: ID!,
        $stand: Int!,
        $schaetzung: Boolean,
        ) {
        createZaehlerstand(
            ablesedatum: $ablesedatum,
            zaehler: $zaehler,
            stand: $stand,
            schaetzung: $schaetzung,
            ) {
            id
            ablesedatum
            zaehler
            stand
            schaetzung
        }
    }
`;

export const UPDATE_ZAEHLERSTAND = gql`
    mutation UpdateZaehlerstand(
        $id: ID!,
        $ablesedatum: Date!,
        $zaehler: ID!,
        $stand: Int!,
        $schaetzung: Boolean,
        ) {
        updateZaehlerstand(
            id: $id,
            ablesedatum: $ablesedatum,
            zaehler: $zaehler,
            stand: $stand,
            schaetzung: $schaetzung,
            ) {
            id
            ablesedatum
            zaehler
            stand
            schaetzung
        }
    }
`;


export const DELETE_ZAEHLERSTAND = gql`
    mutation DeleteZaehlerstand($id: ID!){
        deleteZaehlerstand(id: $id) {
            ok
        }
    }
`;
