import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

import {CREATE_PACHTPREIS, GET_PACHTPREISE} from './graphql';
import {GET_PARZELLEN} from '../Parzellen/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AlertMessage from '../Alerts/AlertMessage';
import Autocomplete from '@mui/material/Autocomplete';


let loads;


// format currency back from format 00,00 to 00.00, so it can be written back to database
function formatBackCurrency(amount) {
    if (amount) {
        return amount.replace(",", ".");
    }
    return;
}


// Get Parzellen
function GetParzellen() {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;

    // Es dürfen nur Stellplatzparzellen ausgegeben werden
    const filteredParzellen = data.parzellen.filter(item => item.stellplatzparzelle === true);
        
    //return data.parzellen.map(({ id, standort, stellplatzparzelle }) => (
    return filteredParzellen.map(({ id, standort, stellplatzparzelle }) => (
        {id, standort, stellplatzparzelle}
    ));
    
}


// yup validation

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    .required("Dieses Feld ist ein Pflichtfeld");

const currentDate = new Date();
const currentYear = currentDate.getFullYear();



const validationSchema = yup.object({
    gueltigAb: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        //.min(currentYear, 'Das Jahr darf nicht in der Vergangenheit liegen')
        .required('Bitte das Jahr vierstellig erfassen'),
    preis: euroValidator,
    // Es muss entweder im Feld Mobilheimparzelle Ja gewählt oder eine Stellplatzparzelle ausgewählt werden
    mobilheimparzelle: yup.boolean().required('Bitte auswählen, ob der Preis für die Mobilheimparzellen gelten soll'),
    stellplatzparzelle: yup.object()
        .typeError('Bitte gegebenenfalls eine Stellplatzparzelle auswählen')
        .nullable(true)
        .test('oneOfTwoFields', 'Es muss entweder das Feld Mobilheimparzelle auf Ja gesetzt oder eine Stellplatzparzelle ausgewählt werden', function (value) {
            const { parent } = this;
            if (!parent.mobilheimparzelle && !value) {
                return this.createError({
                    path: 'stellplatzparzelle',
                    message: 'Es muss entweder das Feld Mobilheimparzelle auf Ja gesetzt oder eine Stellplatzparzelle ausgewählt werden',
                });
            }
            // 
            if (parent.mobilheimparzelle && value) {
                return this.createError({
                    path: 'stellplatzparzelle',
                    message: 'Es darf nicht gleichzeitig das Feld Mobilheimparzelle auf Ja gesetzt und eine Stellplatzparzelle ausgewählt werden',
                });
            }
            return true;
        }),
});


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




export default function CreatePachtpreis() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeleteParzelle' of child 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();

    const parzelleData = GetParzellen();


    const [createPachtpreis, { data, loading, error, refetch }] = useMutation(CREATE_PACHTPREIS, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_PACHTPREISE }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.createPachtpreis?.id)) {
                var betragMitCent = formatCurrency(data.createPachtpreis.preis);
                setAlertMessage('Pachtpreis über EUR ' + betragMitCent + ' erfolgreich angelegt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/pachtpreise`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        gueltigAb: '',
        preis: '',
        mobilheimparzelle: '',
        stellplatzparzelle: '',
    };


    const handleSubmit = (values) => {
        
        const checkParzelle = (val) => {
            console.log(val);
            if(val) {
                return val.id;
            }
            return null;
        }

        createPachtpreis({ 
            variables: {
                gueltigAb: parseInt(values.gueltigAb),
                preis: formatBackCurrency(values.preis),
                mobilheimparzelle: values.mobilheimparzelle,
                stellplatzparzelle: checkParzelle(values.stellplatzparzelle),
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Pachtpreis anlegen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="gueltigAb"
                                            name="gueltigAb"
                                            label="Gültig ab (Jahr)"
                                            value={values.gueltigAb}
                                            onChange={handleChange}
                                            error={touched.gueltigAb && Boolean(errors.gueltigAb)}
                                            helperText={touched.gueltigAb && errors.gueltigAb}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="preis"
                                            name="preis"
                                            label="Preis (EUR)"
                                            value={values.preis}
                                            onChange={handleChange}
                                            error={touched.preis && Boolean(errors.preis)}
                                            helperText={touched.preis && errors.preis}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                            required
                                        >
                                            <InputLabel>Mobilheimparzelle</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="mobilheimparzelle"
                                                name="mobilheimparzelle"
                                                value={values.mobilheimparzelle}
                                                onChange={handleChange}
                                                error={touched.mobilheimparzelle && Boolean(errors.mobilheimparzelle)}
                                                helperText={touched.mobilheimparzelle && errors.mobilheimparzelle}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.mobilheimparzelle && errors.mobilheimparzelle ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.mobilheimparzelle && errors.mobilheimparzelle}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>
                                        
                                        <Autocomplete
                                            id="stellplatzparzelle"
                                            name="stellplatzparzelle"
                                            options={parzelleData}
                                            getOptionLabel={(option) => option.standort}
                                            onChange={(e, value) => {
                                                console.log(value);
                                                setFieldValue(
                                                    "stellplatzparzelle", value
                                                    //value !== null ? value : initialValues.parzelleId
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    margin="normal"
                                                    label="Stellplatzparzelle"
                                                    fullWidth
                                                    variant='filled'
                                                    name="stellplatzparzelle"
                                                    {...params}
                                                    error={touched.stellplatzparzelle && Boolean(errors.stellplatzparzelle)}
                                                    helperText={touched.stellplatzparzelle && errors.stellplatzparzelle}
                                                />
                                            )}
                                        />

                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




