import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

import { CREATE_NEBENKOSTENRECHNUNG, GET_NEBENKOSTENRECHNUNGEN } from './graphql';
import { GET_PACHT } from '../Pacht/graphql';
import { GET_PARZELLEN } from '../Parzellen/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AlertMessage from '../Alerts/AlertMessage';
import Autocomplete from '@mui/material/Autocomplete';


let loads;


function formatDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}

// format currency back from format 00,00 to 00.00, so it can be written back to database
function formatBackCurrency(number) {
    if (number) {
        return number.replace(",", ".");
    }
    return null;
}

function formatCurrency(number) {
    if (number) {
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number);
    }
    return null;
}




function GetPacht() {
    const { loading, error, data, refetch } = useQuery(GET_PACHT, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };
    if (error) {
        console.log(error);
        alert(error);
        return error;
    }

    //console.log(data);
    
    const pachtList = data.pacht.map(item => {
        const id = item.id;
        const parzelle = item.parzelle.standort;
        const paechter = item.paechter.name + ', ' + item.paechter.vorname;
        const pachtbeginn = item.pachtbeginn;
        const pachtpreis = item.pachtpreis;
        const pachtende = item.pachtende;
        return { 
            id,
            parzelle,
            paechter,
            pachtbeginn,
            pachtpreis,
            pachtende,
        };
    });

    return pachtList;
}





function GetParzellen() {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;

        
    return data.parzellen.map(({ id, standort }) => (
        {id, standort}
    ));
    
}




// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');

const today = new Date();

const currentYear = today.getFullYear();

const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');

// if date has to be in the future
const validateDateFuture = validateDate.min(tomorrow, 'Das Datum muss in der Zukunft liegen');

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    );


const validationSchema = yup.object({
    pacht: yup.object().required("Es muss ein Pachtvertrag ausgewählt werden"),
    abrechnungsperiode: yup.string().required('Soll die Energierechnung für das vorangegangene Jahr erstellt werden?'),
});
      
      



export default function CreateEnergierechnung() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();

    const pachtData = GetPacht();
    const parzelleData = GetParzellen();


    

    const [createNebenkostenrechnung, { data, loading, error, refetch }] = useMutation(CREATE_NEBENKOSTENRECHNUNG, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_NEBENKOSTENRECHNUNGEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data?.createNebenkostenrechnung?.id)) {
                setAlertMessage('Nebenkostenrechnung mit der Nummer ' + data.createNebenkostenrechnung.rechnungsnummer + ' erfolgreich angelegt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/rechnungen/nebenkostenrechnungen`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage(message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                console.error('Unhandled error:', error);
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);

    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        pacht: '',
        abrechnungsperiode: '',
        rechnungslaufId: '',
    };


    const handleSubmit = (values) => {
        //const rechnungsnummer = createRechnungsnummer();

        createNebenkostenrechnung({ 
            variables: {
                pacht: values.pacht.id,
                abrechnungsperiode: values.abrechnungsperiode,
                rechnungslaufId: null,
                stornoVon: null,
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Nebenkostenrechnung anlegen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <Autocomplete
                                            id="pacht"
                                            name="pacht"
                                            options={pachtData}
                                            filterSelectedOptions
                                            getOptionLabel={(option) => ('Pächter: ' + option.paechter + ' | Parzelle: ' + option.parzelle + " | Beginn: " + formatDate(option.pachtbeginn))}
                                            onChange={(e, value) => {
                                                setFieldValue(
                                                    "pacht", value
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    label="Pacht"
                                                    fullWidth
                                                    variant='filled'
                                                    name="pacht"
                                                    {...params}
                                                    error={touched.pacht && Boolean(errors.pacht)}
                                                    helperText={touched.pacht && errors.pacht}
                                                />
                                            )}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            required
                                            fullWidth
                                        >
                                            <InputLabel>Abrechnungsperiode</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="abrechnungsperiode"
                                                name="abrechnungsperiode"
                                                value={values.abrechnungsperiode}
                                                onChange={handleChange}
                                                error={touched.abrechnungsperiode && Boolean(errors.abrechnungsperiode)}
                                                helperText={touched.abrechnungsperiode && errors.abrechnungsperiode}
                                            >
                                                <MenuItem className='selectField' value={'Vorjahr'}>Vorjahr</MenuItem>
                                                <MenuItem className='selectField' value={'Aktuelles Jahr'}>Aktuelles Jahr</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.abrechnungsperiode && errors.abrechnungsperiode ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.abrechnungsperiode && errors.abrechnungsperiode}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




