import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import { GET_RECHNUNGSLAEUFE, LOCK_RECHNUNGSLAUF } from './graphql';
import { GET_PACHTRECHNUNGEN } from '../Pachtrechnungen/graphql';
import { GET_ENERGIERECHNUNGEN } from '../Energierechnungen/graphql';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';





export default function LockRechnungslauf({ rechnungslauf, isOpenLockDialog, handleCloseLockDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
    
    const [lockRechnungslauf, { data, loading, error, refetch }] = useMutation(LOCK_RECHNUNGSLAUF, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_RECHNUNGSLAEUFE },
            { query: GET_PACHTRECHNUNGEN },
            { query: GET_ENERGIERECHNUNGEN}
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.lockRechnungslauf?.ok)) {
                assignAlertMessage('Rechnungslauf mit der ID ' + rechnungslauf.id + ' abgeschlossen');
                assignAlertSeverity('success');
            }
        },
    });

    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);
    
    
    function LockRechnungslauf(givenId) {
        return lockRechnungslauf({
            variables: {
                id: givenId
            }
        });
    };
    

    return (
        <>
            {isOpenLockDialog ? (
                <Dialog
                    open={isOpenLockDialog}
                    onClose={handleCloseLockDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie den Rechnungslauf mit der ID {rechnungslauf.id} abschliessen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sobald Sie alle Rechnungen dieses Laufs herausgegeben haben, sollte der Lauf und damit alle enthaltenen Rechnunen abgeschlossen werden.<br />
                            Nach Abschluss des Laufs kann dieser nicht mehr gelöscht, aber bei Bedarf storniert werden.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseLockDialog}>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseLockDialog();
                                LockRechnungslauf(rechnungslauf.id);
                                handleOpenAlert();
                            }
                        }
                        autoFocus
                        >Abschliessen</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}