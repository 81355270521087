import { gql } from '@apollo/client';


export const GET_ZAEHLER = gql`
    query GetZaehler {
        zaehler{
            id
            zaehlernummer
            parzelle {
                id
                standort
            }
            energieart
            installationAm
            zaehlerstandBeiInstallation
            letzteEichung
            naechsteEichung
            zaehlermiete
            pauschalpreis
            deinstallationAm
            zaehlerstandBeiDeinstallation
            zaehlerwechsel
        }
    }
`;


export const CREATE_ZAEHLER = gql`
    mutation CreateZaehler(
        $zaehlernummer: String!,
        $parzelle: ID!,
        $energieart: String!,
        $installationAm: Date,
        $zaehlerstandBeiInstallation: Int,
        $letzteEichung: Int!,
        $zaehlermiete: Boolean,
        $pauschalpreis: Decimal,
        $deinstallationAm: Date,
        $zaehlerstandBeiDeinstallation: Int,
        ) {
        createZaehler(
            zaehlernummer: $zaehlernummer,
            parzelle: $parzelle,
            energieart: $energieart,
            installationAm: $installationAm,
            zaehlerstandBeiInstallation: $zaehlerstandBeiInstallation,
            letzteEichung: $letzteEichung,
            zaehlermiete: $zaehlermiete,
            pauschalpreis: $pauschalpreis,
            deinstallationAm: $deinstallationAm,
            zaehlerstandBeiDeinstallation: $zaehlerstandBeiDeinstallation,
            ) {
            id
            zaehlernummer
            parzelle
            energieart
            installationAm
            zaehlerstandBeiInstallation
            letzteEichung
            zaehlermiete
            pauschalpreis
            deinstallationAm
            zaehlerstandBeiDeinstallation
        }
    }
`;

export const UPDATE_ZAEHLER = gql`
    mutation UpdateZaehler(
        $id: ID!,
        $zaehlernummer: String!,
        $parzelle: ID!,
        $energieart: String!,
        $installationAm: Date,
        $zaehlerstandBeiInstallation: Int,
        $letzteEichung: Int,
        $zaehlermiete: Boolean,
        $pauschalpreis: Decimal,
        $deinstallationAm: Date,
        $zaehlerstandBeiDeinstallation: Int,
        $zaehlerwechsel: Boolean,
        ) {
        updateZaehler(
            id: $id,
            zaehlernummer: $zaehlernummer,
            parzelle: $parzelle,
            energieart: $energieart,
            installationAm: $installationAm,
            zaehlerstandBeiInstallation: $zaehlerstandBeiInstallation,
            letzteEichung: $letzteEichung,
            zaehlermiete: $zaehlermiete,
            pauschalpreis: $pauschalpreis,
            deinstallationAm: $deinstallationAm,
            zaehlerstandBeiDeinstallation: $zaehlerstandBeiDeinstallation,
            zaehlerwechsel: $zaehlerwechsel,
            ) {
            id
            zaehlernummer
            parzelle
            energieart
            installationAm
            zaehlerstandBeiInstallation
            letzteEichung
            zaehlermiete
            pauschalpreis
            deinstallationAm
            zaehlerstandBeiDeinstallation
            zaehlerwechsel
        }
    }
`;


export const DELETE_ZAEHLER = gql`
    mutation DeleteZaehler($id: ID!){
        deleteZaehler(id: $id) {
            ok
        }
    }
`;
