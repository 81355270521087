import { gql } from '@apollo/client';


export const GET_NEBENKOSTENRECHNUNGEN = gql`
    query GetNebenkostenrechnungen {
        nebenkostenrechnungen{
            id
            rechnungsdatum
            rechnungsnummer
            parzelleStandort
            paechtername
            paechtervorname
            debNr
            zeitraumVon
            zeitraumBis
            rechnungsbetrag
            rechnungslaufId
            storniert
            stornoVon
            abgeschlossen
            abrechnungsperiode
        }
    }
`;


export const GET_NEBENKOSTENRECHNUNGEN_OF_PACHT = gql`
    query GetNebenkostenrechnungenOfPacht($pachtID: ID!) {
        nebenkostenrechnungenOfPacht(pachtId: $pachtID){
            id
            rechnungsdatum
            rechnungsnummer
            debNr
            rechnungsbetrag
            paechtername
            paechtervorname
            storniert
            stornoVon
        }
    }
`;


export const CREATE_NEBENKOSTENRECHNUNG = gql`
    mutation CreateNebenkostenrechnung(
        $pacht: ID!,
        $abrechnungsperiode: String!,
        $rechnungslaufId: Int,
        $stornoVon: String,
        ) {
        createNebenkostenrechnung(
            pacht: $pacht,
            abrechnungsperiode: $abrechnungsperiode,
            rechnungslaufId: $rechnungslaufId,
            stornoVon: $stornoVon,
             ) {
                id
                rechnungsnummer
            }
    }
`;


export const LOCK_NEBENKOSTENRECHNUNG = gql`
    mutation LockNebenkostenrechnung($id: ID!){
        lockNebenkostenrechnung(id: $id) {
            ok
        }
    }
`;



// Für eine Stornierung nutzen wir im backend die mutation createNebenkostenrechnung
export const STORNO_NEBENKOSTENRECHNUNG = gql`
    mutation CreateNebenkostenrechnung(
        $stornoVon: String,
        $pacht: ID,
        $abrechnungsperiode: String,
        $rechnungslaufId: Int,
        ){
        createNebenkostenrechnung(
            stornoVon: $stornoVon,
            pacht: $pacht,
            abrechnungsperiode: $abrechnungsperiode,
            rechnungslaufId: $rechnungslaufId,
            ) {
                id
                stornoVon
                rechnungsnummer
        }
    }
`;



export const DELETE_NEBENKOSTENRECHNUNG = gql`
    mutation DeleteNebenkostenrechnung($id: ID!){
        deleteNebenkostenrechnung(id: $id) {
            ok
        }
    }
`;


