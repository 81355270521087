import * as React from 'react';
import { useParams } from "react-router-dom";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider, from} from '@apollo/client';
import {GET_MOBILHEIME} from '../Mobilheime/graphql';
import { GET_PARZELLEN } from './graphql';
import { GET_PAECHTER } from '../Paechter/graphql';

import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { Link } from "react-router-dom";

import Autocomplete from '@mui/material/Autocomplete';
import Title from '../Utils/Title'
import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';





let loads;

function GetParzellen() {
    //const [loads, setLoading] = React.useState(false)
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };
    if (error) return <p>Error :(</p>;
    return data.parzellen;
    
}


function GetPaechter() {
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;
    
    return data.paechter;
}


function GetMobilheime() {
    const { loading, error, data, refetch } = useQuery(GET_MOBILHEIME, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;
    return data.mobilheime;
}



// fomat chached date to dd.MM.yyyy
function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
    } else {
        // important: set date to empty string - otherwise yup will make field required!!
        date = '';
    }
    return date;
}





export default function ShowParzelle() {

    const params = useParams();
    const parzelleID = params.id;
    const parzellen = GetParzellen();

    const getPaechter = GetPaechter();
    const getMobilheime = GetMobilheime();

    const navigate = useNavigate();
    const goBack = () => {
		navigate(-1);
	}

    const handleEditButton = () => {
        navigate(`/parzelle/${parzelleID}/update`);
    }

    // filter to get parzelle with id
    const filtered = Object.values(parzellen).filter(parzelle => parzelle.id === parzelleID);



    const LinkTextFieldMobilheim = () => {

        const inputStyles = {
            height: '56px',
        };

        return (
          <TextField
            id="link-textfield"
            label="Mobilheim"
            variant="filled"
            fullWidth
            disabled
            size="medium"
            InputProps={{
              inputComponent: MobilheimLink,
              style: inputStyles,
            }}
            value={MobilheimLink}
            InputLabelProps={{
                shrink: filtered[0].mobilheim ? true : false,
            }}
          />
        );
    };

    const MobilheimLink = () => {
        if (filtered[0].mobilheim) {
            return (
                <Typography component="div" className="reference-link">
                    <Link to={`/mobilheim/${filtered[0].mobilheim.id}/show`}>
                        {filtered[0].standort} 
                    </Link>
                </Typography>
            )
        }
        return '';
    };
    

    // set const for 'Schlüssel im Büro'    
    function formatBoolSchluesselImBuero() {
        if(filtered.map(paechter => {return paechter.schluesselImBuero}).toString() == 'true') {
            return 'Ja';
        } else {
            return 'Nein';
        }
    }
    const formatSchluesselImBuero = formatBoolSchluesselImBuero();

    

    // get paechter
    const paechter = (val) => { 
        if(val !== '') {
            // get id's of array of objects, see https://stackoverflow.com/a/64110076/2732698
            return val.map( (item)=>item.paechterSet);
        }
    }

    const paechterData = filtered[0].paechterSet;


    /*
    const LinkTextFieldPaechter = () => {

        const inputStyles = {
            height: '56px',
        };

        return (
          <TextField
            id="link-textfield"
            label="Pächter"
            variant="filled"
            fullWidth
            disabled
            size="medium"
            InputProps={{
              inputComponent: Namen,
              style: inputStyles,
            }}
            value={Namen}
            InputLabelProps={{
                shrink: paechterData.length < 1 ? false : true,
            }}
          />
        );
    };

    const Namen = () => (
            paechterData.map(item => {
                return (
                    <Typography component="div" className="reference-link">
                        <Link to={`/paechter/${item.id}/show`}>
                            {item.name + ', ' + item.vorname}
                        </Link>
                    </Typography>
                )
            })
    );


    < LinkTextFieldPaechter />
    < LinkTextFieldMobilheim />

    */

    // set const for 'Gelbe Tonne'    
    function formatBoolGelbeTonne() {
        if(filtered.map(parzelle => {return (parzelle.gelbeTonne)}).toString() == 'true') {
            return 'Ja';
        } else {
            return 'Nein';
        }
    }
    const formatGelbeTonne = formatBoolGelbeTonne();


    // set const for 'Blaue Tonne'    
    function formatBoolBlaueTonne() {
        if(filtered.map(parzelle => {return (parzelle.blaueTonne)}).toString() == 'true') {
            return 'Ja';
        } else {
            return 'Nein';
        }
    }
    const formatBlaueTonne = formatBoolBlaueTonne();


    // set const for 'Stellplatzparzelle'    
    function formatBoolStellplatzparzelle() {
        if(filtered.map(parzelle => {return (parzelle.stellplatzparzelle)}).toString() == 'true') {
            return 'Ja';
        } else {
            return 'Nein';
        }
    }
    const formatStellplatzparzelle = formatBoolStellplatzparzelle();


    // set const for 'Verwaltungsparzelle'    
    function formatBoolVerwaltungsparzelle() {
        if(filtered.map(parzelle => {return (parzelle.verwaltungsparzelle)}).toString() == 'true') {
            return 'Ja';
        } else {
            return 'Nein';
        }
    }
    const formatVerwaltungsparzelle = formatBoolVerwaltungsparzelle();



    // set const for 'Verwaltungsparzelle'    
    function formatBoolAConto() {
        if(filtered.map(parzelle => {return (parzelle.aConto)}).toString() == 'true') {
            return 'Ja';
        } else {
            return 'Nein';
        }
    }
    const formatAConto = formatBoolAConto();





    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                        >
                            
                            {filtered.map(parzelle => {
                                return (
                                    <>
                                        <Title>Parzelle "{parzelle.standort}" anzeigen</Title>

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Standort"
                                            value={parzelle.standort}
                                        />
                                        
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Grösse (in Quadratmetern)"
                                            value={parzelle.parzellengroesse}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Besonderheiten"
                                            value={parzelle.besonderheiten}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Zusatzpflege"
                                            value={parzelle.zusatzpflege}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="FI-Schalter"
                                            value={parzelle.fiSchalterNeu}
                                        />
                                        <TextField      
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Gasdruckprüfung"
                                            value={formatCachedDate(parzelle.gasdruckpruefung)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Geplante Gasdruckprüfung"
                                            value={formatCachedDate(parzelle.geplanteGasdruckpruefung)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="SAV"
                                            value={parzelle.sav}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Biotonne Nr."
                                            value={parzelle.bioTonneNr}
                                        />
                                        
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Gelbe Tonne"
                                            value={formatGelbeTonne}
                                        />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Blaue Tonne"
                                            value={formatBlaueTonne}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Restmüll Tonne Nr."
                                            value={parzelle.restmuellTonneNr}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Stellplatzparzelle"
                                            value={formatStellplatzparzelle}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Verwaltungsparzelle"
                                            value={formatVerwaltungsparzelle}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Schacht"
                                            value={parzelle.schacht}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Schlüssel im Büro"
                                            value={formatSchluesselImBuero}
                                        />
                                    </>                                    
                                );
                                })
                            }
                            <Button color="primary" variant="contained" onClick={handleEditButton}>
                                bearbeiten
                            </Button>
                            <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                zurück
                            </Button>
                            
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};