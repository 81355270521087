import * as React from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { GET_ONE_PAECHTER } from '../../Paechter/graphql';
import Title from '../../Utils/Title';
import Button from '@mui/material/Button';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LoadingAnimation from '../../Utils/LoadingAnimation';

function GetOnePaechter(pachtID) {
    const { loading, error, data } = useQuery(GET_ONE_PAECHTER, {
        variables: { pachtID },
        onCompleted: () => {
            console.log('Query completed');
        }
    });

    if (loading) {
        return { loading: true, data: null, error: null };
    }
    if (error) {
        return { loading: false, data: null, error: error.message };
    }
    return { loading: false, data: data.onePaechter, error: null };
}

function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = `${date[2]}.${date[1]}.${date[0]}`;
    } else {
        date = '';
    }
    return date;
}

function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

export default function ShowPaechter() {
    const params = useParams();
    const pachtID = params.id;
    const navigate = useNavigate();
    const { loading, data, error } = GetOnePaechter(pachtID);

    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
            <LoadingAnimation />
        </Box>
    );
    if (error) return <p>Error: {error}</p>;

    const onepaechter = data;

    const goBack = () => {
        navigate(-1);
    }

    const handleEditButton = () => {
        navigate(`/paechter/${onepaechter.id}/update`);
    }

    

    

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid
                    item xs={12}
                    sx={{ flexGrow: 1}}
                >
                    
                        <Box 
                            noValidate
                            sx={{
                                '& .MuiTextField-root': { marginRight: 3, minWidth: '400px' },
                                flexGrow: 1
                            }}
                        >
                            {onepaechter && onepaechter.map(paechter => (
                                <React.Fragment key={paechter.id}>
                                    <Title>{paechter.vorname} {paechter.name}</Title>
                                    <TextField
                                        fullWidth
                                        sx={{maxWidth: "825px"}}
                                        disabled
                                        variant='filled'
                                        label="Anrede"
                                        value={paechter.anrede}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Nachname"
                                        value={paechter.name}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Vorname"
                                        value={paechter.vorname}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Strasse und Hausnummer"
                                        value={paechter.strasse}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="PLZ"
                                        value={paechter.plz}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Ort"
                                        value={paechter.ort}
                                    />

                                    <TextField
                                        fullWidth
                                        sx={{maxWidth: "825px"}}
                                        disabled
                                        variant='filled'
                                        label="Vorherige Meldeadresse"
                                        value={paechter.vorherigeMeldeadresse}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Telefon Festnetz"
                                        value={paechter.telefonFestnetz}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Telefon Mobil"
                                        value={paechter.telefonMobil}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Geburtsdatum"
                                        value={formatCachedDate(paechter.geburtsdatum)}
                                    />
                                    
                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Geburtsname"
                                        value={paechter.geburtsname}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Name Partner"
                                        value={paechter.namePartner}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Geburtsdatum Partner"
                                        value={formatCachedDate(paechter.geburtsdatumPartner)}
                                    />
                                    
                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Ausweisnummer Pächter"
                                        value={paechter.ausweisnummerPaechter}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Ausweisnummer Partner"
                                        value={paechter.ausweisnummerPartner}
                                    />

                                    <TextField
                                        fullWidth
                                        sx={{maxWidth: "825px"}}
                                        multiline
                                        rows={3}
                                        disabled
                                        variant='filled'
                                        label="Weitere Kontaktpersonen"
                                        value={paechter.weitereKontaktpersonen}
                                        sx={{maxWidth: "825px"}}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="IBAN"
                                        value={paechter.iban}
                                    />

                                    <TextField
                                        fullWidth
                                        sx={{maxWidth: "825px"}}
                                        multiline
                                        rows={3}
                                        disabled
                                        variant='filled'
                                        label="Zusatzpflege"
                                        value={paechter.zusatzpflege}
                                    />

                                    <TextField
                                        fullWidth
                                        sx={{maxWidth: "825px"}}
                                        multiline
                                        rows={10}
                                        disabled
                                        variant='filled'
                                        label="Auflagen"
                                        value={paechter.auflagen}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Auflagenfrist"
                                        value={formatCachedDate(paechter.auflagenfrist)}
                                    />

                                    <TextField
                                        fullWidth
                                        sx={{maxWidth: "825px"}}
                                        multiline
                                        fullWidth
                                        rows={5}
                                        disabled
                                        variant='filled'
                                        label="Duldungen"
                                        value={paechter.duldungen}
                                    />

                                    <TextField
                                        fullWidth
                                        sx={{maxWidth: "825px"}}
                                        disabled
                                        variant='filled'
                                        label="Briefkasten Nr."
                                        value={paechter.briefkastenNr}
                                    />
                                    
                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Sensor 1"
                                        value={paechter.sensor1}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Kaufdatum Sensor 1"
                                        value={formatCachedDate(paechter.kaufdatumSensor1)}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Sensor 2"
                                        value={paechter.sensor2}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Kaufdatum Sensor 2"
                                        value={formatCachedDate(paechter.kaufdatumSensor2)}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Magnetkarte"
                                        value={paechter.magnetkarte}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Ausgabedatum Magnetkarte"
                                        value={formatCachedDate(paechter.ausgabedatumMagnetkarte)}
                                    />

                                    <TextField
                                        fullWidth
                                        sx={{maxWidth: "825px"}}
                                        disabled
                                        variant='filled'
                                        label="Gebrauchte Sensoren"
                                        value={paechter.gebrauchteSensoren}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="KFZ-Kennzeichen"
                                        value={paechter.kfzKennzeichen}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Erschließungskosten (EUR)"
                                        value={paechter.erschliessungskosten}
                                    />

                                    <TextField
                                        disabled
                                        variant='filled'
                                        label="Ausgeschieden am"
                                        value={formatCachedDate(paechter.ausgeschiedenAm)}
                                    />
                                    
                                </React.Fragment>
                            ))}
                            
                        </Box>
                    
                </Grid>
            </Grid>
        </Container>
    );
}
