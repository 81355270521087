//import React from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";

import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';


import AlertMessage from '../Alerts/AlertMessage';

import {CREATE_PACHT, GET_PACHT} from './graphql';
import { GET_PARZELLEN } from '../Parzellen/graphql';
import { GET_PAECHTER } from '../Paechter/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';


import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


let loads;


// format currency back from format 00,00 to 00.00, so it can be written back to database
function formatBackCurrency(amount) {
    if (amount) {
        return amount.replace(",", ".");
    }
    return null;
}


// fomat date to yyyy-MM-dd so it can be written back to database
function formatDate(date) {
    // format only, if the date field is not an empty string
    // otherwise we get an error at non-mandatoy date fields, if date fields are not filled
    if(date) {
        date = date.split(".");
        // if day has not 2 digits
        if (date[0].length < 2) {
            date[0] = "0" + date[0];
        }
        // if month has not 2 digits
        if (date[1].length < 2) {
            date[1] = "0" + date[1];
        }
        date = date[2] + "-" + date[1] + "-" + date[0];
        return date;
        
    } else {
        // we have to return null, because in backend we can only make a date field optional when setting 'null=True' in models.py
        return null;
    }
}

function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


// Get Parzellen
function GetParzellen() {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;
        
    return data.parzellen.map(({ id, standort }) => (
        {id, standort}
    ));
    
}


function GetPaechter() {
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };
    if (error) return <p>Error :(</p>;

    return data.paechter
    .map(({ id, name, vorname, geburtsdatum }) => (
        {id, name, vorname, geburtsdatum}
    ));
    
}


// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');
const today = new Date();
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');
// if date has to be in the future
const validateDateFuture = validateDate.min(today, 'Das Datum darf nicht in der Vergangenheit liegen');
const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    .required("Dieses Feld ist ein Pflichtfeld");



const validationSchema = yup.object({
    parzelle: yup.object().required("Es muss eine Parzelle ausgewählt werden"),
    paechter: yup.object().required("Es muss ein Pächter ausgewählt werden"),
    //pachtbeginn: validateDateFuture,
    pachtbeginn: validateDate.required("Dieses Feld ist ein Pflichtfeld"),
    pachtende: validateDateFuture,
    aConto: yup.boolean().required('Bitte wählen'),
});




export default function CreatePacht() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();

    const parzelleData = GetParzellen();
    const paechterData = GetPaechter();

    
    

    const [createPacht, { data, loading, error, graphQLErrors, refetch }] = useMutation(CREATE_PACHT, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_PACHT }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data?.createPacht?.id)) {
                setAlertMessage('Pacht mit der ID ' + data.createPacht.id + ' erfolgreich angelegt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/pacht`);
                }, 2000);
                
            } else {
                
            }
        }
    });
    

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);
    

    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        parzelle: '',
        paechter: '',
        pachtbeginn: '',
        pachtende: '',
        aConto: '',
    };


    const handleSubmit = (values) => {
        
        const formatBool = (val) => {
            if(val == '') {
                return false;
            }
            return val;
        }

        createPacht({ 
            variables: {
                parzelle: values.parzelle.id,
                paechter: values.paechter.id,
                pachtbeginn: formatDate(values.pachtbeginn),
                pachtende: formatDate(values.pachtende),
                aConto: formatBool(values.aConto),
            }
        });
        
    };


    return (
        <>

        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>

            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Pacht anlegen</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <Autocomplete
                                            id="parzelle"
                                            name="parzelle"
                                            options={parzelleData}
                                            getOptionLabel={(option) => option.standort}
                                            onChange={(e, value) => {
                                                setFieldValue(
                                                    "parzelle", value
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    label="Parzelle"
                                                    fullWidth
                                                    variant='filled'
                                                    name="parzelle"
                                                    {...params}
                                                    error={touched.parzelle && Boolean(errors.parzelle)}
                                                    helperText={touched.parzelle && errors.parzelle}
                                                />
                                            )}
                                        />

                                        <Autocomplete
                                            id="paechter"
                                            name="paechter"
                                            options={paechterData}
                                            filterSelectedOptions
                                            getOptionLabel={(option) => (
                                                option.name + ", " + 
                                                option.vorname + 
                                                ' | geb. ' + formatCachedDate(option.geburtsdatum) +
                                                ' (ID ' + option.id + ')'
                                            )}
                                            
                                            onChange={(e, value) => {
                                                setFieldValue(
                                                    "paechter", value
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    label="Pächter"
                                                    fullWidth
                                                    variant='filled'
                                                    name="paechter"
                                                    {...params}
                                                    error={touched.paechter && Boolean(errors.paechter)}
                                                    helperText={touched.paechter && errors.paechter}
                                                />
                                            )}
                                        />

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                        >
                                            <InputLabel>a conto</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="aConto"
                                                name="aConto"
                                                value={values.aConto}
                                                onChange={handleChange}
                                                error={touched.aConto && Boolean(errors.aConto)}
                                                helperText={touched.aConto && errors.aConto}
                                            >
                                                <MenuItem className='selectField' value={false}>Nein</MenuItem>
                                                <MenuItem className='selectField' value={true}>Ja</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.aConto && errors.aConto ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.aConto && errors.aConto}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="pachtbeginn"
                                            name="pachtbeginn"
                                            label="Pachtbeginn"
                                            placeholder='TT.MM.JJJJ'
                                            value={values.pachtbeginn}
                                            onChange={handleChange}
                                            error={touched.pachtbeginn && Boolean(errors.pachtbeginn)}
                                            helperText={touched.pachtbeginn && errors.pachtbeginn}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="pachtende"
                                            name="pachtende"
                                            label="Pachtende"
                                            placeholder='TT.MM.JJJJ'
                                            value={values.pachtende}
                                            onChange={handleChange}
                                            error={touched.pachtende && Boolean(errors.pachtende)}
                                            helperText={touched.pachtende && errors.pachtende}
                                        />

                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        
        </>
    );

};




