import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import ShowPacht from './Stammdaten';
import ShowPaechter from './Paechter';
import ShowMobilheim from './Mobilheim';
import ShowParzelle from './Parzelle';

import ListRechnungenOfPacht from './Rechnungen';
import ListGutschriftenOfPacht from './Gutschriften';
import ListZahlungenOfPacht from './Zahlungen';
import ListMahnungenOfPacht from './Mahnungen';


export default function PachtBoard() {
    const [value, setValue] = React.useState('stammdaten');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box sx={{ width: '100%' }}>
                            <TabContext value={value}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    centered = 'true'
                                    aria-label="tab"
                                    sx={{
                                        borderBottom: '1px solid lightgrey'
                                    }}
                                >
                                    <Tab 
                                        value="stammdaten"
                                        label="Stammdaten"
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor: 'transparent !important',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent !important',
                                                color: 'rgb(87, 119, 168) !important'
                                            },
                                        }}
                                    />

                                    <Tab 
                                        value="paechter"
                                        label="paechter"
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor: 'transparent !important',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent !important',
                                                color: 'rgb(87, 119, 168) !important'
                                            },
                                        }}
                                    />
                                    
                                    <Tab 
                                        value="mobilheim"
                                        label="Mobilheim"
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor: 'transparent !important',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent !important',
                                                color: 'rgb(87, 119, 168) !important'
                                            },
                                        }}
                                    />

                                    <Tab 
                                        value="parzelle"
                                        label="parzelle"
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor: 'transparent !important',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent !important',
                                                color: 'rgb(87, 119, 168) !important'
                                            },
                                        }}
                                    />

                                    <Tab
                                        value="rechnungen"
                                        label="Rechnungen"
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor: 'transparent !important',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent !important',
                                                color: 'rgb(87, 119, 168) !important'
                                            },
                                        }}    
                                    />

                                    <Tab
                                        value="gutschriften"
                                        label="Gutschriften"
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor: 'transparent !important',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent !important',
                                                color: 'rgb(87, 119, 168) !important'
                                            },
                                        }}    
                                    />

                                    <Tab 
                                        value="zahlungen"
                                        label="Zahlungen"
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor: 'transparent !important',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent !important',
                                                color: 'rgb(87, 119, 168) !important'
                                            },
                                        }}
                                    />

                                    <Tab 
                                        value="mahnungen"
                                        label="Mahnungen"
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor: 'transparent !important',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent !important',
                                                color: 'rgb(87, 119, 168) !important'
                                            },
                                        }}
                                    />
                                    
                                </Tabs>
                                
                                <TabPanel value="stammdaten">
                                    <ShowPacht />
                                    
                                </TabPanel>

                                <TabPanel value="paechter">
                                    <ShowPaechter />
                                </TabPanel>

                                <TabPanel value="mobilheim">
                                    <ShowMobilheim />
                                </TabPanel>

                                <TabPanel value="parzelle">
                                    <ShowParzelle />
                                </TabPanel>

                                <TabPanel value="rechnungen">
                                    <ListRechnungenOfPacht />
                                </TabPanel>

                                <TabPanel value="gutschriften">
                                    <ListGutschriftenOfPacht />
                                </TabPanel>

                                <TabPanel value="zahlungen">
                                    <ListZahlungenOfPacht />
                                </TabPanel>

                                <TabPanel value="mahnungen">
                                    <ListMahnungenOfPacht />
                                </TabPanel>

                            </TabContext>
                        </Box>
                    </Paper>
            </Grid>
        </Grid>
    </Container>
  );
}