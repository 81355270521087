import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useParams } from "react-router-dom";

import { CREATE_ZAHLUNGSMODUS } from './graphql';
import { GET_PACHT } from '../Pacht/graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AlertMessage from '../Alerts/AlertMessage';


let loads;



// yup validation

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

const currentDate = new Date();
const currentYear = currentDate.getFullYear();



const validationSchema = yup.object({
    aenderungZum: yup
        .number()
        .typeError('Bitte das Jahr vierstellig erfassen')
        //.min(currentYear, 'Das Jahr darf nicht in der Vergangenheit liegen')
        .test('len', 'Bitte das Jahr vierstellig erfassen', val => val && val.toString().length === 4)
        .required('Bitte das Jahr vierstellig erfassen'),
});



export default function CreateZahlungsmodus() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const navigate = useNavigate();

    const params = useParams();
    const pachtID = params.id;

    const [createZahlungsmodus, { data, loading, error, refetch }] = useMutation(CREATE_ZAHLUNGSMODUS, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_PACHT }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        
        onCompleted: (data) => {
            if (Boolean(data.createZahlungsmodus?.id)) {
                setAlertMessage('Zahlungsmodus erfolgreich geändert');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/pacht`);
                }, 2000);
            }
        }
    });

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);

    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        pacht: pachtID,
        aenderungZum: '',
    };


    const handleSubmit = (values) => {
        // siehe Dokumentation im Backend. Es muss ein date übergeben werden
        const convertToGueltigAb = (val) => {
            // Create a new Date object with the given year
            const dateObj = new Date(val, 0, 1);
            // Format the date to 'YYYY-MM-DD' format
            const formattedDate = `${dateObj.getFullYear()}-01-01`;
            return formattedDate;
        }

        createZahlungsmodus({ 
            variables: {
                pacht: pachtID,                
                aenderungZum: convertToGueltigAb(parseInt(values.aenderungZum)),
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Zahlungsmodus ändern</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="aenderungZum"
                                            name="aenderungZum"
                                            label="Gültig ab (Jahr)"
                                            value={values.aenderungZum}
                                            onChange={handleChange}
                                            error={touched.aenderungZum && Boolean(errors.aenderungZum)}
                                            helperText={touched.aenderungZum && errors.aenderungZum}
                                        />

                                        
                                        <Button color="primary" variant="contained" type="submit">
                                            ändern
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




