import { gql } from '@apollo/client';


export const GET_SONSTIGE_RECHNUNGEN = gql`
    query GetSonstigeRechnungen {
        sonstigeRechnungen{
            id
            rechnungsdatum
            rechnungsnummer
            debNr
            rechnungsbetrag
            parzelleStandort
            rechnungsnummer
            paechtername
            paechtervorname
            stornoVon
            abgeschlossen
            storniert
            pacht{
                id
            }
            externerEmpfaenger
        }
    }
`;


export const GET_SONSTIGE_RECHNUNGEN_OF_PACHT = gql`
    query GetSonstigeRechnungenOfPacht($pachtID: ID!) {
        sonstigeRechnungenOfPacht(pachtId: $pachtID){
            id
            rechnungsdatum
            rechnungsnummer
            debNr
            rechnungsbetrag
            paechtername
            paechtervorname
            storniert
            stornoVon
        }
    }
`;


export const CREATE_SONSTIGE_RECHNUNG = gql`
    mutation CreateSonstigeRechnung(
        $rechnungsdatum: Date!,
        $leistungsdatum: Date,
        $rechnungspositionen: String!,
        $pacht: ID,
        $externerEmpfaenger: String,
        $mwst: Decimal,
        $stornoVon: String,
        $hinweisUeberweisung: String,
        ) {
        createSonstigeRechnung(
            rechnungsdatum: $rechnungsdatum,
            leistungsdatum: $leistungsdatum,
            rechnungspositionen: $rechnungspositionen,
            pacht: $pacht,
            externerEmpfaenger: $externerEmpfaenger,
            mwst: $mwst,
            stornoVon: $stornoVon,
            hinweisUeberweisung: $hinweisUeberweisung,
             ) {
                id
                rechnungsnummer
                rechnungsdatum
                rechnungsbetrag
            }
    }
`;


export const LOCK_SONSTIGE_RECHNUNG = gql`
    mutation LockSonstigeRechnungen($id: ID!){
        lockSonstigeRechnung(id: $id) {
            ok
        }
    }
`;



// Für eine Stornierung nutzen wir im backend die mutation createGutschrift
export const STORNO_SONSTIGE_RECHNUNG = gql`
    mutation CreateSonstigeRechnung(
        $rechnungsdatum: Date!,
        $leistungsdatum: Date,
        $rechnungspositionen: String!,
        $pacht: ID,
        $externerEmpfaenger: String,
        $mwst: Decimal,
        $stornoVon: String,
        $hinweisUeberweisung: String,
        ) {
        createSonstigeRechnung(
            rechnungsdatum: $rechnungsdatum,
            leistungsdatum: $leistungsdatum,
            rechnungspositionen: $rechnungspositionen,
            pacht: $pacht,
            externerEmpfaenger: $externerEmpfaenger,
            mwst: $mwst,
            stornoVon: $stornoVon,
            hinweisUeberweisung: $hinweisUeberweisung,
            ) {
                id
                rechnungsnummer
                rechnungsdatum
                rechnungsbetrag
            }
    }
`;



export const DELETE_SONSTIGE_RECHNUNG = gql`
    mutation DeleteSonstigeRechnung($id: ID!){
        deleteSonstigeRechnung(id: $id) {
            ok
        }
    }
`;




