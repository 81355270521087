import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from "formik";
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useParams } from "react-router-dom";

import { CREATE_SONSTIGE_RECHNUNG, GET_SONSTIGE_RECHNUNGEN } from './graphql';

import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";
import FormControl from '@mui/material/FormControl';

import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import IconButton from "@mui/material/IconButton";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import AlertMessage from '../Alerts/AlertMessage';
import Autocomplete from '@mui/material/Autocomplete';
import { red } from '@mui/material/colors';


let loads;



function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


function formatDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


function formatDateToDB(date) {
    if(date == null) {
        return;
    } else {
        date = date.split(".");
        date = date[2] + "-" + date[1] + "-" + date[0];
        return date;
    }
}


// format currency back from format 00,00 to 00.00, so it can be written back to database
function formatBackCurrency(amount) {
    return amount.replace(",", ".");
}



function GetSonstigeRechnungen() {
    const { loading, error, data, refetch } = useQuery(GET_SONSTIGE_RECHNUNGEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
    if (loading) {
        loads = true;
        return loads;
    };
    if (error) {
        console.log(error);
        alert(error);
        return error;
    }
    const sonstigeRechnungenList = data.sonstigeRechnungen.map(item => {
        const id = item.id;
        const pachtId = item.pacht?.id;
        const rechnungsnummer = item.rechnungsnummer;
        return { 
            id,
            pachtId,
            rechnungsnummer,
        };
    });
    return sonstigeRechnungenList;

}



// yup validation

// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// date validation
const minDate = new Date('1900-01-01');

const today = new Date();

const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Jahr muss vierstellig sein - bitte korrigieren');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen');

// if date has to be in the future
const validateDateFuture = validateDate.min(tomorrow, 'Das Datum muss in der Zukunft liegen');

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');
const requiredInt = yup.number().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^-?\d+(,\d{2})?$/;
const euroValidator = yup
    .string()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )
    .required("Dieses Feld ist ein Pflichtfeld");


const validationSchema = yup.object({
    rechnungsdatum: validateDatePast.required('Dieses Feld ist ein Pflichtfeld'),
    leistungsdatum: validateDatePast,
});


function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}




export default function StornoSonstigeRechnung() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const params = useParams();
    const rechnungID = params.id;

    const sonstigeRechnungenData = GetSonstigeRechnungen();
    const zuStornierendeRechnung = sonstigeRechnungenData.find(item => item.id === rechnungID);
    const stornoVon = zuStornierendeRechnung.rechnungsnummer;
    const pachtId = zuStornierendeRechnung.pachtId;


    // "Gesamtbetrag der Rechnung"
    const [gesamtbetrag, setGesamtbetrag] = React.useState(0)
    
    // fields for credits positions ("Rechnungs-Positionen")
    const [inputPositionFields, setInputPositionFields] = React.useState([
        { position: '', betrag: '' },
    ])

    const handleAddFields = () => {
        console.log(inputPositionFields.length);
        // Limitierung Felder/columns auf maximal 5
        if (inputPositionFields.length + 1 <= 10) {
            setInputPositionFields([...inputPositionFields, { position: '', betrag: '' }]);
        } else {
            console.log('Mehr als 10 Positionen sind nicht erlaubt');
        }
        
        
    };

    const handleRemoveFields = (index) => {
        const values = [...inputPositionFields];
        // Nur wenn noch mindestens 2 Felder bzw. columns vorhanden sind, column löschen
        if (inputPositionFields.length + 1 > 2) {
            values.splice(index, 1);
            setInputPositionFields(values);
        }
    };

    // fires, when field gets left
    // Erst nach dem Verlassen des Feldes soll die Summe im Feld addiert werden
    // Feld unten vorerst aus Zeitgründen wieder rausgenommen
    const handleBlurInput = (index, event) => {
        setGesamtbetrag(gesamtbetrag => gesamtbetrag + parseFloat(event.target.value));
    };


    const handleChangeInput = (index, event) => {
        const values = [...inputPositionFields];
        values[index][event.target.name] = event.target.value;
        setInputPositionFields(values);
    };

    const navigate = useNavigate();


    const [stornoSonstigeRechnung, { data, loading, error, refetch }] = useMutation(CREATE_SONSTIGE_RECHNUNG, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_SONSTIGE_RECHNUNGEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.createSonstigeRechnung?.id)) {
                var betragMitCent = formatCurrency(data.createSonstigeRechnung.rechnungsbetrag);
                setAlertMessage('Alte Rechnung storniert. Neue Rechnung über EUR ' + betragMitCent + ' erfolgreich angelegt');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/rechnungen/sonstige-rechnungen`);
                }, 2000);
            }
        }
    });


    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage(message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    
    const goBack = () => {
		navigate(-1);
	}



    const initialValues = {
        rechnungsdatum: '',
        leistungsdatum: '',
        position: '',
        betrag: '',
        mwst: 'ohne',
        externerEmpfaenger: '',
    };


    const handleSubmit = (values) => {

        const checkLeistungsdatum = (leistungsdatum) => {
            if (leistungsdatum) {
                return formatDateToDB(leistungsdatum);
            } else {
                return null;
            }
        }

        const checkMwst = (value) => {
            if (value === 'ohne') {
                return null;
            } else {
                console.log('number: ', Number(value));
                return Number(value);
            }
        }

        stornoSonstigeRechnung({ 
            variables: {
                rechnungsdatum:  formatDateToDB(values.rechnungsdatum),
                leistungsdatum:  checkLeistungsdatum(values.leistungsdatum),
                pacht: parseInt(pachtId),
                externerEmpfaenger: values.externerEmpfaenger,
                rechnungspositionen: JSON.stringify(inputPositionFields),
                stornoVon: stornoVon,
                mwst: checkMwst(values.mwst),
                
            }
        });
        
    };


    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); console.log('close fired'); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Sonstige Rechnung-Nr. {stornoVon} stornieren</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    
                                    <Form noValidate>

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            required
                                            id="rechnungsdatum"
                                            name="rechnungsdatum"
                                            label='Rechnungsdatum'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.rechnungsdatum}
                                            onChange={handleChange}
                                            error={touched.rechnungsdatum && Boolean(errors.rechnungsdatum)}
                                            helperText={touched.rechnungsdatum && errors.rechnungsdatum}
                                        />

                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="leistungsdatum"
                                            name="leistungsdatum"
                                            label='Leistungsdatum'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.leistungsdatum}
                                            onChange={handleChange}
                                            error={touched.leistungsdatum && Boolean(errors.leistungsdatum)}
                                            helperText={touched.leistungsdatum && errors.leistungsdatum}
                                        />
                                        
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={5}
                                            variant='filled'
                                            id="externerEmpfaenger"
                                            name="externerEmpfaenger"
                                            label="Externer Rechnungsempfänger"
                                            value={values.externerEmpfaenger}
                                            onChange={handleChange}
                                            error={touched.externerEmpfaenger && Boolean(errors.externerEmpfaenger)}
                                            helperText={touched.externerEmpfaenger && errors.externerEmpfaenger}
                                        />

                                        {inputPositionFields.map((inputPositionField, index) => (
                                            <div key={index}>
                                            <TextField
                                                name="position"
                                                id="position"
                                                label="Bezeichnung der Leistung"
                                                variant="filled"
                                                sx={{ m: 1, width: '65%', marginLeft: '0' }}
                                                value={inputPositionField.position}
                                                onChange={(event) => handleChangeInput(index, event)}
                                                //onChange={(e, value) => {
                                                //    handleChangeInput(index, e);
                                                //    setFieldValue(
                                                //        //"position", value
                                                //        inputPositionField.position, value
                                                //    );
                                                //}}
                                                error={touched.position && Boolean(errors.position)}
                                                helperText={touched.position && errors.position}
                                            />
                                            <TextField
                                                name="betrag"
                                                id="betrag"
                                                label="Betrag"
                                                variant="filled"
                                                sx={{ m: 1, width: '20%' }}
                                                value={inputPositionField.betrag}
                                                onChange={(event) => handleChangeInput(index, event)}
                                                onBlur={(event) => handleBlurInput(index, event)}
                                                error={touched.betrag && Boolean(errors.betrag)}
                                                helperText={touched.betrag && errors.betrag}
                                            />
                                            <IconButton onClick={() => handleRemoveFields(index)} sx={{ 
                                                marginTop: '15px',
                                                '& > :selected': { color: red },
                                                }} >
                                                <RemoveIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleAddFields()} sx={{ marginTop: '15px' }} >
                                                <AddIcon />
                                            </IconButton>
                                            </div>
                                        ))}

                                        <FormControl 
                                            variant='filled'
                                            fullWidth
                                        >
                                            <InputLabel>Mehrwertsteuer</InputLabel>
                                            <Select
                                                MenuProps={{
                                                  sx: {
                                                    "&& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    },
                                                    "&& .MuiMenuItem-root": {
                                                        backgroundColor: "transparent !important"
                                                    },
                                                    "&& .Mui-selected": {
                                                        backgroundColor: "#f2f2f2 !important"
                                                    }
                                                  }
                                                }}
                                                id="mwst"
                                                name="mwst"
                                                value={values.mwst}
                                                onChange={handleChange}
                                                error={touched.mwst && Boolean(errors.mwst)}
                                                helperText={touched.mwst && errors.mwst}
                                            >
                                                <MenuItem className='selectField' value="ohne">Ohne Mehrwertsteuer</MenuItem>
                                                <MenuItem className='selectField' value="19">19 Prozent</MenuItem>
                                            </Select>
                                                                                        
                                            {touched.mwst && errors.mwst ? (
                                                <FormHelperText
                                                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                                                >
                                                    {touched.mwst && errors.mwst}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <Button color="primary" variant="contained" type="submit">
                                            anlegen
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                        
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

};




