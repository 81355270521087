import { gql } from '@apollo/client';


export const GET_MOBILHEIME = gql`
    query GetMobilheime {
        mobilheime{
            id
            typ
            anbau
            baujahr
            kaufdatum
            vorbesitzer
            kaufpreis
            parzelle{
                id
                standort
                mobilheim {
                    id
                }
                stellplatzparzelle
            }
        }
    }
`;

export const GET_ONE_MOBILHEIM = gql`
    query GetOneMobilheim($pachtID: ID!) {
        oneMobilheim(pachtId: $pachtID) {
            id
            typ
            anbau
            baujahr
            kaufdatum
            vorbesitzer
            kaufpreis
            parzelle{
                id
                standort
                mobilheim {
                    id
                }
                stellplatzparzelle
            }
        }
    }
`;


export const CREATE_MOBILHEIM = gql`
    mutation CreateMobilheim(
        $parzelle: ID!,
        $typ: String!,
        $anbau: String,
        $baujahr: String!,
        $kaufdatum: Date,
        $vorbesitzer: String,
        $kaufpreis: String!,
        ) {
            createMobilheim(
                parzelle: $parzelle,
                typ: $typ,
                anbau: $anbau,
                baujahr: $baujahr,
                kaufdatum: $kaufdatum,
                vorbesitzer: $vorbesitzer,
                kaufpreis: $kaufpreis,
                ) {
                    id
                    parzelle
                    typ
                    anbau
                    baujahr
                    kaufdatum
                    vorbesitzer
                    kaufpreis
            }
    }
`;

export const UPDATE_MOBILHEIM = gql`
    mutation UpdateMobilheim(
        $id: ID!,
        $parzelle: ID!,
        $typ: String!,
        $anbau: String!,
        $baujahr: String!,
        $kaufdatum: Date,
        $vorbesitzer: String!,
        $kaufpreis: String
        ) {
            updateMobilheim(
                id: $id,
                parzelle: $parzelle,
                typ: $typ,
                anbau: $anbau,
                baujahr: $baujahr,
                kaufdatum: $kaufdatum,
                vorbesitzer: $vorbesitzer,
                kaufpreis: $kaufpreis,
                ) {
                    id
                    parzelle
                    typ
                    anbau
                    baujahr
                    kaufdatum
                    vorbesitzer
                    kaufpreis
            }
    }
`;


export const DELETE_MOBILHEIM = gql`
    mutation DeleteMobilheim($id: ID!){
        deleteMobilheim(id: $id) {
            ok
        }
    }
`;
