import { gql } from '@apollo/client';


export const GET_LOGS = gql`
    query GetLogs(
        $item: Int,
    ) {
        logs(
            item: $item,
            ) {
            id
            createdAt
            level
            modul
            item
            entry
        }
    }
`;


export const CREATE_LOG = gql`
    mutation CreateLog(
        $level: String!,
        $modul: String!,
        $item: String!,
        $entry: String!,
        ) {
        createRechnungslauf(
            level: $level,
            modul: $modul,
            item: $item,
            entry: $entry,
             ) {
                id
                createdAt
                level
                modul
                item
                entry
            }
    }
`;




