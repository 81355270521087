//import { useQuery, gql, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';


export const GET_EINZELKOSTEN = gql`
    query GetEinzelkosten {
        einzelkosten{
            id
            gueltigAb
            kostenart
            kosten
        }
    }
`;


export const CREATE_EINZELKOSTEN = gql`
    mutation CreateEinzelkosten(
        $gueltigAb: Int!,
        $kostenart: String!,
        $kosten: Decimal!,
        ) {
        createEinzelkosten(
            gueltigAb: $gueltigAb,
            kostenart: $kostenart,
            kosten: $kosten,
             ) {
                id
                gueltigAb
                kostenart
                kosten
        }
    }
`;


export const UPDATE_EINZELKOSTEN = gql`
    mutation UpdateEinzelkosten(
        $id: ID!,
        $gueltigAb: Int!,
        $kostenart: String!,
        $kosten: Decimal!,
        ) {
            updateEinzelkosten(
                id: $id,
                gueltigAb: $gueltigAb,
                kostenart: $kostenart,
                kosten: $kosten,
                ) {
                    id
                    gueltigAb
                    kostenart
                    kosten
        }
    }
`;


export const DELETE_EINZELKOSTEN = gql`
    mutation DeleteEinzelkosten($id: ID!){
        deleteEinzelkosten(id: $id) {
            ok
        }
    }
`;
