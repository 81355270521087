import * as React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";
//import { useFormik } from 'formik';
import { Formik, Form } from "formik";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';
import {UPDATE_MOBILHEIM, GET_MOBILHEIME} from './graphql';
import { GET_PARZELLEN } from '../Parzellen/graphql';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { parse, isDate } from "date-fns";

import Autocomplete from '@mui/material/Autocomplete';
import Title from '../Utils/Title'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


import AlertMessage from '../Alerts/AlertMessage';




let loads;

// Get Parzellen
function GetParzellen() {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;

    //console.log(data);

    // filter for parzellen without mobilheim because of one-to-one-relationship of mobilheim <> parzelle
    // also filter for parzellen with Stellplatzparzelle = false
    const filteredData = data.parzellen.filter(parzelle => parzelle.mobilheim === null && parzelle.stellplatzparzelle === false);
    //console.log(filteredData);
        
    //return filteredData.parzellen.map(({ id, standort }) => (
    return filteredData.map(({ id, standort }) => (
        {id, standort}
    ));
}


// fomat chached date to dd.MM.yyyy so the prefilled date can be filled in in correct format
function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
    } else {
        // important: set date to empty string - otherwise yup will make field required!!
        date = '';
    }
    return date;
}

// fomat date back to yyyy-MM-dd so it can be written back to database
function formatDate(date) {
    if(date) {
        date = date.split(".");
        // if day has not 2 digits
        if (date[0].length < 2) {
            date[0] = "0" + date[0];
        }
        // if month has not 2 digits
        if (date[1].length < 2) {
            date[1] = "0" + date[1];
        }
        date = date[2] + "-" + date[1] + "-" + date[0]; 
    } else {
        date = null;
    }   
    return date;
}

// format currency from database format 00.00 to 00,00, so it is displayed correctly in frontend
function formatCurrencyFromDB(amount) {
    if (amount) {
        return amount.replace(".", ",");
    }
}

// format currency back from frontend format 00,00 to database format 00.00, so it can be written back to database
function formatCurrencyForDB(amount) {
    if (amount) {
        return amount.replace(",", ".");
    }
    return null;
}


// parse date string during editing form
// unfortunately formatting two digits of day and month is not working, so we handle that in 
// function formatDate - see function formatDate above
function parseDateString(value, originalValue) {
    let parsedDate = isDate(originalValue, 'dd.MM.yyyy')
        ? originalValue
        : parse(originalValue, "dd.MM.yyyy", new Date());
    
    return parsedDate;
}


// yup validation
// date validation
const minDate = new Date('1900-01-01');

const today = new Date();

const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const validateDate = yup.date()
    .typeError('Bitte ein gültiges Datum erfassen')
    .transform(parseDateString)
    .min(minDate, 'Das Datum ist zu früh');

// if date is not allowed to be in the future
const validateDatePast = validateDate.max(today, 'Das Datum darf nicht in der Zukunft liegen').nullable().notRequired();

// if date has to be in the future
const validateDateFuture = validateDate.min(tomorrow, 'Das Datum muss in der Zukunft liegen');

const requiredString = yup.string().required('Dieses Feld ist ein Pflichtfeld');

let patternTwoDigisAfterComma = /^\d+(,\d{2})?$/;
const euroValidator = yup
    //.number()
    .string()
    //.positive()
    .test(
        "is-decimal",
        "Der Betrag muss mit einem Komma und zwei Stellen dahinter erfasst werden",
        (val: any) => {
            if (val != undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    )

const validationSchema = yup.object({
    kaufdatum: validateDate,
    parzelle: yup.object().typeError('Es muss eine Parzelle ausgewählt werden').required("Es muss eine Parzelle ausgewählt werden"),
    //kaufpreis: euroValidator.nullable(true),
    kaufpreis: requiredString,
});



let input;






export default function UpdateMobilheim() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeleteParzelle' of child 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');


    const params = useParams();
    const mobilheimId = params.id;

    //console.log('Mobilheim ID: ' + mobilheimId);

    const client = useApolloClient();
    // get cached mobilheim - on client- to prefill update form - so no query is needed
    // see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-fragments
    const selectedMobilheim = client.readFragment({
        id: 'MobilheimType:' + mobilheimId, // The value of the apollo client cache ID
        //id: 'MobilheimType:' + '82', // The value of the apollo client cache ID
        fragment: gql`
        fragment selectedMobilheim on MobilheimType {
            id
            parzelle{
                id
                standort
            }
            typ
            anbau
            baujahr
            kaufdatum
            vorbesitzer
            kaufpreis
        }
        `,
    });

    //console.log(selectedMobilheim);

    // useState to store values of parzelleId
    const [value, setValue] = React.useState(selectedMobilheim.parzelle);

    const parzelleData = GetParzellen();
   
    const navigate = useNavigate();
   

    const [updateMobilheim, { data, loading, error, refetch }] = useMutation(UPDATE_MOBILHEIM, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_MOBILHEIME },
            { query: GET_PARZELLEN }
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.updateMobilheim?.id)) {
                setAlertMessage('Mobilheim mit der ID ' + data.updateMobilheim.id + ' erfolgreich geändert');
                setAlertSeverity('success');
                setOpenAlert(true);
                setTimeout(() => {
                    navigate(`/mobilheime`);
                }, 2000);
            }
        }
    });


    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                //const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                setAlertMessage('Fehler: ' + message);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else if (error.networkError) {
                console.log(`output from errorLink: [Network error]: ${error.networkError}`);
                setAlertMessage(networkError);
                setAlertSeverity('error');
                setOpenAlert(true);
            }
            else {
                setAlertMessage('Fehler! Bitte den Support kontaktieren.');
                setAlertSeverity('error');
                setOpenAlert(true);
                console.log(error);
            }
        }
    }, [error]);


    const goBack = () => {
		navigate(-1);
	}


    const initialValues = {
        parzelle: selectedMobilheim.parzelle,
        typ: selectedMobilheim.typ,
        anbau: selectedMobilheim.anbau,
        baujahr: selectedMobilheim.baujahr,
        kaufdatum: formatCachedDate(selectedMobilheim.kaufdatum),
        vorbesitzer: selectedMobilheim.vorbesitzer,
        kaufpreis: formatCurrencyFromDB(selectedMobilheim.kaufpreis),
    };

    

    const handleSubmit = (values) => {

        updateMobilheim({ 
            variables: {
                id: mobilheimId,
                parzelle: values.parzelle.id,
                typ: values.typ,
                anbau: values.anbau,
                baujahr: values.baujahr,
                // format date back to yyyy-MM-dd
                kaufdatum: formatDate(values.kaufdatum),
                vorbesitzer: values.vorbesitzer,
                kaufpreis: formatCurrencyForDB(values.kaufpreis),
            }
        });
    };

    



    return (
        <>
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid item xs={12}>
                    
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Title>Mobilheim ändern</Title>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate>
                                        
                                        <Autocomplete
                                            options={parzelleData}
                                            filterSelectedOptions
                                            value={value}
                                            onChange={(e, value) => {
                                                // at first set UseState
                                                setValue(value);
                                                // then use state to set field value
                                                setFieldValue(
                                                    "parzelle", value
                                                    //value !== null ? value : initialValues.parzelle
                                                );
                                            }}
                                            // important so that initially selected values get considered
                                            isOptionEqualToValue={(option, value) => option.standort === value.standort}
                                            getOptionLabel={(option) => option.standort}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    {option.standort}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params}
                                                    variant='filled'
                                                    label="Standort (Parzelle)"
                                                    name="parzelle"
                                                    required
                                                    error={touched.parzelle && Boolean(errors.parzelle)}
                                                    helperText={touched.parzelle && errors.parzelle}
                                                />
                                            )}
                                        />
                                        
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="typ"
                                            name="typ"
                                            label="Typ"
                                            value={values.typ}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="anbau"
                                            name="anbau"
                                            label='Anbau'
                                            value={values.anbau}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="baujahr"
                                            name="baujahr"
                                            label="Baujahr"
                                            value={values.baujahr}
                                            onChange={handleChange}
                                            error={touched.baujahr && Boolean(errors.baujahr)}
                                            helperText={touched.baujahr && errors.baujahr}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="kaufdatum"
                                            name="kaufdatum"
                                            label='Kaufdatum'
                                            placeholder='TT.MM.JJJJ'
                                            value={values.kaufdatum}
                                            onChange={handleChange}
                                            error={touched.kaufdatum && Boolean(errors.kaufdatum)}
                                            helperText={touched.kaufdatum && errors.kaufdatum}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="vorbesitzer"
                                            name="vorbesitzer"
                                            label="Vorbesitzer"
                                            value={values.vorbesitzer}
                                            onChange={handleChange}
                                            error={touched.vorbesitzer && Boolean(errors.vorbesitzer)}
                                            helperText={touched.vorbesitzer && errors.vorbesitzer}
                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id="kaufpreis"
                                            name="kaufpreis"
                                            label="Kaufpreis (EUR)"
                                            value={values.kaufpreis}
                                            onChange={handleChange}
                                            error={touched.kaufpreis && Boolean(errors.kaufpreis)}
                                            helperText={touched.kaufpreis && errors.kaufpreis}
                                        />
                                        
                                        <Button color="primary" variant="contained" type="submit">
                                            ändern
                                        </Button>
                                        <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                            zurück
                                        </Button>

                                    </Form>
                            )}
                            </Formik>

                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>

        </Container>
        </>
    );

    

    

};



