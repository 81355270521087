import * as React from 'react';
import { useParams } from "react-router-dom";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';
import { GET_ONE_PARZELLE } from '../../Parzellen/graphql';
import { useNavigate } from "react-router-dom";
import LoadingAnimation from '../../Utils/LoadingAnimation';

import Autocomplete from '@mui/material/Autocomplete';
import Title from '../../Utils/Title'
import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


let loads;


function GetOneParzelle(pachtID) {
    const { loading, error, data } = useQuery(GET_ONE_PARZELLE, {
        variables: { pachtID },
        onCompleted: () => {
            console.log('Query completed');
        }
    });

    if (loading) {
        return { loading: true, data: null, error: null };
    }
    if (error) {
        return { loading: false, data: null, error: error.message };
    }
    return { loading: false, data: data.oneParzelle, error: null };
}





// fomat chached date to dd.MM.yyyy
function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
    } else {
        // important: set date to empty string - otherwise yup will make field required!!
        date = '';
    }
    return date;
}

function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
        maximumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
    }).format(number);
}


function formatBool(value) {
    if(value == true) {
        return 'Ja';
    } else {
        return 'Nein';
    }
}





export default function ShowParzelle() {

    const params = useParams();
    const pachtID = params.id;
    const { loading, data, error } = GetOneParzelle(pachtID);
    const oneParzelle = data;

    const navigate = useNavigate();

    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
            <LoadingAnimation />
        </Box>
    );

    const goBack = () => {
		navigate(-1);
	}


    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid
                    item xs={12}
                    sx={{ flexGrow: 1}}
                >
                    
                        <Box 
                            noValidate
                            sx={{
                                '& .MuiTextField-root': { marginRight: 3, minWidth: '400px' },
                                flexGrow: 1
                            }}
                        >
                            
                            {oneParzelle && oneParzelle.map(parzelle => {
                                return (
                                    <>
                                        <Title>Parzelle {parzelle.standort}</Title>

                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Standort"
                                            value={parzelle.standort}
                                        />
                                        
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Grösse (in Quadratmetern)"
                                            value={parzelle.parzellengroesse}
                                        />
                                        <TextField
                                            fullWidth
                                            sx={{maxWidth: "825px"}}
                                            disabled
                                            variant='filled'
                                            label="Besonderheiten"
                                            value={parzelle.besonderheiten}
                                        />
                                        <TextField
                                            fullWidth
                                            sx={{maxWidth: "825px"}}
                                            disabled
                                            variant='filled'
                                            label="Zusatzpflege"
                                            value={parzelle.zusatzpflege}
                                        />
                                        
                                        <TextField      
                                            disabled
                                            variant='filled'
                                            label="Gasdruckprüfung"
                                            value={formatCachedDate(parzelle.gasdruckpruefung)}
                                        />
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Geplante Gasdruckprüfung"
                                            value={formatCachedDate(parzelle.geplanteGasdruckpruefung)}
                                        />
                                        
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Stromzählerart"
                                            value={parzelle.stromzaehlerart}
                                        />
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="SAV"
                                            value={parzelle.sav}
                                        />
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Biotonne Nr."
                                            value={parzelle.bioTonneNr}
                                        />
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Gelbe Tonne"
                                            value={formatBool(parzelle.gelbeTonne)}
                                        />
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Blaue Tonne"
                                            value={formatBool(parzelle.blaueTonne)}
                                        />
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Restmüll Tonne Nr."
                                            value={parzelle.restmuellTonneNr}
                                        />
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Stellplatzparzelle"
                                            value={formatBool(parzelle.stellplatzparzelle)}
                                        />
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Verwaltungsparzelle"
                                            value={formatBool(parzelle.verwaltungsparzelle)}
                                        />
                                        <TextField
                                            disabled
                                            variant='filled'
                                            label="Schacht"
                                            value={parzelle.schacht}
                                        />
                                        
                                    </>                                    
                                );
                                })
                            }
                            
                        </Box>
                        
                </Grid>
            </Grid>
        </Container>
    );
};