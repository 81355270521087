import { gql } from '@apollo/client';


export const GET_PARZELLEN = gql`
    query GetParzellen {
        parzellen{
            id
            standort
            parzellengroesse
            fiSchalterNeu
            besonderheiten
            zusatzpflege
            gasdruckpruefung
            geplanteGasdruckpruefung
            sav
            bioTonneNr
            gelbeTonne
            blaueTonne
            restmuellTonneNr
            stellplatzparzelle
            verwaltungsparzelle
            schacht
            schluesselImBuero
        }
    }
`;


export const GET_ONE_PARZELLE = gql`
    query GetOneParzelle($pachtID: ID!) {
        oneParzelle(pachtId: $pachtID) {
            id
            standort
            parzellengroesse
            fiSchalterNeu
            besonderheiten
            zusatzpflege
            gasdruckpruefung
            geplanteGasdruckpruefung
            sav
            bioTonneNr
            gelbeTonne
            blaueTonne
            restmuellTonneNr
            stellplatzparzelle
            verwaltungsparzelle
            schacht
        }
    }
`;


export const CREATE_PARZELLE = gql`
    mutation CreateParzelle(
        $standort: String!,
        $parzellengroesse: Int!,
        $fiSchalterNeu: String,
        $besonderheiten: String,
        $zusatzpflege: String,
        $gasdruckpruefung: Date,
        $geplanteGasdruckpruefung: Date,
        $sav: String!,
        $bioTonneNr: String,
        $gelbeTonne: Boolean,
        $blaueTonne: Boolean,
        $restmuellTonneNr: String,
        $stellplatzparzelle: Boolean,
        $verwaltungsparzelle: Boolean,
        $schacht: String,
        $schluesselImBuero: Boolean!,
        ) {
        createParzelle(
            standort: $standort,
            parzellengroesse: $parzellengroesse,
            fiSchalterNeu: $fiSchalterNeu,
            besonderheiten: $besonderheiten,
            zusatzpflege: $zusatzpflege,
            gasdruckpruefung: $gasdruckpruefung,
            geplanteGasdruckpruefung: $geplanteGasdruckpruefung, 
            sav: $sav, 
            bioTonneNr: $bioTonneNr,
            gelbeTonne: $gelbeTonne,
            blaueTonne: $blaueTonne,
            restmuellTonneNr: $restmuellTonneNr,
            stellplatzparzelle: $stellplatzparzelle,
            verwaltungsparzelle: $verwaltungsparzelle,
            schacht: $schacht,
            schluesselImBuero: $schluesselImBuero,
            ) {
            id
            standort
            parzellengroesse
            fiSchalterNeu
            besonderheiten
            zusatzpflege
            gasdruckpruefung
            geplanteGasdruckpruefung
            sav
            bioTonneNr
            gelbeTonne
            blaueTonne
            restmuellTonneNr
            stellplatzparzelle
            verwaltungsparzelle
            schacht
            schluesselImBuero
        }
    }
`;

export const UPDATE_PARZELLE = gql`
    mutation UpdateParzelle(
        $id: ID!,
        $standort: String!,
        $parzellengroesse: Int!,
        $fiSchalterNeu: String,
        $besonderheiten: String,
        $zusatzpflege: String,
        $gasdruckpruefung: Date,
        $geplanteGasdruckpruefung: Date,
        $sav: String,
        $bioTonneNr: String,
        $gelbeTonne: Boolean,
        $blaueTonne: Boolean,
        $restmuellTonneNr: String,
        $stellplatzparzelle: Boolean,
        $verwaltungsparzelle: Boolean,
        $schacht: String,
        $schluesselImBuero: Boolean!,
        ) {
        updateParzelle(
            id: $id,
            standort: $standort,
            parzellengroesse: $parzellengroesse,
            fiSchalterNeu: $fiSchalterNeu,
            besonderheiten: $besonderheiten,
            zusatzpflege: $zusatzpflege,
            gasdruckpruefung: $gasdruckpruefung,
            geplanteGasdruckpruefung: $geplanteGasdruckpruefung,
            sav: $sav,
            bioTonneNr: $bioTonneNr,
            gelbeTonne: $gelbeTonne,
            blaueTonne: $blaueTonne,
            restmuellTonneNr: $restmuellTonneNr,
            stellplatzparzelle: $stellplatzparzelle,
            verwaltungsparzelle: $verwaltungsparzelle,
            schacht: $schacht,
            schluesselImBuero: $schluesselImBuero,
            ) {
                id
                standort
                parzellengroesse
                fiSchalterNeu
                besonderheiten
                zusatzpflege
                gasdruckpruefung
                geplanteGasdruckpruefung
                sav
                bioTonneNr
                gelbeTonne
                blaueTonne
                restmuellTonneNr
                stellplatzparzelle
                verwaltungsparzelle
                schacht
                schluesselImBuero
            }
    }
`;


export const DELETE_PARZELLE = gql`
    mutation DeleteParzelle($id: ID!){
        deleteParzelle(id: $id) {
            ok
        }
    }
`;
