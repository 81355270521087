import * as React from 'react';

import "./main.css"

import Login from './components/Authentication/Login';
import AppContent from './components/App/AppContent';
import UserContext, { UserProvider } from './components/Authentication/UserContext';
import { useHistory ,useLocation } from 'react-router-dom';


export default function App() {
    const user = React.useContext(UserContext);
    const location = useLocation();
    // on login site only the login component should be shown, not the "whole frame" of the app
    if (location.pathname === '/login') {
        return (
            <UserProvider>
                <Login />
            </UserProvider>
        )
    }
    return (
        <UserProvider>
            <AppContent />
        </UserProvider>
    )

    
}
