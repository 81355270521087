import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import { GET_RECHNUNGSLAEUFE, STORNO_RECHNUNGSLAUF } from './graphql';
import { GET_PACHTRECHNUNGEN } from '../Pachtrechnungen/graphql';
import { GET_ENERGIERECHNUNGEN } from '../Energierechnungen/graphql';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

function formatCachedDate(date) {
    if(date == null) {
        return;
    } else {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


export default function StornoRechnungslauf({ rechnungslauf, isOpenStornoDialog, handleCloseStornoDialog, assignAlertMessage, assignAlertSeverity, handleOpenAlert }) {
    
    const [stornoRechnungslauf, { data, loading, error, refetch }] = useMutation(STORNO_RECHNUNGSLAUF, {
        fetchPolicy: 'network-only',
        refetchQueries: [
            { query: GET_RECHNUNGSLAEUFE },
            { query: GET_PACHTRECHNUNGEN },
            { query: GET_ENERGIERECHNUNGEN }
        ],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.stornoRechnungslauf?.id)) {
                assignAlertMessage('Rechnungslauf mit der ID ' + data.stornoRechnungslauf.stornoVon + ' storniert. Neuen Rechnungslauf mit der Nr. ' +
                data.stornoRechnungslauf.id + ' erstellt.');
                assignAlertSeverity('success');
            }
        },
    });

    React.useEffect(() => {
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);

    
    
    function StornoRechnungslauf(stornoVon, rechnungsart) {
        return stornoRechnungslauf({
            variables: {
                stornoVon: stornoVon,
                rechnungsart: rechnungsart,
            }
        });
    };


        

    return (
        <>
            {isOpenStornoDialog ? (
                <Dialog
                    open={isOpenStornoDialog}
                    onClose={handleCloseStornoDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Möchten Sie den Rechnungslauf mit der Nummer {rechnungslauf.stornoVon} stornieren?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Eine Stornierung eines kompletten Rechnungslaufs ist sinnvoll, wenn alle oder aber eine hohe Anzahl an bereits ausgegebenen
                            Rechnungen dieses Laufs korrigiert, also neu erstellt werden müssen.<br />
                            Wenn Sie die den Lauf stornieren, wird dieser Lauf als storniert gekennzeichnet <b> und zusätzlich ein neuer Rechnungslauf 
                            erstellt.</b>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseStornoDialog}>Nein</Button>
                        <Button
                        onClick={
                            () => {
                                handleCloseStornoDialog();
                                StornoRechnungslauf(rechnungslauf.stornoVon, rechnungslauf.rechnungsart);
                                handleOpenAlert();
                            }
                        }
                        autoFocus
                        >Stonieren</Button>
                    </DialogActions>
                </Dialog>
            ) : ''}   
        </>
    );  

}