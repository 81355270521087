import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from "react-router-dom";

import { GET_PACHTRECHNUNGEN_OF_PACHT } from '../../Pachtrechnungen/graphql';
import { GET_ENERGIERECHNUNGEN_OF_PACHT } from '../../Energierechnungen/graphql';
import { GET_NEBENKOSTENRECHNUNGEN_OF_PACHT } from '../../Nebenkostenrechnungen/graphql';
import { GET_SONSTIGE_RECHNUNGEN_OF_PACHT } from '../../SonstigeRechnungen/graphql';

import { ContextMenuPachtBoard as ContextMenuPacht } from '../../Pachtrechnungen/ContextMenu';
import { ContextMenuPachtBoard as ContextMenuEnergie } from '../../Energierechnungen/ContextMenu';
import { ContextMenuPachtBoard as ContextMenuNebenkosten } from '../../Nebenkostenrechnungen/ContextMenu';
import { ContextMenuPachtBoard as ContextMenuSonstige } from '../../SonstigeRechnungen/ContextMenu';

import Title from '../../Utils/Title';
import AlertMessage from '../../Alerts/AlertMessage';

import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

function formatBool(val) {
    return val.value ? 'Ja' : 'Nein';
}

function formatDate(date) {
    if (date.value == null) {
        return;
    } else {
        const [year, month, day] = date.value.split("-");
        return `${day}.${month}.${year}`;
    }
}

const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);

export default function ListRechnungenOfPacht() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    const params = useParams();
    const pachtID = params.id;

    const { loading: loadingPacht, data: dataPacht } = useQuery(GET_PACHTRECHNUNGEN_OF_PACHT, { variables: { pachtID } });
    const { loading: loadingEnergie, data: dataEnergie } = useQuery(GET_ENERGIERECHNUNGEN_OF_PACHT, { variables: { pachtID } });
    const { loading: loadingNebenkosten, data: dataNebenkosten } = useQuery(GET_NEBENKOSTENRECHNUNGEN_OF_PACHT, { variables: { pachtID } });
    const { loading: loadingSonstige, data: dataSonstige } = useQuery(GET_SONSTIGE_RECHNUNGEN_OF_PACHT, { variables: { pachtID } });

    const allRechnungen = React.useMemo(() => {
        if (dataPacht && dataEnergie && dataNebenkosten && dataSonstige) {
            const pachtrechnungen = dataPacht.pachtrechnungenOfPacht.map(item => ({
                id: item.id,
                rechnungsnummer: item.rechnungsnummer,
                rechnungsdatum: item.rechnungsdatum ? item.rechnungsdatum : null,
                rechnungsbetrag: item.rechnungsbetrag,
                debNr: item.debNr,
                paechter: `${item.paechtername}, ${item.paechtervorname}`,
                rechnungsart: 'Pacht',
                stornoVon: item.stornoVon,
                storniert: item.storniert,
            }));

            const energierechnungen = dataEnergie.energierechnungenOfPacht.map(item => ({
                id: item.id,
                rechnungsnummer: item.rechnungsnummer,
                rechnungsdatum: item.rechnungsdatum,
                rechnungsbetrag: item.rechnungsbetrag,
                debNr: item.debNr,
                paechter: `${item.paechtername}, ${item.paechtervorname}`,
                rechnungsart: 'Energie',
                stornoVon: item.stornoVon,
                storniert: item.storniert,
            }));

            const nebenkostenrechnungen = dataNebenkosten.nebenkostenrechnungenOfPacht.map(item => ({
                id: item.id,
                rechnungsnummer: item.rechnungsnummer,
                rechnungsdatum: item.rechnungsdatum,
                rechnungsbetrag: item.rechnungsbetrag,
                debNr: item.debNr,
                paechter: `${item.paechtername}, ${item.paechtervorname}`,
                rechnungsart: 'Nebenkosten',
                stornoVon: item.stornoVon,
                storniert: item.storniert,
            }));

            const sonstigerechnungen = dataSonstige.sonstigeRechnungenOfPacht.map(item => ({
                id: item.id,
                rechnungsnummer: item.rechnungsnummer,
                rechnungsdatum: item.rechnungsdatum,
                rechnungsbetrag: item.rechnungsbetrag,
                debNr: item.debNr,
                paechter: `${item.paechtername}, ${item.paechtervorname}`,
                rechnungsart: 'Sonstige',
                stornoVon: item.stornoVon,
                storniert: item.storniert,
            }));

            return [
                ...pachtrechnungen,
                ...energierechnungen,
                ...nebenkostenrechnungen,
                ...sonstigerechnungen,
            ];
        }
        return [];
    }, [dataPacht, dataEnergie, dataNebenkosten, dataSonstige]);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 70 },
        { field: 'rechnungsdatum', headerName: 'Ausstellungsdatum', width: 170, type: 'date', valueFormatter: formatDate, headerAlign: 'right', align: 'right' },
        { field: 'rechnungsnummer', headerName: 'Rechnungs-Nr.', width: 150, headerAlign: 'right', align: 'right' },
        { field: 'rechnungsart', headerName: 'Rechnungsart', width: 170 },
        { field: 'rechnungsbetrag', headerName: 'Rechnungsbetrag (netto EUR)', width: 230, type: 'number', valueFormatter: (params) => formatCurrency(params.value) },
        { field: 'debNr', headerName: 'Deb.-Nr.', width: 120, type: 'number', headerAlign: 'right', align: 'right' },
        { field: 'stornoVon', headerName: 'Storno von', width: 120, headerAlign: 'right', align: 'right' },
        { field: 'storniert', headerName: 'storniert', width: 120, type: 'boolean', valueFormatter: formatBool },
        {
            field: 'actions',
            headerName: '',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                if (params.row.rechnungsart === 'Pacht') {
                    return (
                        <ContextMenuPacht
                            params={params}
                            assignAlertMessage={setAlertMessage}
                            assignAlertSeverity={setAlertSeverity}
                            handleOpenAlert={() => setOpenAlert(true)}
                        />
                    );
                }
                if (params.row.rechnungsart === 'Energie') {
                    return (
                        <ContextMenuEnergie
                            params={params}
                            assignAlertMessage={setAlertMessage}
                            assignAlertSeverity={setAlertSeverity}
                            handleOpenAlert={() => setOpenAlert(true)}
                        />
                    );
                }
                if (params.row.rechnungsart === 'Nebenkosten') {
                    return (
                        <ContextMenuNebenkosten
                            params={params}
                            assignAlertMessage={setAlertMessage}
                            assignAlertSeverity={setAlertSeverity}
                            handleOpenAlert={() => setOpenAlert(true)}
                        />
                    );
                }
                if (params.row.rechnungsart === 'Sonstige') {
                    return (
                        <ContextMenuSonstige
                            params={params}
                            assignAlertMessage={setAlertMessage}
                            assignAlertSeverity={setAlertSeverity}
                            handleOpenAlert={() => setOpenAlert(true)}
                        />
                    );
                }
                return null;
            }
        }
    ];

    

    return (
        <>
            <AlertMessage
                message={alertMessage}
                severity={alertSeverity}
                isOpenAlert={openAlert === true}
                handleCloseAlertButton={() => setOpenAlert(false)}
            />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Title>Rechnungen</Title>
                        <div style={{ width: '100%' }}>
                            <ThemeProvider theme={lighttheme}>
                                <DataGrid
                                    sx={{
                                        borderRadius: '6px',
                                        background: '#ffffff',
                                        boxShadow: 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                            outline: 'none',
                                        },
                                    }}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 25, page: 0 },
                                        },
                                        sorting: {
                                            sortModel: [{ field: 'rechnungsdatum', sort: 'desc' }],
                                        },
                                    }}
                                    rows={allRechnungen}
                                    columns={columns}
                                    pageSize={3}
                                    autoHeight={true}
                                    rowsPerPageOptions={[3]}
                                    pageSizeOptions={[10, 15, 25, 50, 100]}
                                    loading={loadingPacht || loadingEnergie || loadingNebenkosten || loadingSonstige}
                                    checkboxSelection={false}
                                    hideFooterSelectedRowCount={true}
                                />
                            </ThemeProvider>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
